.admin-options {
  padding-top: 0;
  float: right;
  margin: auto;
  margin-right: 0px;
}

.admin-options a, .admin-options span {
  font-size: 12px;
}

.admin-options a {
  font-weight: bold;
  margin: 0 3px;
}

.DropdownStyle {
  color: #fff;
  background-color: '#1d4d74';
  border: 'none';
}

#menu ul {
  padding: 0px;
  margin: 0px;
  margin-left: -40px;
  list-style: none;
}

#menu ul li {
  display: inline;
}

#menu ul li a {
  padding: 2px 12px;
  display: inline-block;
  /* visual do link */
  color: #fff;
  text-decoration: none;
  font-size: 12px;
}

#menu ul li a:hover {
  color: #dbdb6b;
}

.back-nav2 {
  background: #c4af7e;
}

.ajuste-nav2 {
  margin: 0;
  padding: 2px;
  color: #333;
  text-decoration: none;
}

.fundo {
  margin: 5px 10px;
}

.logo-small {
  height: 20px;
  margin-left: 25px;
}

@media only screen and (max-width: 768px) {
  .responsive-navbar {
    display: block !important;
  }
  .navbar-large {
    display: none !important;
  }
  .navbar-nav * {
    color: white !important;
  }
  .navbar-toggler {
    border-color: white !important;
    background-color: white !important;
  }
  .navbar-brand .media img {
    width: 90vw;
  }
}

@media only screen and (min-width: 768px) {
  .responsive-navbar {
    visibility: hidden;
    display: none !important;
    position: absolute;
    width: 0px !important;
  }
  .navbar-expand-small {
    height: 35px;
    align-items: center;
    padding-bottom: 5px;
  }
}