.footer {
  position: absolute;  
  left: 0;
  z-index: 0;
  padding: 30px 0;
  background-color: #1d4d74;
  width: 100%;
  color: #fff;
  font-size: 11px;
}

.is-footerlogo a {
  display: inline;
  line-height: 50px;
}

.is-footerlogo img {
  width: 200px;
}

.is-footer-social ul {
  list-style: none;
  padding: 0;
  margin: 5px 0 0 0;
}

.is-footer-social ul li {
  display: inline;
  margin-right: 15px;
}

ul {
  list-style: none;
}

.copyright {
  font-size: 14px;
  margin: 30px 0 0 0;
  float: left;
  width: 100%;
  text-align: center;
}

.copyright a:hover {
  cursor: pointer;
  text-decoration: none;
}

.link {
  color: #d2b03e;
  padding-left: 2px;
}

.p-footer {
  font-size: 15px;
}

.is-footermod li {
  margin-top: 10px;
}

.is-footermod a {
  font-size: 12pt;
  color: white;
}

a:hover {
  text-decoration: none !important;
}

.link-a {
  color: white m !important;
}

.change-text {
  color: white;
}

@media only screen and (min-width: 768px) {
  .login-form {
    max-width: 40%;
    justify-self: center;
    margin: auto;
  }
}

@media only screen and (max-width: 768px) {
  .is-footerlogo {
    max-width: 400px;
  }
}

