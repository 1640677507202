.footer-recovery {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 30px 0;
    background-color: #1d4d74;
    width: 100%;
    color: #fff;
    font-size: 11px;
    /* margin-top: 3rem; */
  }