/* Spinner */

#spinnertable:not([hidden]) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #spinnertable::after {
    content: "";
    width: 80px;
    height: 80px;
    border: 10px solid #f3f3f3;
    border-top: 10px solid rgb(29, 77, 116);
    border-radius: 100%;
    will-change: transform;
    animation: spin 1s infinite linear;
  }
  
  @keyframes spin {
    from {
        transform: rotate(0deg);
    }
  
    to {
        transform: rotate(360deg);
    }
  }