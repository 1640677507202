.wrapper {
    position: relative;
    min-height: 100%;
}

#info-peca header {
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
}

.headerAjuste {
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
}


.row .row {
    width: auto;
    max-width: none;
    min-width: 0;
    margin: 0 -15px;
}

.twelve, .row .twelve {
    width: 100%;
}

.eight, .row .eight {
    width: 66.66667%;
}

.column, .columns {
    float: left;
    min-height: 1px;
    padding: 0 15px;
    position: relative;
}

.presencial-title {
    position: relative;
    padding-left: 137px;
    display: block;
    width: 100%;
}
.presencial-title h1 {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
}
.presencial-title h1 a {
    float: left;
    padding-right: 15px;
    border-right: 1px solid #ddd;
    height: 46px;
    line-height: 46px;
}

a img {
    border: none;
}

.presencial-title h2 {
    font-size: 14px;
    font-family: 'Open Sans', Arial, sans-serif;
    color: #737373;
    font-weight: 400;
    margin: 0 0 0 15px;
    padding: 15px 50px 15px 0;
    position: relative;
    font-weight: 600;
    line-height: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.presencial-title .title-autorizado {
    font-size: 10px;
    width: 100%;
    padding-right: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: left;
    font-weight: normal;
    text-transform: uppercase;
}

.presencial-title .title-numleilao {
    font-size: 10px;
    width: 100%;
    padding-right: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 10px;
}

.title-numleilao {
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 12px;
    opacity: 0.7;
}

.presencial-title .title-numleilao {
    font-size: 10px;
    width: 100%;
    padding-right: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 10px;
}

.title-numleilao {
    position: absolute;
    top: 0;
    left: 0;
    text-align: left;
    padding-right: 15px;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    line-height: 16px;
}

.log-box {
    position: absolute;
    top: 0;
    right: 0;
}

.log-box-wrap {
    position: relative;
}

.is-titlelogin a {
    color: #737373;
    border: 1px solid #f1f1f1;
    background-color: #f1f1f1;
    box-shadow: 1px 1px 1px 0px rgb(0 0 0 / 15%);
    display: block;
    font-size: 20px;
    transition: all 0.5s ease;
}

.icon {
    padding: 10px 12px;
}

#infoprod-presencial {
    padding: 15px 0;
}

.nome-1 {
    padding-right: 100px;
    position: relative;
}

.nome-1 h2 {
    color: #737373;
    font-size: 20px;
    margin: 0;
    height: 27px;
    line-height: 27px;
}

.presencial-tools {
    padding: 0;
    list-style: none;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
}

.presencial-tools li:last-child {
    margin-right: 14px;
    margin-left: 25px;
}

.is-voltacatalogo {
    padding-right: 20px;
    margin: 0;
    border-right: 1px solid #ddd;
}

.presencial-tools li {
    float: left;
    margin-left: 20px;
}

.presencial-tools li a {
    color: #737373;
    font-size: 25px;
}

.descricao-text {
    margin-top: 12px;
}

.descricao-text p {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
}

.borderWhite {
    border: 1px solid #f1f1f1;
}

#proxlote {
    margin-top: 15px;
}

.section-title {
    padding-left: 0;
}

.section-title {
    padding-left: 10px;
    position: relative;
    margin-top: 10px;
}

.section-title h2 {
    font-weight: normal;
    color: #737373;
    font-size: 12px;
    font-family: "Open Sans";
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 10px 0;
}

.verde{background-color:#50b432; color:#fff;}
.verde h2 span { background-color:#50b432; color:#fff; }
.verde h2 span i { color:#fff; }
.verde .section-title span { background:#50b432; z-index:2; position:relative; }
.verde .section-title:after { border-color:rgba(255, 255, 255, 0.3);  z-index:1; }
.azul{background-color:#65b1d2; color:#fff;}
.azul h2 span { background-color:#65b1d2; color:#fff; }
.azul h2 span i { color:#fff; }
.azul .section-title span { background:#65b1d2; z-index:2; position:relative; }
.azul .section-title:after { border-color:rgba(255, 255, 255, 0.3);  z-index:1; }
.laranja{background-color:#ed561b; color:#fff;}
.laranja h2 span { background-color:#ed561b; color:#fff; }
.laranja h2 span i { color:#fff; }
.laranja p span { display:table-cell; vertical-align:middle; }
.laranja p i { font-size:30px; position:absolute; top:50%; left:0; margin-top:-18px; }
.laranja .section-title span { background:#ed561b; z-index:2; position:relative; }
.laranja .section-title:after { border-color:rgba(255, 255, 255, 0.3);  z-index:1; }
.amarelo{background-color:#efd373; color:#333;}
.amarelo h2 span { background-color:#efd373; color:#000; }
.amarelo h2 span i { color:#000; }
.amarelo .section-title span { background:#efd373; z-index:2; position:relative; }
.amarelo .section-title:after { border-color:rgba(255, 255, 255, 0.3);  z-index:1; }
.azulclaro{background-color:#24cbe5; color:#333;}
.azulclaro h2 span { background-color:#24cbe5; color:#fff; }
.azulclaro h2 span i { color:#fff; }
.azulclaro .section-title span { background:#24cbe5; z-index:2; position:relative; }
.azulclaro .section-title:after { border-color:rgba(255, 255, 255, 0.3);  z-index:1; }
.vermelho{background-color:#bb4f5d; color:#fff; }
.vermelho h2 span { background-color:#bb4f5d; color:#fff; }
.vermelho h2 span i { color:#fff; }
.vermelho p i { font-size:30px; position:absolute; top:50%; left:0; margin-top:-18px; }
.vermelho .section-title span { background:#bb4f5d; z-index:2; position:relative; }
.vermelho .section-title:after { border-color:rgba(255, 255, 255, 0.3);  z-index:1; }
.cinza { background-color:#757575; color:#fff; }
.cinza h2 span { background-color:#757575; color:#fff; }
.cinza h2 span i { color:#fff; }
.cinza .section-title span { background:#757575; z-index:2; position:relative; }
.cinza .section-title:after { border-color:rgba(255, 255, 255, 0.3);  z-index:1;}

.internet {
    color: #65b1d2;
    font-weight: bold;
}

.section-title:after {
    content: " ";
    display: block;
    position: relative;
    top: -17px;
    width: 100%;
    border-top: 1px solid #ddd;
    z-index: -1;
}

#proxlote ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#proxlote li:first-child {
    padding-left: 15px;
}

#proxlote li {
    width: 20%;
    display: table;
    height: 100px;
    padding: 0 5px;
}

#proxlote li.lastitem {
    padding-right: 15px;
}

.column, .columns {
    float: left;
    min-height: 1px;
    padding: 0 15px;
    position: relative;
}

#proxlote li .proxlote-imgwrap {
    display: table-cell;
    width: 100%;
    height: 100%;
    border: 1px solid #ddd;
    text-align: center;
    vertical-align: middle;
}

#proxlote li .proxlote-imgwrap img {
    max-width: 100%;
    max-height: 100px;
    vertical-align: middle;
}

.rodapeimg {
    margin-top: -10px;
}

.headerimg {
    width: 120px;
    margin-top: -40px;
}

.gray-wrap {
    padding: 15px;
    margin: 10px 0 0 0;
    background: #f1f1f1;
    position: relative;
}

.termometro {
    position: relative;
    height: 30px;
}

.termometro p {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 30px;
    text-align: center;
    width: 100%;
    margin: 0;
}

.pb-pct {
    height: 31px;
    position: absolute;
    top: 0;
    left: 0;
    background: #efd373;
}

.spacetry {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

.gray-wrap {
    padding: 15px;
    margin: 10px 0 0 0;
    background: #f1f1f1;
    position: relative;
}

#mensagens-leilao {
    position: relative;
    height: 200px;
}

#mensagens-leilao {
    border: 1px solid #ddd;
    background: #fff;
    overflow-y: scroll;
}

.leilao-semlance {
    background-color: #1d4d74;
    color: #737373;
    padding: 5px;
    font-weight: bold;
    padding: 10px;
}

.leilao-semlance h3 {
    font-size:11px; 
    margin:0 0 5px 0; 
    color:white;
}

.valor {
    color: white;
}

.zero-wrap {
    padding: 0;
    margin: 10px 0 0 0;
}

.alerta {
    padding: 15px;
    color: #737373;
    transition: all 0.5s ease;
}

.inicio {
    background-color: #f1f1f1;
}

.zero-wrap .section-title {
    margin: 0;
}

.zero-wrap .section-title h2 {
    margin-top: 0;
}

.section-title h2 {
    font-weight: normal;
    color: #737373;
    font-size: 12px;
    font-family: "Open Sans";
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 10px 0;
}

.alerta p {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    transition: all 0.5s ease;
    height: 42px;
    display: table-cell;
    vertical-align: middle;
}

.gray-wrap .section-title:after {
    border-color: rgba(197, 197, 197, 0.4);
    z-index: 1;
}

.gray-wrap .section-title span {
    background: #f1f1f1;
    z-index: 2;
    position: relative;
}

.gray-wrap .lance h3, .gray-wrap .lance p {
    color: #737373;
    z-index: 1;
    position: relative;
}


.gray-wrap p a {
    color: #fff;
    cursor: pointer;
    z-index: 1;
    position: relative;
    width: 100%;
    background: #737373;
    display: block;
    padding: 10px 0;
}

.naologado a {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    text-align: center;
}

.lance h3 {
    margin: 0 0 15px 0;
    padding: 15px 0 0 20px;
    position: relative;
    font-size: 14px;
    text-align: left;
    font-family: "Open Sans", arial;
}

.lance .title-numleilao {
    left: 20px;
    font-size: 11px;
}