.my-logo {
  height: 200px;
  width: 200px;
  object-fit: cover;
  background-color: white;
  cursor: pointer;
}

.my-store-content {
  position: relative;
  top: -50px;
}

.my-banner {
  background-color: white;
  height: 200px;
  width: 100%;
  object-fit: cover;
}