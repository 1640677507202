.buyandsell {
    padding-bottom: 30px;
}

.is-geraltitle {
    margin-bottom: 30px;
}

.is-comocomprar {
    padding: 0 0 10px 40px;
}

.intern-title-buyandsell h3 {
    width: auto;
    color: #565656;
    margin: 0;
    line-height: 30px;
    font-size: 16px;
    position: relative;
}