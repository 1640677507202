/* Spinner */

#spinner:not([hidden]) {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#spinner::after {
  content: "";
  width: 80px;
  height: 80px;
  border: 2px solid #f3f3f3;
  border-top: 3px solid rgb(29, 77, 116);
  border-radius: 100%;
  will-change: transform;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
      transform: rotate(0deg);
  }

  to {
      transform: rotate(360deg);
  }
}

/* Spinner Erro */

#spinnerErro:not([hidden]) {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
}


#changetypecolCPF, #changetypecolCNPJ {
  display: none;
}

.content-wrap {
  background: #fff;
  display: flex;
  min-height: calc(100vh - 400px);
}

.content-wrap .content-sizectrl {
  min-height: 520px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.geraltitle {
  margin-bottom: 30px;
}

.logcad-tab {
  list-style: none;
  margin: 0;
}

.alerterrorh4, .alertsuccessh4 {
  display: none;
  font-size: 13px;
  text-transform: uppercase;
}

.alerterrorh4 {
  color: red;
}

.alertsuccessh4 {
  color: green;
}

.alerterrorcep, .alertsuccesscep {
  display: none;
  font-size: 13px;
  text-transform: uppercase;
}

.alerterrorcep {
  color: red;
}

.alertsuccesscep {
  color: green;
}

.logcad-wrap {
  width: 100%;
  float: left;
  padding: 30px;
  margin-bottom: 30px;
}

.logcad-tab-wrap {
  float: left;
  width: 100%;
}

.logcad-tab li {
  float: left;
  margin: 0 5px -1px 0;
}

.teste {
  margin-left: -25px;
  margin-top: 1px;
}

.logcad-tab li a {
  float: left;
  list-style: none;
  padding: 0;
  transition: all 0.5s ease;
  color: #333;
  background: #ddd;
  padding: 10px 40px;
  text-shadow: 1px 1px 0px rgba(255, 255, 255, 1);
}

.logcad-tab li.ativo a {
  color: #333;
  background: #fff;
}

.logcad-wrap article.ativo {
  display: block;
}

.cad .lcarticle-wrap {
  padding-top: 0;
  padding-bottom: 0;
}

.has-feedback {
  position: relative;
}

.form-group {
  margin-bottom: 15px;
}

.has-feedback .form-control {
  padding-right: 42.5px;
}

input[type="text"], input[type="password"] {
  padding: 6px 10px;
  padding-right: 10px;
}

.form-control {
  border-radius: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.no-log {
  font-size: 12px;
}

.logcad-wrap .Actions .yellow-btn {
  margin-bottom: 0;
}

.Actions .yellow-btn {
  margin: 15px 0;
  margin-bottom: 15px;
  padding: 7px 30px;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

.yellow-btn {
  font-family: 'Open sans';
  text-shadow: 1px 1px 0px rgba(153, 128, 60, 1);
  border: none;
  color: #fff;
  padding: 5px 15px;
  background-color: #d2b03f;
  transition: all 0.5s ease;
  margin-top: 20px;
}

.article-title span {
  font-family: 'Droid Serif';
  font-size: 16px;
  font-style: italic;
  color: #797979;
  margin-top: 10px;
  display: block;
  padding-bottom: 15px;
}

.article-content h4 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

h4 {
  color: #333;
  font-size: 16px;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 400;
  margin: 5px 0;
  margin-bottom: 5px;
}

.cadtipo .form-group {
  margin: 0;
}

.logcad-wrap .form-group label {
  font-weight: normal;
}

.cadtipo {
  margin-bottom: 15px;
}

.cadtipo label.ativo {
  color: #fff;
  background: #d2b03f;
  text-shadow: 1px 1px 0px rgba(153, 128, 60, 1);
}

.cadtipo label {
  transition: all 0.5s ease;
  position: relative;
  cursor: pointer;
  width: 100%;
  text-align: center;
  color: #909090;
  background: #ddd;
  padding: 10px 0;
  text-shadow: 1px 1px 0px rgba(255, 255, 255, 1);
}

.article-content label {
  font-size: 14px;
  font-weight: normal;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

.cad .Actions input {
  margin-top: 5px;
}

.Actions .gray-btn {
  padding: 7px 30px;
}

.gray-btn {
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);
  border: none;
  color: #fff;
  padding: 5px 15px;
  background-color: #797979;
  transition: all 0.5s ease;
}

button, input, select, textarea {
  font-family: inherit;
  font-size: inherit;
}

@media only screen and (min-width: 768px) {
  .login-form {
    max-width: 40%;
    justify-self: center;
    margin: auto;
  }
}

@media only screen and (max-width: 768px) {
  .register-form .row {
    display: block !important;
    max-width: 100%;
    margin: auto;
  }
}