.product-media-card {
  width: 405px;
  height: 405px;
  padding-left: 5px;
  padding-top: 5px;
}

.product-media-card div img {
  padding-right: 5px;
  padding-bottom: 5px;
  object-fit: contain !important;
}

.js-image-zoom__zoomed-area {
  height: 150px !important;
  width: 150px !important;
  margin-top: 0px;
}

.product-image-btn {
  height: 100px;
  width: 100px;
  object-fit: cover;
}

.minheigth {
  min-height: 10vh;
}