.dropzone {
  background-color: rgba(0, 0, 0, 0.05);
  text-align: center;
  height: 70px;
  border: 2px dashed gray;
  margin-top: 5px;
}

.dropzone:hover {
  cursor: pointer;
}

.dropzone-thumbs-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
}

.dropzone-thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
  position: relative;
}

.dropzone-thumb-inner {
  display: flex;
  overflow: hidden;
  width: 96px;
  height: 90px;
}

.dropzone-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dropzone-thumb-delete-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 5px;
  height: 25px;
  width: 25px;
  padding: 0px !important;
}

.dropzone-thumb-delete-icon {
  color: white;
  font-size: 10px;
}