.bid-value-strong {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  vertical-align: sub;
}

.bid-bg-dark {
  background-color: gray;
  color: white;
  width: 220px;
  margin: auto !important;
}

.bid-bg-dark div:nth-child(3) {
  align-self: center;
}
.bid-bg-dark div:nth-child(1) {
  font-size: 18px;
  font-weight: 800;
  align-self: center;
}

.bid-bg-dark label, .bid-bg-dark input {
  cursor: pointer;
}

.bid-bg-dark input {
  font-size: 20px;
}

.bid-btn:disabled, .bid-btn:disabled:hover {
  color: lightgray;
  cursor: not-allowed;
}