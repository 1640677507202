.leilao-galeria {
  font-size: 13px;
  color: #888;
  font-family: 'Droid Serif', serif;
  font-style: italic;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.home-card-body.card-body {
  height: 117.5px;
}

.card-img-top {
  border-radius: 10px !important;
}

.card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.leilao-title > a {
  width: 100%;
  color: #333;
  text-transform: uppercase;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.title {
  color: #1d4d74;
}

.is-newsectiontitle {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.is-newsectiontitle h3 {
  font-weight: 600;
  color: #333;
  font-size: 35px;
  font-family: 'Noto Sans', Arial, sans-serif;
  letter-spacing: -1px;
  position: relative;
  margin: 0;
}

.is-newsectiontitle h3 span {
  color: #F04C46;
  line-height: 60px;
  font-size: 35px;
  font-weight: 600;
  letter-spacing: -3px;
}

.span-text {
    font-size: 1.75rem;
    font-weight: 600;
    letter-spacing: -3px;
}

.is-newsectiontitle {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.is-newsectiontitle h3 {
  font-weight: 600;
  color: #333;
  font-size: 35px;
  font-family: 'Noto Sans', Arial, sans-serif;
  letter-spacing: -1px;
  position: relative;
  margin: 0;
}


.is-newsectiontitle.is-vendapos h3 span {
  color: #6082a5;
  font-weight: 400;
}

.is-newsectiontitle h3 span {
  line-height: 60px;
  font-size: 35px;
  font-weight: 600;
  letter-spacing: -3px;
}

.is-vendadireta-search-home {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: rgb(29, 77, 116) !important;
}

.is-anuncio-wrap {
  background-color: #f9f9f9;
  padding: 40px 0;
}


.banner-text.has-bannershadow {
  background-color: rgb(29, 77, 116);
}

.banner-text {
  padding: 20px 0;
}

.is-newbanner .has-shadow {
  text-shadow: 1px 1px 2px rgb(51 51 51);
}

.is-newbanner .white-txt {
  color: #fff !important;
}

.is-newbanner .dia-leilao {
  color: #000;
  font-size: 14px;
  padding-bottom: 15px;
  margin-bottom: 10px;
  position: relative;
}

.is-newbanner .titulo-leilao {
  text-transform: uppercase;
  color: #000;
  font-size: 32px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.is-newbanner .dia-leilao:before {
  content: "";
  width: 70px;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 15px;
  background-color: #d2b03f;
}

.is-newbanner .galeria-leilao {
  font-family: 'Droid Serif', serif;
  color: #888;
  font-size: 12px;
  font-style: italic;
}

.is-newbanner .galeria-leilao i {
  font-size: 16px;
  margin-right: 5px;
}

.is-vendadireta-btn {
  line-height: 40px;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -2px;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.is-vendadireta-btn a {
  font-size: 25px;
  color: #333;
}

.is-vendadireta-btn i {
  color: #6082a5;
  margin-right: 10px;
}

.not-hover:hover {
  color: black;
}


.icon-hover, .is-vendadireta-btn a:hover span {
  color: rgb(29, 77, 116);
}

.is-vendadireta-btn span {
  font-size: 25px;
  color: #6082a5;
}

.carousel-control-prev {
  margin-left: -120px;
}

.carousel-control-next {
  margin-right: -120px;
}

.borda-redonda {
  border-radius: 15px !important;
}

.home-card {
  width: 15rem;
  margin-bottom: 30px;
}

.home-card .card {
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.2);
}

.carousel-control-next {
  z-index: 1;
}

.categories-select__menu {
  z-index: 10 !important;
}

.btn-custom {
  background-color: #1d4d74 !important;
  border-color: #1d4d74 !important;
}

.home-carousel-img {
  object-fit: contain;
}

.home-carousel-item {
  max-height: 350px;
  overflow: hidden;
}

.home-store-logo {
  object-fit: cover;
  height: 150px;
}

.carousel-caption-text h5 {
  font-weight: 900;
}

.carousel-caption-text {
  padding: 0px;
  margin: 0px;
  color: black;
}

.carousel-text-gray {
  color: gray;
}

.mobile-none {
  display: block;
}

.carousel-image-multiple div {
  text-align: center;
  padding: 5px 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
}

.carousel-image-multiple {
  display: grid;
  grid-template-areas:
    'up up down1'
    'up up down2';
  grid-gap: 10px;
  height: 200px;
}

.carousel-image-multiple div:first-child {
  grid-area: up;
  width: 100%;
  height: 100%;
}

.carousel-image-multiple div:nth-child(2) {
  grid-area: down1;
  width: 100%;
  height: 100px;
}

.carousel-image-multiple div:nth-child(3) {
  grid-area: down2;
  width: 100%;
  height: 100px;
}

@media only screen and (min-width: 768px) {
  .home-card {
    flex: 0 0 25% !important;
    max-width: 25% !important;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .mobile-none {
    display: none;
  }
  .home-carousel-item {
    height: 300px;
  }
  .card-img-top {
    height: auto;
  }
  .carousel {
    max-width: calc(100vw - 30px);
  }
  .carousel-caption {
    bottom: 0px;
    height: 90%;
  }
  .carousel-caption h3 {
    font-size: 15px;
  }
  .carousel-caption p {
    font-size: 12px;
  }
  .home-btn {
    max-width: 100vw;
    margin: auto;
  }
  .home-grid {
    max-width: 100vw;
    margin: auto;
    font-size: 12px;
  }
  .home-card {
    margin-top: 10px;
  }
  .home-card-body {
    max-width: 100%;
  }
  .home-card-body .h5 {
    font-size: 15px;
  }
}

.carousel-control-prev, .carousel-control-next {
  height: 100px;
  margin-top: auto;
  margin-bottom: auto;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  width: 50px !important;
  height: 50px !important;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ccc' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ccc' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e") !important;
}