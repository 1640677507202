.product-btn:hover {
  background-color: #1d4d74bd;
  color: white;
}

.card-text {
  text-overflow: ellipsis;
  flex-wrap: nowrap;
}

.product-delete {
  position: absolute;
  top: 10px;
  right: 60px;
  padding-bottom: 8px !important;
}

.product-item {
  position: absolute;
  top: 10px;
  right: 10px;
  padding-bottom: 8px !important;
}