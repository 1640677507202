.contato-form, .address {
    margin-top: 30px;
}

.contato-cadastro {
    margin-bottom: 50px;
}

.input-label-container {
    margin-bottom: 10px;
}

.input-label-container .label-container {
    display: table;
}

.input-label-container .input-container {
    padding-left: 0;
}

.input-label-container .form-group {
    margin: 0;
}

.input-label-container .label-container label {
    font-weight: normal;
    font-size: 13px;
    display: table-cell;
    vertical-align: middle;
    height: 34px;
}

.form-group textarea {
    border: 1px solid #ddd;
    padding: 6px 10px;
    width: 100%;
    height: 135px;
    resize: none;
}

.address {
    margin-bottom: 20px;
}

.address p {
    font-size: 13px;
    margin-bottom: 10px;
}

.address h4 {
    margin: 0;
    color: rgb(29, 77, 116);
    font-size: 15px;
    padding-bottom: 5px;
}

.color-blue {
    background-color: rgb(29, 77, 116) !important ;
}

.has-feedback {
    position: relative;
}

.col-xs-4 {
    width: 33.33333333%;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
}

.col-xs-8 {
    width: 66.66666667%;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
}

.marginh2 h2 {
    margin: 0 0 40px 0;
}