.smoothscroll-top {
    position:fixed;
    text-align:right;
    z-index: 3;
    color:#fff;
    height:50px;
    line-height:44px;
    right:30px;
    padding-top:2px;
    border-radius:5px;
    transition:all .5s ease-in-out;
    transition-delay:.2s;
    bottom: 15px;
    display: none;
}

.coloracao {
    color: #fff;
    background-color: #1d4d74 !important;
    border-color: #1d4d74 !important;
}