.is-footeraux-wrap {
    padding: 15px 0;
}

.is-footeraux-wrap li {
    margin-top: 10px;
}

.mq-faux {
    width: 100%;
    display: block;
    float: left;
    margin: 15px 0;
}

h4 {
    color: #333;
    font-size: 16px;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
    margin: 5px 0;
}

.col-xs-6 {
    width: 50%;
    float: left;
}

.col-xs-12 {
    width: 100%;
    float: left;
}

.col-xs-4 {
    width: 33%;
    float: left;
}