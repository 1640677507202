.auction-delete {
  position: absolute;
  top: 10px;
  right: 10px;
  padding-bottom: 8px !important;
}

.card-img-top {
  height: 200px;
  object-fit: cover;
}

.auctioneers-card-link {
  cursor: pointer;
  color: black;
}