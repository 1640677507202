/* Span */
.subnav-fundo {
    border-bottom: 1px solid #F1F1F1;
    width: 100%;
    float: left;
    margin-bottom: 25px;
}

.mc-content {
    padding-bottom: 30px;
    height: 100%;
}

.mc-content .title-box {
    display: table;
    width: 100% !important;
    float: left;
    padding: 0;
    position: relative;
    margin-bottom: 25px;
}

.is-contasite .intern-title h3 {
    line-height: 17px;
}

.intern-title h3 {
    width: auto;
    color: #565656;
    margin: 0;
    line-height: 30px;
    padding: 0 0 0 40px;
    font-size: 16px;
    position: Relative;
}

.intern-title h3 .apoioinfotxt {
    font-size: 11px;
    width: 100%;
    background: none !important;
    margin: 0;
}

.mc-label {
    width: 100%;
    font-size: 11px;
    color: #565656;
    font-weight: bold;
    float: left;
}

.is-sidefiltercontent {
    max-height: 3000px;
    transition: all 0.5s ease-out;
    opacity: 1;
}

.mc-info-block {
    margin-bottom: 10px;
}

.mc-label {
    width: 100%;
    font-size: 11px;
    color: #565656;
    font-weight: bold;
    float: left;
}

.mc-label-info {
    float: left;
    width: 100%;
    color: #7c7c7c;
    font-size: 14px;
}

.mc-label-link {
    float: left;
    color: #2564c2;
    font-size: 11px;
    font-weight: bold;
}

.menu-painel li {
    box-sizing: border-box;
    display: inline-block;
}

.subnav-menu ul li a {
    transition: all 0.5s ease;
    height: 32px;
    text-align: center;
    color: #65605B;
    font-weight: normal;
    font-size: 11px;
    float: left;
    width: 100%;
    position: relative;
    padding-top: 55px;
    line-height: 14px;
}

.list-avatar {
  height: 70px;
}