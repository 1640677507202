/* CSS João */


.is-breadcrumb .is-breadlabel {
    font-weight: bold;
    color: #737373;
    margin-right: 10px;
}

.is-breadcrumb ul {
    display: inline-block;
}

.is-breadcrumb li {
    display: inline-block;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 11px;
    color: #757575;
    margin-right: 5px;
}

.is-breadcrumb li a {
    margin-right: 10px;
    color: #a9a6a6;
}

.is-breadcrumb li .iconRight {
    color: #a9a6a6;
}

.is-breadcrumb li:last-child {
    font-weight: bold;
}

/* CSS João */

/* ==========================================================================
   PERSONAL
   ========================================================================== */
a[href^=tel] {
   text-decoration:inherit;
   color: inherit;
}
html {
  height: 100%;
}
body {
  font-family: 'Open Sans', Arial, sans-serif;
  height:100%;
  width:100%; 
  overflow-x:hidden;
}
a:focus {
  outline: 0;
}
a {
  line-height: normal;
}
input:focus {
  outline: 0;
}
.wrapper {
  position: relative;
  min-height: 100%;
}

.three, .row .three {
    width: 25%;
}
.twelve, .row .twelve {
    width: 100%;
}
#senhacampo2 { font-family:'dotsfont'; }
#toTop { position:fixed; bottom:15px; right:15px; background:#fff; border:1px solid #ddd; text-align:center;  z-index:999; padding:10px; color:#8E8E8E; cursor:pointer; display:none;
  -webkit-box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.3);
  box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.3);
}
#toTop i { margin:0 10px 0 0; }
.nopadding { padding:0 !important; }
.nopadding-left { padding-left:0 !important; }
.nopadding-right { padding-right:0 !important; }
input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"],
input[type="time"], input[type="url"], textarea, select, textarea { border-radius:0!important; box-shadow: none; border: 1px solid #cccccc;}

.toggleinfo, .Botao, .EmailNewsletter, .vermais-btn a, .fav-watch-btns a, .fav-watch-btns i, .catalogo-fav-watch .watch, .catalogo-fav-watch .watch i, .swiper-pagination-bullet,
.status-prod .aovivo-status, .is-btn, .tabela-lances .tbl-button, .tabela-favoritos tr.ok td a.tbl-button, #content .inner-catalog .right-side .peca-info .preco-bid ul li .bidnumber i,
.paginacao ul li a { transition: all 0.5s ease-in-out!important; -webkit-transition: all 0.5s ease-in-out!important; -moz-transition: all 0.5s ease-in-out!important; -ms-transition: all 0.5s ease-in-out!important; -o-transition: all 0.5s ease-in-out!important;}
.itemativo { display:block !important; }

.tabela-bloqueios { border:none; }
.tabela-bloqueios a { color:#2564c2; font-size:16px; font-weight:bold; }
.tabela-bloqueios a:hover { color:#2564c2; text-decoration:underline; }
.tabela-bloqueios thead { background:#fff; }
.tabela-bloqueios thead th { padding:8px 10px 9px; background-color:#ff0000; text-transform:uppercase; border:none; font-size:11px; color:#fff; }
.tabela-bloqueios tbody tr { background:#fff !important; }
.tabela-bloqueios tbody td { padding:9px 10px; vertical-align: middle; font-size:11px; border-bottom:1px solid #fee2e2; background:#fff; }
.wrap-bloq { padding:15px; background:#fff3f3; margin-bottom:20px; }
.wrap-bloq table { background:#fff3f3; }
.bloqueio-title p { color:#ff0000; font-size:12px; margin:0; margin-bottom:15px !important; }
.conta-subnav .bloqueio-title h2 { position:relative; margin-bottom:10px; }
.conta-subnav .bloqueio-title h2 i { position:absolute; left:0; top:50%; color:#ff0000; }

.cntblck { display:block; width:100%; margin-bottom:15px; font-size:13px; color:#6b6b6b; line-height:20px; padding-left:10px; }
.cntblck p { margin:0 !important; line-height:20px; min-height:18px; padding:0 !important; }
.cntblck span { margin:0 !important; line-height:20px; min-height:18px; font-size:13px; color:#6b6b6b; }
.cntblck .section-title { padding:0; }
.info-box .cntblck { padding:0; }
 
/* ==========================================================================
   MODAL
   ========================================================================== */
.login-wrap { max-height:0; overflow:hidden; opacity:0;   transition: opacity 1s ease-in-out; -moz-transition: opacity 1s ease-in-out; -webkit-transition: opacity 1s ease-in-out;}
.envio-wrap { max-height:0; overflow:hidden; opacity:0;   transition: opacity 1s ease-in-out; -moz-transition: opacity 1s ease-in-out; -webkit-transition: opacity 1s ease-in-out;}
.amigo-wrap { max-height:0; overflow:hidden; opacity:0;   transition: opacity 1s ease-in-out; -moz-transition: opacity 1s ease-in-out; -webkit-transition: opacity 1s ease-in-out;}
.contato-wrap { max-height:0; overflow:hidden; opacity:0;   transition: opacity 1s ease-in-out; -moz-transition: opacity 1s ease-in-out; -webkit-transition: opacity 1s ease-in-out;}
.esqueceu-wrap { max-height:0; overflow:hidden; opacity:0; transition: opacity 1s ease-in-out; -moz-transition: opacity 1s ease-in-out; -webkit-transition: opacity 1s ease-in-out;}
.response-wrap { max-height:0; overflow:hidden; opacity:0; transition: opacity 1s ease-in-out; -moz-transition: opacity 1s ease-in-out; -webkit-transition: opacity 1s ease-in-out; }
.response-wrap .erro { display:none;}
.response-wrap .sucesso { display:none;}
.response-wrap .alerta { display:none;}
.default-modal.red-warning .erro { display:block; }
.default-modal.green-warning .sucesso { display:block; }
.default-modal.yellow-warning .alerta { display:block; }
.red-warning .close-reveal-modal, .green-warning .close-reveal-modal { color:#fff !important; }
.red-warning .warning-text b { color:#6B2F2F; }
.green-warning .warning-text b { color:#1E421E; }
.default-modal .ativo { opacity: 1; max-height:1000px; }
.default-modal .loading-box { display:none; }
.default-modal.red-warning, .default-modal.green-warning { padding:10px 20px 20px 20px; }
#confirmalance-modal { background-color: #F7F7B1; border:1px solid #E0E095; }
#confirmalance-modal .close-reveal-modal { color:#535353 !important; }
#confirmalance-modal .confirmalance-title { float:left; margin-bottom:10px; width:100%; }
#confirmalance-modal .confirmalance-title h2 { font-size:25px; margin-bottom:5px; color:#535353 !important; font-weight:300; text-transform:none; }
#confirmalance-modal .handler { margin-bottom:10px; float:left; width:100%; }
#confirmalance-modal .handler p { font-size:14px; margin-bottom:0; color:#535353; font-weight:400; }
#confirmalance-modal .handler p b { color:#535353; }
#confirmalance-modal .aceitelance { float:left; width:100%; margin-top:10px; }
#confirmalance-modal .aceitelance li { padding:0; width:auto; margin:0 10px 0 0; border:none; }
#confirmalance-modal .aceitelance li:hover { border:none; }
#confirmalance-modal .aceitelance li input { border:none; background-color:#535353; color:#fff; padding:10px 20px; }
#confirmalance-modal .aceitelance li input:hover { border:none !important; background-color:#000; }
#confirmalance-modal .aceitelance li input:focus { border:none !important; }
#confirmalance-modal .aceitelance li .lancarbtn {  background-color:#699E69;  }
#confirmalance-modal .aceitelance li .lancarbtn:hover {  background-color:#507750; }
.voltarinicio { margin:10px 0 0 0 !important; float:left;  }
.overesp { position:fixed; top:0; left:0; z-index:999; width:100%; padding-bottom: 11px !important; opacity:0;  transition: all 1s ease-in-out; -moz-transition: all 1s ease-in-out; -webkit-transition: all 1s ease-in-out; }
.overesp .warning-title { text-align:center; }
.overesp .warning-text { text-align:center; font-size:16px !important; }
.overesp .warning-title i { font-size:50px; }
.overesp-wrap { position:relative; width:100%; padding:65px 20px; }
.overesp .closerespoverlay { position:absolute; top:0; right:0; }
.anchorlead { position:absolute; }
.anchorpos { float:left; width:100%; position:relative; }
.default-modal h2 {
  text-transform: uppercase;
  margin: 0 0 20px 0;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Open Sans', Arial, sans-serif;
  color: #8E8E8E;
}
.default-modal h2 + span { margin-top:-15px; display:block; margin-bottom:20px;}
.default-modal h5 {
  color: #737373;
  font-size: 13px;
  font-weight: 600;
  margin-top: 0;
  font-family: 'Open Sans', Arial, sans-serif;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.default-modal form {
  overflow: hidden;
  margin: 0;
  width:100%;
}
.default-modal p,
.default-modal label {
  font-size: 13px;
  font-weight: 300;
  color: #8E8E8E;
}
.default-modal p {
  margin-bottom: 15px;
}
.default-modal p b { color:#737373; }
.default-modal p:last-child {
  margin-bottom: 0;
}
.default-modal label,
.default-modal input,
.default-modal button {
  float: left;
}
.default-modal .minienviar {
  display: block;
  width: 100%;
  padding: 5px;
  background: #525252;
  color: #fff;
  border: 0;
  padding: 10px 20px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.default-modal .minienviar:hover {
  background: #696969;
  border:none !important;
}
.default-modal .sucessobox { display:none; }
.default-modal label {
  margin-right: 10px;
}
.default-modal input,
.default-modal textarea {
  border-radius: 0;
  box-shadow: none;
  padding: 5px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  font-size: 13px;
  color: #8E8E8E;
  margin:0;
}
.default-modal input:hover,
.default-modal textarea:hover,
.default-modal input:focus,
.default-modal textarea:focus {
  border: 1px solid #A7A7A7;
  outline: 0;
}
.default-modal .Actions input, .default-modal .Actions .comprov_btn  {
  display: block;
  width: 100%;
  padding: 5px;
  background: #737373;
  color: #fff;
  border: 0;
  padding: 10px 20px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.default-modal.Actions input:hover {
  background: #696969;
}
.default-modal ul {
  list-style-type: none;
  float: left;
  margin-bottom: 0;
}
.default-modal ul li {
  width: 100%;
  float: left;
  line-height: 32px;
  margin-bottom: 10px;
  padding: 0 15px;
  border: 1px solid #f1f1f1;
  font-size: 13px;
  color: #8E8E8E;
}
.default-modal ul li:last-child {
  margin-bottom: 0;
}
.default-modal ul li:nth-child(even) {
  background-color: #f3f3f3;
  border: 1px solid #eaeaea;
}
.default-modal .input-radio {
  margin-right: 10px;
  margin-bottom: 10px;
}
.default-modal .mini-info {
  float: left;
  margin-bottom: 15px;
}
.default-modal .mini-img-outer {
  width: 115px;
  height: 115px;
  border: 1px solid #F1F1F1;
  text-align: center;
  background-color: #FFF;
  position: relative;
  float: left;
  margin-right: 15px;
}
.default-modal .mini-img-inner {
  width: 115px;
  height: 115px;
  display: table-cell;
  max-width: 115px;
  max-height: 110px;
  vertical-align: middle;
}
.default-modal .mini-img-inner img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}
.default-modal .mini-description,
.default-modal .send-to-friend {
  float: left;
}
.default-modal .send-to-friend textarea {
  height: 75px;
}
.default-modal .mini-description {
  width: 70%;
}
.default-modal .mini-description p:last-child {
  margin-bottom: 0;
}
.default-modal .captcha {
  float: left;
  margin-bottom: 15px;
}
.default-modal .captcha input {
  margin-bottom: 0;
}
.default-modal .captcha label {
  width: 100%;
  margin-bottom: 5px;
}
.default-modal .captcha .realperson-challenge {
  width: 45%;
  float: right;
}
.default-modal .captcha .realperson-challenge .realperson-text {
  text-align: center;
  background: #EFEFEF;
}
.default-modal .captcha .is-realperson {
  float: left;
  width: 45%;
}
.default-modal .google-maps {
  border: 1px solid #DADADA;
}
.default-modal .flex-video {
  margin-bottom: 20px;
}
.default-modal h6 {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  color: #8E8E8E;
  font-family: 'Open Sans', Arial, sans-serif;
  margin: 0;
}
.default-modal span,
.default-modal a {
  font-size: 13px;
  color: #8E8E8E;
}
#local-modal p {
  margin-bottom: 0;
}
#local-modal .google-maps {
  height: 350px;
}
#termos-condicoes-modal .handler {
  height: 350px;
  overflow-y: scroll;
}
#leilao-modal h5,
#leilao-modal p {
  margin-bottom: 10px;
}
#historico-modal ul li span {
  font-weight: 600;
  padding-right: 25px;
}

#video-modal .flex-video {
  margin-bottom: 0;
}
#historico-modal h2 { margin-bottom:10px; }
.is-histInfoleilao { margin-bottom:15px; font-family:'Open Sans', Arial, sans-serif; line-height:20px; color:#8E8E8E;  }
.is-histInfoleilao div { display:inline-block; margin-right:20px; font-size:12px; }
.is-histInfoleilao i { font-size:14px; margin-right:3px; color:#a5a5a5; }
.is-histInfoleilao span { font-weight:bold; color:#757575; font-size:14px; }

#confirma-termos-modal .handler {overflow: auto; height: 143px; padding-right: 15px;}
#confirma-termos-modal .aceitetermos { margin-top:20px; padding-top:20px; border-top:1px solid #ddd;  }
#confirma-termos-modal .aceitetermos li { width:100%; float:left; padding:0; margin:0 0 15px 0; background:transparent; border:none; }
#confirma-termos-modal .aceitetermos li:last-child {margin: 0;}
#confirma-termos-modal .aceitetermos li input { margin-right:10px; background:#525252; color:#fff; padding:5px 15px; border-radius:0; border:none; border: 1px solid #525252;}
#confirma-termos-modal .aceitetermos li label { height:20px; float:left; line-height:20px; margin:0; }
#confirma-termos-modal .aceitetermos #idaceito { width:20px; height:20px; margin-right:10px;  }
.CPFopt, .EMopt { display:none; }
.no-log {
  float: left;
}
.no-log a {
  color: #2564c2;
  font-weight: 600;
}
#login-modal .send-to-friend a {
  font-size: 13px;
  font-weight: 600;
  color: #2564c2;
}
.no-log span {
  margin-right:10px;
}
#login-modal .send-to-friend .senha {
  margin-bottom: 5px;
}
.Actions input {
  margin-top: 10px;
  margin-bottom: 10px;
}
.default-modal .minienviar:hover { border: 0!important;}
@media only screen and (max-width: 620px) {
  .default-modal .captcha .realperson-challenge,
  .default-modal .captcha .is-realperson {
    width: 100%;
  }
  .default-modal .captcha .realperson-challenge {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 460px) {
  #local-modal .google-maps {
    height: 250px;
  }
  #historico-modal ul li span {
    padding-right: 15px;
    font-size:11px;
    float:left;
  }
}
/* ==========================================================================
   HEADER
   ========================================================================== */
.mobile-search {
  width: 100%;
  padding-right: 50px;
  position: relative;
  background: #000;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  max-height: 0;
  overflow: hidden;
}
.mobile-search form {
  display: block;
  margin: 0;
  opacity: 1;
}
.mobile-search input { height: 30px; border-radius: 0;}
.mobile-search input,
.mobile-search select,
.mobile-search button {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  float: right;
  margin: 10px 0;
}
.mobile-search input:hover,
.mobile-search select:hover,
.mobile-search button:hover {
  border: 1px solid #000;
}
.mobile-search input:focus,
.mobile-search select:focus,
.mobile-search button:focus {
  border: 1px solid blue;
  outline: 0;

}
.mobile-search .search_bar {
  width: 100%;
  float: left;
  height: 30px;
  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 0;
}
.mobile-search .search_select {
  width: 100%;
  height: 30px;
  padding: 5px 10px;
}
.mobile-search .search_button {
  width: 40px;
  position: absolute;
  top: 0;
  right: 10px;
  height: 30px;
  padding: 5px 10px;
  background-color: #808080;
  border: 1px solid #aaa;
}
.mobile-search .search_button i {
  font-size: 18px;
  color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.mobile-search .search_button:hover {
  background-color: #fff;
}
.mobile-search .search_button:hover i {
  color: #000;
}
.mobile-search .search_button:focus {
  outline: 0;
}
#header {
  /*background-image: url(../imgs/header_test.jpg);
  background-position: center;
  background-size: cover;*/
  background-position: inherit;
  background-size: inherit;
  position: relative;
}
#header a {
  color: #fff;
  font-size: 10px;
  line-height: normal;
}
#header .barra-topo {
  padding: 8px 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid #000;
  width: 100%;
}
#header .barra-topo .no-padding-left {
  padding-left: 0;
}
#header .barra-topo p {
  color: #fff;
  font-size: 10px;
  font-family: inherit;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}
#header .barra-topo i {
  color: #fff;
  float: left;
  margin-right: 20px;
}
#header .barra-topo .border-right:after {
  content: '';
  position: absolute;
  height: 30px;
  width: 1px;
  background-color: white;
  right: 0;
  top: 0;
}
#header .barra-topo .atendimento-topo span {
  height: 28px;
  display: table;
}
#header .barra-topo .atendimento-topo p {
  display: table-cell;
  vertical-align: middle;
}
#header .barra-topo .telefone-topo span {
  display: table;
}
#header .barra-topo .telefone-topo .mobile-tel {
  display: table;
}
#header .barra-topo .telefone-topo p {
  display: table-cell;
  vertical-align: middle;
}
#header .barra-topo .telefone-topo i {
  font-size: 21px;
  line-height: 28px;
}
#header .barra-topo .mail-topo a {
  display: table;
}
#header .barra-topo .mail-topo p {
  display: table-cell;
  vertical-align: middle;
}
#header .barra-topo .mail-topo i {
  font-size: 17px;
  line-height: 28px;
}
#header .barra-topo .login-topo .login {
  float: right;
}
#header .barra-topo .login-topo #logado a  {
  margin:0 10px 0 0;
  float: right;
}
#header .barra-topo .login-topo .login p {
  float: right;
  display: table;
}
#header .barra-topo .login-topo .login .logged-container {
  float: left;
}
#header .barra-topo .login-topo .login .logged-container .my-account {
  margin-right: 10px;
}
#header .barra-topo .login-topo .login span {
  display: table-cell;
  vertical-align: middle;
  color: #fff!important;
}
#header .barra-topo .login-topo .login i {
  float: left;
  position: relative;
  line-height: 28px;
  font-size: 20px;
}
#header .barra-topo .login-topo .desktop-hide {
  display: none;
}
#header .header-content {
  position: relative;
}
#header .header-content .logo-mobile { display: none;}
#header .header-content .tobas-trigger.active i,
#header .header-content .search-trigger.active i {
  color: black;
}
#header .header-content .collapse-menu{
  width: 100px;
  height: 25px;
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -15px;
  display: none;
}
#header .header-content .tobas-trigger {
  width: 30px;
  height: 25px;
  float:right;
}
#header .header-content .tobas-trigger i {
  font-size: 30px;
  padding: 0px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  line-height:25px;
}
#header .header-content .tobas-trigger:hover i {
  color: black;
}
#header .header-content .search-trigger {
  width: 30px;
  height: 20px;
  margin-right:20px;
  float:right;
}
#header .header-content .search-trigger i {
  font-size: 25px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#header .header-content .search-trigger:hover i {
  color: black;
}
#header .header-content h1 {
  margin: 0;
  padding: 0;
  float:left;
  width:100%;
}
#header .header-content h1 a { max-width:100%; max-height:100%; float:left; }
#header .header-content h1 img { max-width:100%; max-height:100%; float:left; }
#header .header-content .fakeon {
  display: none;
}

.desktop-search-wrap { position:absolute; top:50%; right:0; margin-top:-16px; }

.header-banner { float:left; width:100%; position:relative; }

#header .header-content .search {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  width: 100%;
  float: right;
  padding-right: 50px;
  position: relative;
}
#header .header-content .search form {
  display: block;
  margin: 0;
  opacity: 1;
}
#header .header-content .search input,
#header .header-content .search select,
#header .header-content .search button {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  float: right;
  margin:0;
  height:32px;
}
#header .header-content .search input:hover,
#header .header-content .search select:hover,
#header .header-content .search button:hover {
  border: 1px solid #000;
}
#header .header-content .search input:focus,
#header .header-content .search select:focus,
#header .header-content .search button:focus {
  border: 1px solid #2564c2;
}
#header .header-content .search .search_bar {
  width: 100%;
  float: left;
  height: 30px;
  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 0;
}
#header .header-content .search .search_select {
  width: 100%;
  padding: 5px 10px;
}
#header .header-content .search .search_select:focus {
  outline: 0;
}
#header .header-content .search .search_button {
  width: 40px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  background-color: #808080;
  border: 1px solid #aaa;
}
#header .header-content .search .search_button i {
  font-size: 18px;
  color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#header .header-content .search .search_button:hover {
  background-color: #fff;
}
#header .header-content .search .search_button:hover i {
  color: #000;
}
#header .header-content .search .search_button:focus {
  outline: 0;
}
#header .mobile-menu.open {
  display: none;
}
#header .mobile-menu.open ul {
  max-height: 500px;
}
#header .mobile-menu {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
#header .mobile-menu .active {
  background-color: #535353;
}
#header .mobile-menu .active a {
  color: #B3B3B3;
}
#header .mobile-menu ul {
  background-color: #A9A9A9;
  margin-bottom: 0;
  max-height: 0;
  overflow-y: hidden;
  transition-property: all;
  transition-duration: .5s;
  /*transition-timing-function: cubic-bezier(0, 1, 0.5, 1);*/
  -webkit-transition: all 0.5s ease-in-out!important;
  -moz-transition: all 0.5s ease-in-out!important;
  -ms-transition: all 0.5s ease-in-out!important;
  -o-transition: all 0.5s ease-in-out!important;
}
#header .mobile-menu ul li {
  display: block;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#header .mobile-menu ul li:hover {
  background-color: #535353;
}
#header .mobile-menu ul li:hover a {
  color: #B3B3B3;
}
#header .mobile-menu ul li a {
  text-decoration: none;
  padding: 15px;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
}
#header .menu {
  background-color: rgba(179, 179, 179, 0.5);
  width: 100%;
}
#header .menu .menu-nav {
  line-height: normal;
  margin: 0;
  background-color: rgba(179, 179, 179, 0.5);
  min-height: 45px;
  padding: 0;
  width: 100%;
  position: relative;
  border-right:1px solid #ddd;
  border-left:1px solid #ddd;
}
#header .menu .menu-nav .active a {
  color: #B3B3B3;
  float:left;
  width:100%;
}
#header .menu .menu-nav ul {
  margin: 0;
  display: table;
  height: 45px;
  line-height: 45px;
  list-style: none;
  width:100%;
}
#header .menu .menu-nav ul > li {
  width: 14.2857142%;
  border-right: 1px solid #E2E2E2;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  display: table-cell;
  vertical-align:middle;

}
#header .menu .menu-nav ul > li:first-child {
  border-left: 0;
}
#header .menu .menu-nav ul > li:last-child {
  border-right: 0;
}
#header .menu .menu-nav ul > li a {
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  float:left;
  width:100%;
  line-height:45px;
}
#header .menu .menu-nav ul > li:hover {
  background: rgba(0, 0, 0, 0.5);
}
#header .menu .menu-nav ul > li:hover a {
  color: #B3B3B3;
}
#header .menu .menu-nav ul > li.active {
  background: rgba(0, 0, 0, 0.5);
}
.search input, .search select, .footer-invitation input, .is-irpara input, .search-container input, .is-filtro select, .EmailNewsletter,
.inner-padded-column textarea { color: #6b6b6b!important; }
/* ==========================================================================
   SLIDER
   ========================================================================== */
#slider {
  background: #8E8E8E;
  height: 350px;
  border-bottom: 1px solid #e5e5e5;
}
#slider .swiper-button-next,
#slider .swiper-button-prev {
  height: 44px;
  margin-top: -36px !important;
}
#slider .swiper-button-next i,
#slider .swiper-button-prev i {
  font-size: 55px;
  line-height: 33px;
}
#slider .swiper-pagination-bullet-active {
  background-color: #000;
}
#slider .swiper-container {
  height: 385px;
}
#slider .swiper-container .slide-text {
  margin-top: 15px;
}
#slider .swiper-container .slide-text a {
  display: block;
  height: 35px;
  overflow: hidden;
}
#slider .swiper-container .slide-leilao a {
  background-color:transparent !important;
  border: none;
}
#slider .swiper-container .slide-text img {
  border: 1px solid #000;
  width: 100%;
  height: 100%;
  display: block;
}
.slide-leilao a { font-size:12px; }
.slide-leilao span { text-transform:uppercase; font-size:11px; opacity:0.6; }
#slider .swiper-container .slide-text .banner-auction-name h2 {
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 15px;
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;
  display: table-cell;
  vertical-align: middle;
}
#slider .swiper-container .slide-text .banner-auction-info {
  padding-top: 20px;
}
#slider .swiper-container .slide-text .banner-auction-info.slide-leilao {
  padding-top:0;
}
#slider .swiper-container .slide-text .banner-auction-info h3 {
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;
  padding-bottom: 5px;
  opacity:0.6;
}
#slider .swiper-container .slide-text .banner-auction-info h4 {
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 5px;
}
#slider .swiper-container .slide-text .banner-auction-info p {
  margin: 0;
  line-height: 24px;
  font-size: 18px;
  height:80px;
  overflow:hidden;
  margin:0 0 30px 0;
  font-family: 'Open Sans', Arial, sans-serif;
  word-break: break-word;
}
#slider .swiper-container .slide-text .banner-auction-info.slide-leilao p {
  height:auto; margin-bottom:10px;
}
#slider .swiper-container .slide-text .banner-auction-info h5 {
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 25px;
  font-weight: 800;
  margin: 0;
  padding-bottom: 25px!important;
}
#slider .swiper-container .slide-text .banner-auction-info h5 .is-rs { 
  font-size:20px;
  margin-right:8px;
}
#slider .swiper-container .slide-text .banner-auction-info h5 .is-valor {
  font-size:38px;
}
#slider .swiper-container .slide-text .banner-auction-info a {
  height: 40px;
  width: 130px;
  font-size: 14px;
  padding-top: 10px;
  text-align: center;
  background-color: #525252;
  color: #8E8E8E;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  margin-top:30px;
}
#slider .swiper-container .slide-text .banner-auction-info a:hover {
  background-color: #BDBDBD;
  color: #fff;
}
#slider .swiper-container .slide-text .banner-auction-info.slide-leilao a {
  width:100%; height:auto; margin:0; text-align:left; padding:0; line-height:18px; border: none!important;
}
#slider .swiper-container .slide-image {
  padding-left: 0;
}
#slider .swiper-container .slide-image .img-box-banner {
  height: 320px;
  width: 100%;
  float: left;
  margin-top: 15px;
  border: 1px solid black;
  background: #fff;
}
#slider .swiper-container .slide-image .img-box-banner .img-wrapper {
  display: table;
  width: 100%;
  height: 318px;
  line-height: 320px;
  margin: 0;
}
#slider .swiper-container .slide-image .img-box-banner .img-wrapper a {
  display: table-cell;
  vertical-align: middle !important;
  text-align: center;
  background: #fff;
}
#slider .swiper-container .slide-image .img-box-banner .img-wrapper img {
  max-width: 100%;
  max-height: 318px;
  vertical-align: middle !important;
}
#slider .slider-separator {
  width: 100%;
  height: 1px;
  background-color: #F1F1F1;
}
#slider .swiper-container .slide-image .img-box-banner, #slider .swiper-container .slide-text img { border: 1px solid #e5e5e5; background: #fff;}
#slider .swiper-button-next i, #slider .swiper-button-prev i { color: #737373; text-shadow: 1px 1px 2px rgba(150, 150, 150, 1);}
.swiper-pagination-bullet { background: #ddd !important; opacity: 1 !important;}
/*.swiper-wrapper { overflow:hidden; }*/
.pecas-slider { position:relative; padding:0 40px; height: 200px;}
.pecas-slider.lote-slider { height: 235px;}
.pecas-slider .swiper-container { position:static; }
.pecas-slider .swiper-button-prev { top:0; left:0; border:1px solid #b7b7b7; margin:0; height:100%; width:35px; }
.pecas-slider .swiper-button-next { top:0; right:0; border:1px solid #b7b7b7; margin:0; height:100%; width:35px; }
.pecas-slider i { color:#888888; }
.pecas-slider .prod-box { border:none; padding:0; }
.pecas-slider .img-box-wrap { border-color:#d6d5d5; }
#content .pecas-highlights .pecas-slider h3 { margin-bottom:10px; height:57px; }
.pecas-slider .prod-box a { color:#737373; transition:all 0.5s ease; }
.pecas-slider .prod-box:hover a { color:#000; }
.pecas-slider .prod-box:hover .img-box-wrap { border-color:#737373; }
.pecas-slider .prod-box:hover .search-login { color:#737373 !important; }
.pecas-slider .prod-box:hover .search-login span { color:#000; }

.lotes-highlights .pecas-slider { height:235px; padding:0; }

#slider .in-captation {
  height: 385px;
}
#slider .in-captation .info-text {
  margin-top: 80px;
}
#slider .in-captation .info-text h3,
#slider .in-captation .info-text p {
  text-align: center;
  margin: 0;
}
#slider .in-captation .info-text h3 {
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 35px;
  padding-bottom: 15px;
}
#slider .in-captation .info-text p {
  font-size: 25px;
  font-weight: 300;
}
/* ==========================================================================
   CONTENT
   ========================================================================== */
.content-info h4  { color: #000!important;}
.content-info p a:hover, .catalog-text a:hover { color: #121317 !important;}
.mobile-search select { border-color:#ccc;}
.is-leilao-finalizado { color:red; font-size:22px; }
.fb-share { background-color:#4b66a0; color:#fff;}
.fb-share:hover { background-color: #557BCA;}
.ongoing-auctions-title, .inner-content-title { border-bottom: 1px solid #F1F1F1!important;}
.numberbg { border: 1px solid #F1F1F1!important;}
#content .inner-content-title .cat-title .cat-title-btns .fb-share { color: #fff!important; }
.tabela-lances .tbl-button:hover { background: #000;}
.warning i, .inner-padded-column ul li:first-child h3 { color: #D07171 !important;}
.inner-padded-column ul li .inner-upload-box h3 { color: #8E8E8E!important;}
.inner-padded-column ul li button:hover { background: #737373; color:#180604;}
.content-info p a, .catalog-text a { color:#2564c2!important; text-decoration: underline;}
.google-maps h2, .pecas-highlights h3 { color: #737373!important}
.total-arrows .arrows a { border: 1px solid #F1F1F1;}
.total-arrows .arrows a:hover { background-color: #f1f1f1;}
.total-arrows .arrows a:hover i { color: #000;}
#content .inner-catalog .leilao-catalogo-top .catalog-box .facebook { color: #fff;}
.preco-bid ul li .bidnumber:hover i { color: #2564c2; }
.avaliable-button.veja-aovivo-home:hover { background: #ff0000!important; }
#content .ongoing-auctions .ongoing-auctions-container .content-buttons.aovivo-buttons a.veja-aovivo-leilao-novo { background-color: #fff !important; border:1px solid red; color: red !important; font-weight:bold !important; padding:5px 15px; }
#content .ongoing-auctions .ongoing-auctions-container .content-buttons.aovivo-buttons a.veja-aovivo-leilao-novo:hover { background-color: #ff0000 !important; color: #fff!important; border:1px solid red; }
.image-container-helper a.veja-aovivo-leilao-novo { background: #fff !important; border:1px solid red; color: red !important; font-weight:bold; padding:5px 15px; }
.image-container-helper a.veja-aovivo-leilao-novo:hover { background: #ff0000 !important; color: #fff!important; border:1px solid red; }
#content .ongoing-auctions .ongoing-auctions-container .content-buttons a.veja-aovivo-home { color: #fff!important;}
#content .google-maps {
  background: #E8E8E8;
  border-top: 1px solid #DADADA;
}
#content .google-maps h2 {
  font-size: 20px;
  font-family: 'Open Sans', Arial, sans-serif;
  color: #8E8E8E;
  font-weight: 400;
  margin: 35px 0 20px 0;
}
#content .google-maps .map-container {
  padding-bottom: 300px;
  border: 1px solid #D4D4D4;
}
#content .google-maps .flex-video {
  margin-bottom: 0;
}
#content .google-maps .address {
  margin-bottom: 55px;
  margin-top: 20px;
}
#content .google-maps .address h6,
#content .google-maps .address p {
  margin: 0;
}
#content .google-maps .address h6 {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  color: #8E8E8E;
  font-family: 'Open Sans', Arial, sans-serif;
}
#content .google-maps .address p,
#content .google-maps .address a,
#content .google-maps .address span {
  font-size: 13px;
  color: #6b6b6b;
}
#content .ongoing-auctions .warning {
  color: red!important;
}
#content .ongoing-auctions .ongoing-auctions-title {
  margin-top: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #F1F1F1;
  margin-bottom: 20px;
}
#content .ongoing-auctions .ongoing-auctions-title h2 {
  font-size: 20px;
  font-family: 'Open Sans', Arial, sans-serif;
  color: #8E8E8E;
  font-weight: 400;
  margin: 0;
}
#content .ongoing-auctions .ongoing-auctions-container {
  margin-bottom: 30px;
  float: left;
  width:100%;
}
.divider { width:100%; float:left; border-bottom:1px solid #f1f1f1; margin-top:30px; }
.ongoing-auctions .row div.ongoing-auctions-container:last-of-type .divider { border-bottom: 0; }

#content .ongoing-auctions .ongoing-auctions-container:last-child {
  border-bottom: 0;
}
#content .ongoing-auctions .ongoing-auctions-container .content-title h2 {
  font-size: 18px;
  font-family: 'Open Sans', Arial, sans-serif;
  color: #8E8E8E;
  font-weight: 300;
  margin: 0;
}
#content .ongoing-auctions .ongoing-auctions-container .content-info {
  margin-top: 0;
}
#content .ongoing-auctions .ongoing-auctions-container .content-info h4 {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  font-family: 'Open Sans', Arial, sans-serif;
  padding-bottom: 5px;
}
#content .ongoing-auctions .ongoing-auctions-container .content-info p {
  margin-bottom: 10px;
  font-size: 13px;
  color: #6b6b6b;
}
#content .ongoing-auctions .ongoing-auctions-container .content-info p a {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .ongoing-auctions .ongoing-auctions-container .content-info-important {
  position: relative;
  margin-top: 10px;
}
#content .ongoing-auctions .ongoing-auctions-container .content-info-important h5 {
  margin: 0;
  font-weight: 600;
  font-size: 15px;
  font-family: 'Open Sans', Arial, sans-serif;
  padding-bottom: 10px;
}
.important-info-close i { font-size:16px; }
#content .ongoing-auctions .ongoing-auctions-container .content-info-important .important-info-close,
#content .ongoing-auctions .ongoing-auctions-container .content-info-important .important-info-open {
  position: absolute;
  right: 0;
  top: 0;
  color: #000;
}
#content .ongoing-auctions .ongoing-auctions-container .content-info-important .important-info-close i.fa-toggle-on {
  display: none;
}
#content .ongoing-auctions .ongoing-auctions-container .content-info-important .important-info-close i.fa-toggle-off {
  display: block;
}
#content .ongoing-auctions .ongoing-auctions-container .content-info-important .important-info-close.active i.fa-toggle-on {
  display: block;
}
#content .ongoing-auctions .ongoing-auctions-container .content-info-important .important-info-close.active i.fa-toggle-off {
  display: none;
}
.content-info-important-hide {
  max-height: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  overflow: hidden;
}
.content-info-important-hide p {
  font-size: 13px;
  margin: 0;
  padding-bottom: 10px;
}
.content-info-important-hide p:last-child {
  padding-bottom: 0;
}
.content-info-important-hide.open {
  max-height: 2000px;
}
.content-info-important.close {
  display: none;
}
#content .ongoing-auctions .ongoing-auctions-container .content-image {
  height: 230px;
  position: relative;
  border: 1px solid #F1F1F1;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .ongoing-auctions .ongoing-auctions-container .content-image:hover {
  border: 1px solid #525252;
}
#content .ongoing-auctions .ongoing-auctions-container .content-image a {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
#content .ongoing-auctions .ongoing-auctions-container .content-image a img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}
#content .ongoing-auctions .ongoing-auctions-container .content-buttons {
  margin-top: 15px;
}
#content .ongoing-auctions .ongoing-auctions-container .aovivo-buttons {
  margin-top: 0;
}
.veja-aovivo-home { margin:0 0 10px 0 !important; }
.slide-text .veja-aovivo-home { margin:0 !important; color:#fff; text-align:center; background:#ff0000; line-height:35px; }
.slide-text .veja-aovivo-home i { margin:0 10px 0 0 !important; font-size:16px;  }
#content .ongoing-auctions .ongoing-auctions-container .content-buttons a {
  display: block;
  width: 100%;
  height: 32px;
  background-color: #525252;
  margin-bottom: 5px;
  padding: 5px;
  text-align: center;
  color: white;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  font-weight: normal!important;
}
#content .ongoing-auctions .ongoing-auctions-container .content-buttons a:hover {
  background-color: #BDBDBD;
}
#content .ongoing-auctions .ongoing-auctions-container .content-buttons a.veja-aovivo-home  {
  background-color: #ff0000!important;
}
#content .ongoing-auctions .ongoing-auctions-container .veja-aovivo-home i {
  padding-right: 8px;
}
#content .inner-content-title {
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #F1F1F1;
  margin-bottom: 20px;
}
#content .inner-content-title h2 {
  font-size: 20px;
  font-family: 'Open Sans', Arial, sans-serif;
  color: #8E8E8E;
  font-weight: 400;
  margin: 0;
  line-height:30px;
  margin-top:20px;
}
#content .inner-content-title .row h2 {
  margin-top:0;
}
#content .inner-content-title h2 span {
  float:left; width:100%; font-weight:bold; color:#a9a6a6; font-size:14px; line-height:10px; margin-bottom:3px;
}
.is-catalogo #content .inner-content-title { 
  border:none !important;
  margin-bottom:0 !important;
  padding-bottom:0 !important;
}
.content-title { float:left; width:100%; }
.content-info { float:left; width:100%;
  transition-property: all;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
overflow:hidden; }
.content-info.toggled { max-height:1000px !important;
  transition-property: all;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

 }
.content-title h2 { float:left; }
.toggleinfo { float:left; width:100%; border:1px solid #f1f1f1; line-height:30px; text-align:center; margin:0; color:#2564c2; }
.content-title-mobile { display:none; }
#content .inner-catalog .mobile-show {
  display: none;
}
#content .inner-content-title .more-info a {
  float: right;
}
#content .finalized .catalog-box .catalog-auction {
  margin-bottom: 10px!important;
}
#content .inner-catalog {
  margin-top: 30px;
}
#content .inner-peca, #content .inner-leilao { margin-top:15px; }
#content .inner-catalog .right-side .catalog-title {
  padding-bottom: 15px;
  padding-left:10px;
  width:100%;
  color:#737373;
}
.catalog-title {
  padding-bottom: 15px;
  padding-left:10px;
  width:100%;
  color:#737373;
}
.title-numleilao { position:absolute; top:0; left:0; text-align:left; padding-right:15px;  font-size:12px; font-weight:normal; text-transform:uppercase; line-height:16px; }
#content .inner-catalog .right-side .catalog-title h2 {
  font-size: 18px;
  font-family: 'Open Sans', Arial, sans-serif;
  color: #737373;
  font-weight: 400;
  margin: 0;
  padding-left:0;
  padding-top:20px;
  position:relative;
  font-weight:600;
  line-height:20px;
}
.catalog-title h2 {
  font-size: 18px;
  font-family: 'Open Sans', Arial, sans-serif;
  color: #737373;
  font-weight: 400;
  margin: 0;
  padding-left:0;
  padding-top:20px;
  position:relative;
  font-weight:600;
  line-height:20px;
}
#content .inner-catalog .right-side .catalog-title h2 span {
  letter-spacing:2px; 
  text-transform:uppercase;
  font-size:12px;
  opacity:0.7;
}
.is-leilao .catalog-title h2 {
  font-size: 20px;
  font-family: 'Open Sans', Arial, sans-serif;
  color: #737373;
  font-weight: 400;
  margin: 0;
}
#content .inner-catalog .right-side .is-leilao-finalizado {
  font-family: 'Open Sans', Arial, sans-serif;
  padding-left:10px;
}
#content .inner-catalog .leilao-catalogo-top {
  overflow: hidden;
}
#content .inner-catalog .leilao-catalogo-top .catalog-title {
  padding-bottom: 10px;
}
#content .inner-catalog .leilao-catalogo-top .catalog-title h2 {
  font-size: 20px;
  font-family: 'Open Sans', Arial, sans-serif;
  color: #8E8E8E;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
}



#content .inner-catalog .leilao-catalogo-top .catalog-box {
  width: 32%;
  float: left;
  margin-right: 2%;
  position: relative;
}
#content .inner-catalog .leilao-catalogo-top .catalog-bigger-box {
  width: 64%;
  float: left;
  margin-right: 2%;
  position: relative;
}
#content .inner-catalog .leilao-catalogo-top .catalog-full-box {
  width: 100%;
  float: left;
  position: relative;
}
#content .inner-catalog .leilao-catalogo-top .peca-button {
  display: block;
  width: 100%;
  height: 32px;
  background-color: #525252;
  margin-bottom: 5px;
  padding: 6px;
  text-align: center;
  color: white;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: normal!important;
}
#content .inner-catalog .leilao-catalogo-top .catalog-box .peca-button:hover {
  background-color: #BDBDBD;
}
#content .inner-catalog .leilao-catalogo-top .catalog-box .facebook {
  display: block;
  width: 100%;
  height: 32px;
  background-color: #4b66a0;
  margin-bottom: 5px;
  padding: 6px;
  color: white;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  margin-bottom: 15px;
  font-size: 12px;
  text-align:center;
  line-height:15px;
}
#content .inner-catalog .leilao-catalogo-top .catalog-box .facebook:hover {
  background-color: #557BCA;
}
/*#content .inner-catalog .leilao-catalogo-top .catalog-box .facebook span {
  font-size: 14px;
  position: absolute;
  left: 10px;
  top: 5px;
}*/
#content .inner-catalog .leilao-catalogo-top .catalog-box .facebook span {
  font-size: 14px;
  margin:0 10px 0 0;
}
#content .inner-catalog .leilao-catalogo-top .catalog-full-box .veja-aovivo-leilao {
  display: block;
  width: 100%;
  height: 32px;
  background-color: #ff0000 !important;
  margin-bottom: 5px;
  padding: 6px;
  text-align: center;
  color: white;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  margin-bottom: 15px;
  font-size: 12px;
}
#content .inner-catalog .leilao-catalogo-top .catalog-full-box .veja-aovivo-leilao:hover {
  background-color: #af0000;
}
#content .inner-catalog .leilao-catalogo-top .catalog-box:last-child {
  margin-right: 0;
}
.image-container-helper { margin-bottom:-1px; }
.image-container-helper a { padding:8px 15px; background-color:#fff; border:1px solid #f1f1f1; display:block; color:#737373; line-height:22px; width:auto; float:left; margin-right:5px; }
.image-container-helper a i { margin-right:5px; font-size:18px; line-height:20px; }
.image-container-helper a.active { background-color:#f1f1f1; }
.image-container-helper a:hover { background-color:#f1f1f1; }
.capa-tab, .video-tab { opacity:0; display:none; }
.capa-tab.active  { opacity:1; visibility:visible; display:table; width:100% !important; height:230px; }
.capa-tab.active.capa-banner a {height: 250px;}
.video-tab.active { display:block; opacity:1; visibility:visible; }
.finalized .paginacao { margin-bottom:15px; }
.finalized .paginacao p { }
#content .inner-catalog .image-container {
  max-height: 490px;
  width:100%;
  border: 1px solid #F1F1F1;
  position: relative;
  padding:15px 0;
  background:#f1f1f1;
  display:table;
  box-shadow:1px 1px 1px 0px rgba(0, 0, 0, 0.15);
  margin-bottom:20px;
}
#content .inner-catalog .image-container a {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  background-color:#fff;
  border:1px solid #ddd;
  text-align:center;
}
.image-container {
  max-height: 475px;
  width:100%;
  border: 1px solid #F1F1F1;
  position: relative;
  display:table;
}
.image-container a {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  background-color:#fff;
  border:1px solid #ddd;
  text-align:center;
}
.leilao-box-wrap .image-container a { position:relative; }
.image-container img { max-width:100%; max-height:100%; vertical-align:middle; }
.left-side .image-container { background:#f1f1f1;   box-shadow:1px 1px 1px 0px rgba(0, 0, 0, 0.15); padding-top: 15px;}
#content .inner-catalog .main-image .image-container { box-shadow:none; }
#content .inner-catalog .image-container .leilao-button { border:none; background-color:transparent; margin-right:30px; display:inline-block; width:auto; color:#737373; font-size:16px; padding:5px 0; }
.leilao-nav { float:left; width:100%; position:relative; }
.leilao-nav .peca-button {    border: none!important;
    background-color: transparent!important;
    margin-right: 30px;
    color: #737373!important;
    font-size: 16px;
    padding: 5px 0;}
.leilao-nav .peca-button:hover {
  background: transparent!important;
  color: #000!important;
}
#content .inner-content-container .leilao-nav p { text-align:center; width:100%; margin:0;  }
#content .image-container .leilao-nav p { text-align:center; width:100%; margin:0;  }
/*.is-whats { position:absolute; top:-3px; right:0; font-size:25px; color:#737373; line-height:32px; height:40px; }*/
.zapos {position: absolute;padding-left: 15px;padding-top: 3px;}
.ppos {width: 80%!important;}
@media only screen and (max-width: 950px) {
  .ppos {width: 100%!important;}
  .zapos {position: relative; width: 100%!important; }
}
@media only screen and (max-width: 767px) {
  .ppos {width: 50%!important;}
  .zapos {position: absolute; width: 50%!important; }
  .catalog-title {padding-left: 0;}
  
}
@media only screen and (max-width: 400px) {
  .ppos {width: 100%!important;}
  .zapos {position: relative; width: 100%!important; }
}
#content .inner-catalog  .is-whats a { line-height:22px; width:auto; border:none; background:transparent; color:#25d366; font-size:14px; font-weight:bold; display:inline-block; }
#content .inner-catalog  .is-whats i { font-size:22px; display:inline-block; line-height:26px; background-color:#25d366; color:#fff; border-color:#25d366; border-radius:30px; width:25px; height:25px; text-indent:-1px;  }
#content .image-container .is-whats a { line-height:28px; width:auto; border:none; background:transparent; color:#25d366; font-size:14px; font-weight:bold; display:inline-block; }
#content .image-container .is-whats i { font-size:22px; display:inline-block; line-height:26px; background-color:#25d366; color:#fff; border-color:#25d366; border-radius:30px; width:25px; height:25px; text-indent:-1px;  }

#content .inner-catalog .leilao-catalogo-top .is-whats-mobile a, .is-whats-mobile a { padding:8px 10px; background-color:#25d366; color:#fff; width:100%; text-align:center; float:left; margin-bottom:10px; }
.image-container .leilao-button { border:none; margin-right:15px; background-color:transparent; display:inline-block; width:auto; text-align:center; color:#737373; font-size:14px; padding:5px 0; }
#content .inner-catalog .image-container .leilao-button:hover { color:#000; }
.image-container .leilao-button:hover { color:#000; }
#content .inner-catalog .image-container .leilao-button i { margin-right:5px;  font-size:18px; }
.image-container .leilao-button i { margin-right:5px;  font-size:14px; }
#content .inner-catalog .image-container a img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  overflow: auto;
}
.catalog-title h2 { margin-top:0; }
#content .inner-catalog .catalog-text h3 {
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 500;
}
#content .inner-catalog .catalog-text h3:first-child {
  margin-top: 15px;
}
#content .inner-catalog .catalog-text .warning {
  color: red;
}
#content .inner-catalog .catalog-text a {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  color: #525252;
  font-weight: 600;
  text-decoration: underline;
}
#content .inner-catalog .catalog-text a:hover {
  color: #BDBDBD;
}
#content .inner-catalog .catalog-text .important-info {
  display: inline-block;
  margin-top: 10px;
  width: 100%;
}
#content .inner-catalog .catalog-text p {
  margin-bottom: 15px;
  padding-left:10px;
}
#content .inner-catalog .info-box {
  margin-top: 0;
  position: relative;
}
#content .inner-catalog .info-box h3 {
  font-family: 'Open Sans', Arial, sans-serif;
  margin-bottom: 5px;
  margin-top: 0;
  font-size: 16px;
  font-weight: 500;
}
#content .inner-catalog .info-box .important-info-close {
  position: absolute;
  right: 0;
  top: 0;
  color: #000;
}
#content .inner-catalog .info-box .important-info-close i.fa-toggle-on {
  display: none;
}
#content .inner-catalog .info-box .important-info-close i.fa-toggle-off {
  display: block;
}
#content .inner-catalog .info-box .important-info-close.active i.fa-toggle-on {
  display: block;
}
#content .inner-catalog .info-box .important-info-close.active i.fa-toggle-off {
  display: none;
}
#content .inner-catalog .info-box .info-box-content {
  max-height: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  overflow: hidden;
}
#content .inner-catalog .info-box .info-box-content.open {
  max-height: 500px;
}
#content .pecas-highlights {
  background: #E8E8E8;
  border-top: 1px solid #DADADA;
  padding:30px 0 72px 0;
}
#content .pecas-highlights h3 {
  font-size: 20px;
  font-family: 'Open Sans', Arial, sans-serif;
  color: #8E8E8E;
  font-weight: 400;
  margin: 0 0 30px 0;
}
#content .pecas-highlights .pecas-slider .swiper-container .swiper-button-next {
  right: 0;
}
#content .pecas-highlights .pecas-slider .swiper-container i {
  font-size: 55px;
  line-height: 200px;
  width:100%;
  text-align: center;
}
.is-peca #content .pecas-highlights .pecas-slider .swiper-container i {
  line-height:235px;
}
/*#content .pecas-highlights .pecas-slider .swiper-container .swiper-wrapper {
  height: 210px;
}*/
#content .pecas-highlights .pecas-slider .swiper-container .swiper-wrapper .swiper-slide {
  height: 200px;
  background-color: #E8E8E8;
}
#content .pecas-highlights .pecas-slider .swiper-container .swiper-wrapper .swiper-slide .image-container {
  height: 200px;
  border: 1px solid #b7b7b7;
  position: relative;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  background:#fff;
}
#content .pecas-highlights .pecas-slider .swiper-container .swiper-wrapper .swiper-slide .image-container:hover {
  border: 1px solid #525252;
  -webkit-box-shadow: 8px 8px 16px -8px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 8px 8px 16px -8px rgba(0, 0, 0, 0.5);
  box-shadow: 8px 8px 16px -8px rgba(0, 0, 0, 0.5);
}
#content .pecas-highlights .pecas-slider .swiper-container .swiper-wrapper .swiper-slide .image-container a {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  border:none !important;
}
#content .pecas-highlights .pecas-slider .swiper-container .swiper-wrapper .swiper-slide .image-container a img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
#content .pecas-highlights .pecas-slider .swiper-container .swiper-wrapper .swiper-slide .image-container-slider {
  height: 200px;
  border: 1px solid #b7b7b7;
  position: relative;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  background:#fff;
}
#content .pecas-highlights .pecas-slider .swiper-container .swiper-wrapper .swiper-slide .image-container-slider:hover {
  border: 1px solid #525252;
  -webkit-box-shadow: 8px 8px 16px -8px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 8px 8px 16px -8px rgba(0, 0, 0, 0.5);
  box-shadow: 8px 8px 16px -8px rgba(0, 0, 0, 0.5);
}
#content .pecas-highlights .pecas-slider .swiper-container .swiper-wrapper .swiper-slide .image-container-slider a {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
#content .pecas-highlights .pecas-slider .swiper-container .swiper-wrapper .swiper-slide .image-container-slider a img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
#content .inner-content-container {
  margin-bottom: 50px;
}
.is-peca #content .inner-content-container { margin-bottom:0; }
.is-catalogo #content .inner-content-container { margin-bottom:0; }
#content .inner-content-container form {
  margin: 0;
}
#content .inner-content-container .termos h4 {
  margin: 0 0 5px 0;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  color: #8E8E8E;
  font-family: 'Open Sans', Arial, sans-serif;
}
#content .inner-content-container .seu-cadastro,
#content .inner-content-container .seu-endereco {
  margin-bottom: 0;
}

#content .inner-content-container .seu-cadastro label,
#content .inner-content-container .seu-endereco label,
#content .inner-content-container .seu-cadastro span,
#content .inner-content-container .seu-endereco span {
  font-size: 13px;
  margin-bottom: 0;
  font-weight: 300;
  color: #8E8E8E;
  cursor: inherit;
  line-height: 1;
}
#content .inner-content-container .seu-cadastro span,
#content .inner-content-container .seu-endereco span {
  font-size: 12px;
  color: #525252;
  display: block;
}
#content .inner-content-container .seu-cadastro input,
#content .inner-content-container .seu-endereco input,
#content .inner-content-container .seu-cadastro select,
#content .inner-content-container .seu-endereco select {
  margin-bottom: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 5px;
  border: 1px solid #ddd;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  font-size: 13px;
  color: #8E8E8E;
}
#content .inner-content-container .seu-cadastro input:hover,
#content .inner-content-container .seu-endereco input:hover,
#content .inner-content-container .seu-cadastro select:hover,
#content .inner-content-container .seu-endereco select:hover,
#content .inner-content-container .seu-cadastro input:focus,
#content .inner-content-container .seu-endereco input:focus,
#content .inner-content-container .seu-cadastro select:focus,
#content .inner-content-container .seu-endereco select:focus {
  border: 1px solid #A7A7A7;
  outline: 0;
}
#content .inner-content-container .seu-cadastro h4,
#content .inner-content-container .seu-endereco h4 {
  margin: 0 0 15px 0;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  color: #8E8E8E;
  font-family: 'Open Sans', Arial, sans-serif;
}
#content .inner-content-container .seu-cadastro li,
#content .inner-content-container .seu-endereco li {
  list-style-type: none;
}
#content .inner-content-container .seu-cadastro .input-label-container,
#content .inner-content-container .seu-endereco .input-label-container {
  margin-bottom: 10px;
  overflow: hidden;
}
#content .inner-content-container .seu-cadastro .input-label-container .label-container,
#content .inner-content-container .seu-endereco .input-label-container .label-container {
  padding-left: 0;
}
#content .inner-content-container .seu-cadastro .input-label-container .input-container,
#content .inner-content-container .seu-endereco .input-label-container .input-container {
  padding-right: 0;
}
#content .inner-content-container .seu-cadastro .input-label-container .input-container .ddd,
#content .inner-content-container .seu-endereco .input-label-container .input-container .ddd {
  width: 25%;
  float: left;
  margin-right: 5%;
}
.telwrap { float:left; width:100%; padding-left:70px; position:relative;}
.dddwrap { position:absolute; top:0; left:0; width:60px; }
#content .inner-content-container .seu-cadastro .input-label-container .input-container .fone,
#content .inner-content-container .seu-endereco .input-label-container .input-container .fone {
  width: 70%;
  float: left;
}
#content .inner-content-container .seu-cadastro .pessoa-tipo,
#content .inner-content-container .seu-endereco .pessoa-tipo {
  margin-bottom: 10px;
  overflow: hidden;
}
#content .inner-content-container .seu-cadastro .pessoa-tipo label,
#content .inner-content-container .seu-endereco .pessoa-tipo label {
  float: left;
  padding: 0 28px 0 5px;
}
#content .inner-content-container .seu-cadastro .pessoa-tipo label:last-child,
#content .inner-content-container .seu-endereco .pessoa-tipo label:last-child {
  padding-right: 0;
}
#content .inner-content-container .seu-cadastro .pessoa-tipo input,
#content .inner-content-container .seu-endereco .pessoa-tipo input {
  float: left;
  margin-top: 1px;
}
#content .inner-content-container .seu-cadastro .pessoa-tipo a,
#content .inner-content-container .seu-endereco .pessoa-tipo a {
  color: #525252;
  font-weight: 600;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .inner-content-container .seu-cadastro .pessoa-tipo a:hover,
#content .inner-content-container .seu-endereco .pessoa-tipo a:hover {
  color: #BDBDBD;
}
#content .inner-content-container .seu-cadastro button,
#content .inner-content-container .seu-endereco button {
  border: 0;
  background-color: #525252;
  padding: 6px 20px;
  margin-top: 5px;
  color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .inner-content-container .seu-cadastro button:hover,
#content .inner-content-container .seu-endereco button:hover {
  background-color: #BDBDBD;
}
#content .inner-content-container .conditions {
  margin-bottom: 0;
}
#content .inner-content-container .conditions .pessoa-tipo {
  margin-bottom: 10px;
  overflow: hidden;
  position:relative;
  width:305px; height:20px;
}
#content .inner-content-container .conditions .pessoa-tipo label {
  float: left;
  padding: 0 28px 0 5px;
  font-size: 13px;
  margin-bottom: 0;
  font-weight: 300;
  color: #8E8E8E;
  cursor: inherit;
  line-height: 1;
}
.pessoa-tipo .validationIcon { top:0; right:0; }
.date-wrap { display:inline-block; position:relative; }
.date-wrap input { padding-right:30px; }
#content .inner-content-container .conditions .pessoa-tipo label:last-child {
  padding-right: 0;
}
#content .inner-content-container .conditions .pessoa-tipo input {
  float: left;
  margin-top: 2px;
}
#content .inner-content-container .conditions .pessoa-tipo a {
  color: #525252;
  font-weight: 600;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .inner-content-container .conditions .pessoa-tipo a:hover {
  color: #BDBDBD;
}
#content .inner-content-container .conditions li {
  list-style-type: none;
}
#content .inner-content-container .conditions button {
  border: 0;
  background-color: #525252;
  padding: 6px 20px;
  margin-top: 5px;
  color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .inner-content-container .conditions button:hover {
  background-color: #BDBDBD;
}
#content .inner-content-container .seu-cadastro li:nth-child(3) .label-container label,
#content .inner-content-container .seu-cadastro li:nth-child(6) .label-container label {
  padding-top: 6px;
}
#content .inner-content-container .seu-cadastro li:nth-child(8) {
  height: 32px;
}
#content .inner-content-container .seu-cadastro li:nth-child(10) {
  margin-bottom: 20px;
}
#content .inner-content-container .seu-cadastro li:nth-child(10) .label-container label {
  padding-top: 6px;
}
#content .inner-content-container .seu-cadastro li:nth-child(11) input,
#content .inner-content-container .seu-cadastro li:nth-child(12) input {
  margin-top: 2px;
}
#content .inner-content-container .seu-endereco li:first-child h4 {
  margin-bottom: 39px;
}
#content .inner-content-container .seu-endereco li:nth-child(10) .label-container label {
  padding-top: 6px;
}
#content .inner-content-container .catalog-box .avaliable-button {
  display: block;
  width: 100%;
  height: 32px;
  background-color: #000;
  margin-bottom: 5px;
  padding: 5px;
  text-align: center;
  color: white;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  margin-bottom: 15px;
}
#content .inner-content-container .catalog-box .avaliable-button.veja-aovivo-home { background:#ff0000;}
#content .inner-content-container .catalog-box .avaliable-button:hover {
  background-color: #000;
}
#content .inner-content-container .catalog-box .catalog-auction {
  padding: 7px;
  border: 1px solid #F1F1F1;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  height: 100%;
  width: 100%;
}
#content .inner-content-container .catalog-box .catalog-auction:hover {
  border: 1px solid #525252;
}
#content .inner-content-container .catalog-box .catalog-auction:hover .auction-title,
#content .inner-content-container .catalog-box .catalog-auction:hover .auction-info {
  color: #525252;
}
#content .inner-content-container .catalog-box .catalog-auction p {
  margin: 0;
}
#content .inner-content-container .catalog-box .catalog-auction .auction-title {
  font-size: 11px;
  padding-bottom: 5px;
  text-transform: uppercase;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.lista-catalogo #content .inner-content-container .catalog-box .catalog-auction .auction-title {
  height:37px; overflow:hidden;
 }
 .lista-catalogo #content .inner-content-container .catalog-box .catalog-auction .auction-title:last-child {
   float:left !important;
  }
#content .inner-content-container .catalog-box .catalog-auction .auction-info {
  font-size: 10px;
  font-weight: 600;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .inner-content-container .catalog-box .image-container {
  height: 230px;
  position: relative;
  border:none;
}
#content .inner-content-container .catalog-box .image-container .info-hover {
  opacity: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
  height: 100%;
  width: 100%;
  color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  font-size: 14px;
  top:0;
  left:0;
  display:block;
  line-height:20px;
}
.info-hover-wrap { display:table; height:100%; width:100%;  }
.info-hover-wrap-inner { display:table-cell; height:100%; width:100%; vertical-align:middle; }
.leilao-box-wrap {
  margin-bottom:30px;
}
.leilao-box-wrap .cntblck { padding:0; margin-bottom:5px; }
.leilao-box-wrap .cntblck .section-title:after { top:-15px; }
.leilao-box-wrap .cntblck .section-title h2 { margin:5px 0; }
.leilao-box-wrap .cntblck .cat-title h2 { margin:0; padding:20px 0 0 0; font-family:'Open Sans', Arial, sans-serif; font-size:12px; font-weight:normal; }
.leilao-box-wrap .cntblck .title-numleilao { font-size:11px; letter-spacing: 2px;
  text-transform: uppercase; opacity: 0.7; }
.leilao-box-wrap .image-container { display:table; max-height:230px; width:100% !important; }
.leilao-box-wrap .image-container .capa-tab { 
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  margin: 0 auto;
  background: #FFF;
  text-align: center;
  overflow: hidden;
}
#content .inner-content-container  .leilao-box-wrap .catalog-box .image-container .capa-tab a { 
  display: block;
  width: 100%;
  line-height:226px;
  box-sizing:border-box;
  height:230px;
}
#content .inner-content-container  .leilao-box-wrap .catalog-box .image-container .capa-tab a img {
  display:inline;
} 
.leilao-box-wrap .leilao-nav span { display:inline-block; }
.leilao-box-wrap .leilao-nav span a { margin-right:20px; display:inline-block; }
.leilao-box-wrap .leilao-nav span a:last-child { border:none; margin:0; padding:0;  }
#content .inner-content-container .leilao-box-wrap .leilao-nav a { float:left; height:22px; color:#757575; }
#content .inner-content-container .leilao-box-wrap .leilao-nav a.novo-miniaovivo { background:transparent; border:none; color:red; position:relative; padding-right:25px; font-weight:bold; text-align:center;  }
#content .inner-content-container .leilao-box-wrap .leilao-nav a.novo-miniaovivo i { position:absolute; top:0; right:0; }
#content .inner-content-container .leilao-box-wrap .leilao-nav i { font-size:22px; margin:0; }
.leilao-box-wrap .catalog-box { width:100%; float:left; background-color:#f1f1f1; padding:15px 0; }
.leilao-box-wrap .catalog-box .section-title { margin:0; }
.leilao-box-wrap .catalog-box .section-title:after { border-top:1px solid #dadada; top:-10px; z-index:0; }
.leilao-box-wrap .catalog-box .section-title h2 { margin:0; }
.leilao-box-wrap .catalog-box .section-title h2 span { background:#f1f1f1; font-size:11px; position:relative; z-index:1; }
#content .inner-content-container .finalizado-box-wrap .image-container { height:auto; }
.finalizado-box-wrap .cntblck .cat-title h2 { text-transform:uppercase; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; }
.finalizado-box-wrap .cntblck { margin:0; }
#content .inner-content-container .catalog-box .image-container a:hover .info-hover {
  opacity: 1;
}
#content .inner-content-container .catalog-box .image-container a {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

#content .inner-content-container .cadastro {
  padding: 15px;
}
#content .inner-content-container .cadastro h2 {
  margin: 0 0 15px 0;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Open Sans', Arial, sans-serif;
}
#content .inner-content-container .cadastro input {
  margin-bottom: 10px;
  border-radius: 0;
  box-shadow: none;
  padding: 5px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  font-size: 13px;
  color: #8E8E8E;
}
#content .inner-content-container .cadastro label {
  font-size: 13px;
  margin-bottom: 5px;
  font-weight: 300;
}
#content .inner-content-container .cadastro button {
  border: 0;
  padding: 10px 20px;
  margin-top: 5px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .inner-content-container .cadastro-type-1 {
  border: 1px solid #F1F1F1;
  background-color: inherit;
  overflow: hidden;
}
.cadastro-type-1 .row { margin:0 0 10px 0 !important; }
.cadastro-type-2 .row { margin:0 0 10px 0 !important; }
#content .inner-content-container .cadastro-type-1 label,
#content .inner-content-container .cadastro-type-1 h2 {
  color: #8E8E8E;
}
#content .inner-content-container .cadastro-type-1 input {
  border: 1px solid #ddd;
  margin-bottom:0;
}
#content .inner-content-container .cadastro-type-1 input:hover,
#content .inner-content-container .cadastro-type-1 input:focus {
  border: 1px solid #A7A7A7;
  outline: 0;
}
#content .inner-content-container .cadastro-type-1 button {
  background: #8E8E8E;
  color: #fff;
}
#content .inner-content-container .cadastro-type-1 button:hover {
  background: #696969;
}
#content .inner-content-container .cadastro-type-1 .esqueci-senha {
  padding-top: 12px;
  padding-left: 15px;
}
#content .inner-content-container .cadastro-type-1 .esqueci-senha a {
  color: #0032c2;
  font-size: 13px;
}
#content .inner-content-container .cadastro-type-1 .esqueci-senha,
#content .inner-content-container .cadastro-type-1 button {
  float: left;
}
#content .inner-content-container .cadastro-type-2 {
  background-color: #8E8E8E;
  overflow: hidden;
}
#content .inner-content-container .cadastro-type-2 label,
#content .inner-content-container .cadastro-type-2 h2 {
  color: #fff;
}
#content .inner-content-container .cadastro-type-2 input {
  border: 1px solid #656565;
  margin-bottom:0;
}
#content .inner-content-container .cadastro-type-2 input:hover,
#content .inner-content-container .cadastro-type-2 input:focus {
  border: 1px solid #000;
  outline: 0;
}
#content .inner-content-container .cadastro-type-2 button {
  background: #fff;
  color: #8E8E8E;
}
#content .inner-content-container .cadastro-type-2 button:hover {
  background: #525252;
}
#content .inner-content-container .captcha label {
  font-size: 13px;
  padding-top: 10px;
  margin-bottom: 0;
  font-weight: 300;
  color: #8E8E8E;
  padding-bottom: 10px;
}
#content .inner-content-container .captcha .realperson-challenge {
  width: 45%;
  float: right;
}
#content .inner-content-container .captcha .realperson-text {
  background: #EFEFEF;
  text-align: center;
}
#content .inner-content-container .captcha input {
  width: 50%;
  float: left;
  margin-right: 4%;
  margin-bottom: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 5px;
  border: 1px solid #ddd;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  font-size: 13px;
  color: #8E8E8E;
}
#content .inner-content-container .captcha input:hover,
#content .inner-content-container .captcha input:focus {
  border: 1px solid #A7A7A7;
  outline: 0;
}
#content .inner-content-container .captcha button {
  margin-top: 15px;
  border: 0;
  background: #8E8E8E;
  padding: 10px 20px;
  color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .inner-content-container button {
  /* margin-top: 15px; */
  border: 0;
  background: #8E8E8E;
  /* padding: 10px 20px;*/
  color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .inner-content-container .captcha button:hover {
  background: #696969;
}
#content .inner-content-container .contact-mail {
  margin-bottom: 10px;
  float:left; width:100%;
}
#content .inner-content-container .contact-mail:first-child {
  margin-top: 15px;
}
#content .inner-content-container .contact-mail:last-child {
  margin-bottom: 0;
}
#content .inner-content-container .contact-mail:nth-child(3) .label-text label {
  padding-top: 6px!important;
}
#content .inner-content-container .contact-mail h4 {
  margin: 0 0 15px 0;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  color: #8E8E8E;
  font-family: 'Open Sans', Arial, sans-serif;
}
#content .inner-content-container .contact-mail .label-text {
  padding-left: 0;
}
#content .inner-content-container .contact-mail .label-text label {
  font-size: 13px;
  padding-top: 10px;
  margin-bottom: 0;
  font-weight: 300;
  color: #6b6b6b;
}
#content .inner-content-container .contact-mail .input-size {
  padding-right: 0;
}
#content .inner-content-container .contact-mail .input-size .ddd {
  width: 20%;
  margin-right: 5%;
  float: left;
}
#content .inner-content-container .contact-mail .input-size .telefone {
  width: 75%;
  float: left;
}
#content .inner-content-container .contact-mail .input-size input,
#content .inner-content-container .contact-mail .input-size select,
#content .inner-content-container .contact-mail .input-size textarea {
  margin-bottom: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 5px;
  border: 1px solid #ddd;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  font-size: 13px;
  color: #6b6b6b;
}
#content .inner-content-container .contact-mail .input-size input:hover,
#content .inner-content-container .contact-mail .input-size select:hover,
#content .inner-content-container .contact-mail .input-size textarea:hover,
#content .inner-content-container .contact-mail .input-size input:focus,
#content .inner-content-container .contact-mail .input-size select:focus,
#content .inner-content-container .contact-mail .input-size textarea:focus {
  border: 1px solid #A7A7A7;
  outline: 0;
}
#content .inner-content-container .contact-mail .input-size select {
  height: 32px;
}
#content .inner-content-container .contact-mail .input-size textarea {
  height: 100px;
  max-width: 315px;
  max-height: 100px;
}
#content .inner-content-container .contact-mail button {
  margin-top: 15px;
  padding: 10px 20px;
}
#content .inner-content-container .contact-phone {
  margin-top: 15px;
}
#content .inner-content-container .contact-phone h4 {
  margin: 0;
  margin: 0 0 15px 0;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  color: #8E8E8E;
  font-family: 'Open Sans', Arial, sans-serif;
}
#content .inner-content-container .contact-phone table {
  width: 100%;
  border: 0;
  background: #EFEFEF;
  text-transform: uppercase;
}
#content .inner-content-container .contact-phone table thead,
#content .inner-content-container .contact-phone table tbody {
  background: transparent;
}
#content .inner-content-container .contact-phone table thead .left-col,
#content .inner-content-container .contact-phone table tbody .left-col {
  width: 60%;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
#content .inner-content-container .contact-phone table thead .right-col,
#content .inner-content-container .contact-phone table tbody .right-col {
  width: 40%;
  border-bottom: 1px solid #fff;
  text-align: center;
}
#content .inner-content-container .contact-phone table thead th,
#content .inner-content-container .contact-phone table tbody th {
  color: #737373;
}
#content .inner-content-container .contact-phone table thead td,
#content .inner-content-container .contact-phone table tbody td {
  font-size: 13px;
  color: #A8A8A8;
  vertical-align: middle;
}
#content .inner-content-container p {
  margin-bottom: 15px;
  font-size: 13px;
  color: #6b6b6b;
}
#content .inner-content-container p:last-child {
  margin-bottom: 0;
}
#content .inner-content-container .inner-padded-column {
  padding-left: 65px;
}
#content .inner-content-container .inner-padded-column form {
  margin: 0;
}
#content .inner-content-container .inner-padded-column .warning i {
  font-size: 15px;
  color: #a73a39;
}
#content .inner-content-container .inner-padded-column .warning {
  position: absolute;
  left: -50px;
}
#content .inner-content-container .inner-padded-column .numberbg {
  border: 1px solid #ddd;
  border-radius: 0;
  position: absolute;
  left: -65px;
  width: 40px;
  height: 40px;
  line-height: 37px;
  padding-left: 14px;
  font-size: 20px!important;
  font-weight: 600;
  color: #8E8E8E;
}
#content .inner-content-container .inner-padded-column textarea {
  height: 150px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  padding: 10px;
  font-size: 13px;
  color: #8E8E8E;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  border-radius: 0;
  margin-top: 15px;
  box-shadow: none;
}
#content .inner-content-container .inner-padded-column textarea:focus {
  outline: 0;
  background: #fff;
  border: 1px solid #A7A7A7;
}
#content .inner-content-container .inner-padded-column textarea:hover {
  border: 1px solid #A7A7A7;
}
#content .inner-content-container .inner-padded-column ul {
  line-height: normal;
  margin: 0;
}
#content .inner-content-container .inner-padded-column ul li {
  list-style-type: none;
  padding-bottom: 30px;
}
#content .inner-content-container .inner-padded-column ul li:first-child h3 {
  color: #a73a39;
  line-height: 22px;
}
#content .inner-content-container .inner-padded-column ul li:last-child {
  padding-bottom: 0;
}
#content .inner-content-container .inner-padded-column ul li h3 {
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 15px;
  color: #8E8E8E;
  margin: 0;
  font-weight: 600;
}
#content .inner-content-container .inner-padded-column ul li button {
  padding: 8px 15px;
  color: #fff;
  background-color: #515151;
  font-size: 13px;
  border: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  margin-top: 15px;
}
#content .inner-content-container .inner-padded-column ul li .fake-env-button {
  padding: 8px 15px;
  color: #fff;
  background-color: #515151;
  font-size: 13px;
  border: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  margin-top: 15px;
  float:left;
  width:100%;
  line-height:32px;
  text-align:center;
}
#content .inner-content-container .inner-padded-column ul li button:hover {
  background-color: #BDBDBD;
}
#content .inner-content-container .inner-padded-column ul li .inner-upload-box h3 {
  margin-bottom: 15px;
}
#content .inner-content-container .inner-padded-column ul li .inner-upload-box a {
color:#525252;
cursor:pointer;
width:100%; position:absolute; bottom:0; left:0; height:22px;
}
.upload-box-wrap { position:relative; }
.faketext { margin:0 0 0 10px; }
#uploadcomprovante li { position:relative; }
.newaval { display:none; color: #2564c2; font-weight: 600; margin-top:20px; }
.nova-aval { color:#2564c2; font-weight:bold; }
.nova-aval i { color:#2564c2 !important; margin:0 10px 0 0; }
#enviar-aval { float:none !important; }
#uploadcomprovante .loading-box { display:none; }
/* ==========================================================================
   PEÃ‡A
   ========================================================================== */
.ordernar-box { float:right !important; padding-left:0; }
.ordernar-box select { font-size:12px; float:right; width:auto; margin-left:5px; }
.catalog-content .is-catalog-content-tools { margin-bottom:30px; }
.is-catalog-content-tools input { font-size:12px !important; }
.is-catalog-content-tools label { margin-bottom:5px !important; line-height:32px; text-transform:uppercase; color:#737373; letter-spacing:2px; font-size:11px; }
.is-catalog-content-tools #irpara { margin-bottom:0 !important; padding-right:30px; width:120px; }
.is-divider { float:left; width:100%; border-bottom:1px solid #f1f1f1; margin-top:20px; }
#content .inner-content-container .input-spacement .Botao-novo { width:32px; height:32px; transition:all 0.5s ease; padding:0; position:absolute; top:0; right:6px; background:transparent; color:#8E8E8E; }
#content .inner-content-container .input-spacement:hover .Botao-novo i, #content .inner-content-container .input-spacement:focus .Botao-novo i { color:#737373;  transition:all 0.5s ease; }
#content .inner-content-container .input-spacement .Botao-novo i {font-size:18px; }
.view-options-search { padding-right:0; }

#content .terms-info-mobile {
  display: none;
  margin-bottom: 20px;
}
#content .terms-info-mobile .terms-btns {
  float: left;
  width: 100%;
}
#content .terms-info-mobile .terms-btns .active {
  background-color: #BDBDBD;
}
#content .terms-info-mobile .terms-btns a {
  width: 31.3%;
  margin-right: 3%;
  background-color: #525252;
  color: #fff;
  padding: 10px 0;
  display: block;
  float: left;
  text-align: center;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .terms-info-mobile .terms-btns a:last-child {
  margin-right: 0;
}
#content .terms-info-mobile .terms-btns a:hover {
  background-color: #BDBDBD;
}
#content .terms-info-mobile .wrapper-drop {
  float: left;
  margin-top: 20px;
}
#content .terms-info-mobile .wrapper-drop h5 {
  color: #8E8E8E;
  font-size: 13px;
  font-weight: 600;
  margin-top: 0;
  font-family: 'Open Sans', Arial, sans-serif;
}
#content .terms-info-mobile .wrapper-drop p {
  font-size: 13px;
  color: #8E8E8E;
}
#content .terms-info-mobile .wrapper-drop .terms-drop,
#content .terms-info-mobile .wrapper-drop .conditions-drop,
#content .terms-info-mobile .wrapper-drop .shipping-drop {
  max-height: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  overflow: hidden;
}
#content .terms-info-mobile .wrapper-drop .open {
  max-height: 1500px;
}

#content .pecas-highlights .lotes-slider .swiper-container .swiper-wrapper .swiper-slide {
  height: 235px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .pecas-highlights .lotes-slider .swiper-container .swiper-wrapper .swiper-slide:hover {
  -webkit-box-shadow: 8px 8px 16px -8px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 8px 8px 16px -8px rgba(0, 0, 0, 0.5);
  box-shadow: 8px 8px 16px -8px rgba(0, 0, 0, 0.5);
}
#content .pecas-highlights .lotes-slider .swiper-container .swiper-wrapper .swiper-slide:hover .image-container {
  border: 1px solid #525252;
}
#content .pecas-highlights .lotes-slider .swiper-container .swiper-wrapper .swiper-slide:hover .slide-lot {
  border: 1px solid #525252;
  border-bottom: 0;
  background-color: #525252;
}
#content .pecas-highlights .lotes-slider .swiper-container .swiper-wrapper .swiper-slide .image-container:hover {
  box-shadow: none;
}
#content .pecas-highlights .lotes-slider .swiper-container .swiper-wrapper .swiper-slide .slide-lot {
  background-color: #737373;
  border: 1px solid #737373;
  border-bottom: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .pecas-highlights .lotes-slider .swiper-container .swiper-wrapper .swiper-slide .slide-lot h5 {
  color: #8E8E8E;
  font-size: 13px;
  font-weight: 600;
  margin-top: 0;
  font-family: 'Open Sans', Arial, sans-serif;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
  padding: 10px;
  color: #fff;
}
#content .inner-catalog .right-side .mobile-slider {
  display: none;
}
#content .inner-catalog .social-btns-mobile {
  display: none;
}
.left-side { }
.left-side .section-title { padding-left:0; }
.image-row { position:relative;  }
.loading-box {  width:100%; height:100%; min-height:200px; position:absolute; top:0; left:0; padding:30px; }
.loading-box h2 { text-align:center; }
.vender-load { min-height:100px; }
#response-modal .loading-box { position:relative; float:left; padding:0; }

#content .inner-catalog .left-side .main-image {
  transition:all 0.2s ease;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-;
  opacity:0;
}
#content .inner-catalog .left-side .main-image.loaded {
  opacity:1;
  -webkit-box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.15);
}

#content .inner-catalog .left-side .main-image .image-container {
  height: 520px;
  position: relative;
  background-color:#fff;
  border:1px solid #ddd;
}
#content .inner-catalog .left-side .main-image .image-container .img-wrap {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
#content .inner-catalog .left-side .main-image .image-container .img-wrap img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}
#content .inner-catalog .social-btns {
  margin-bottom: 15px;
}
#content .inner-catalog .social-btns .facebook {
  background-color: #4b66a0!important;
  width: 27%;
}
#content .inner-catalog .social-btns .facebook:hover {
  background-color: #557BCA !important;
}
#content .inner-catalog .social-btns .twitter {
  background-color: #55acee!important;
  width: 19%;
}
#content .inner-catalog .social-btns .twitter:hover {
  background-color: #3E7EAF !important;
}
#content .inner-catalog .social-btns .googleplus {
  background-color: #d95232!important;
  width: 20%;
}
#content .inner-catalog .social-btns .googleplus:hover {
  background-color: #903621 !important;
}
#content .inner-catalog .social-btns .back {
  width: 28%;
}
#content .inner-catalog .social-btns a {
  font-size: 12px;
  color: #fff;
  padding: 10px 0;
  display: block;
  float: left;
  margin-right: 2%;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-;
  text-align: center;
}
#content .inner-catalog .social-btns a:hover {
  background-color: #BDBDBD;
  color: #fff;
}
#content .inner-catalog .social-btns a i {
  padding-right: 5px;
}
#content .inner-catalog .social-btns a:last-child {
  margin-right: 0;
}

.new-social-btns { font-size:24px; text-align:left; display:table-cell; float:none; }
.new-social-btns a {margin:0 10px; color:#737373; font-size:24px; transition:all 0.5s ease; display:inline-block; }
.new-social-btns a.facebook:hover { color:#4267b2; }
.new-social-btns a.twitter:hover { color:#1da1f2; }
.new-social-btns a.enviaamigo:hover, .new-social-btns a.entracontato:hover { color:#d2b03f; }
#vergrande { display:inline-block; width:auto; font-size:14px; position:relative; padding:0px 25px 0px 38px; border-right:1px solid #ddd; line-height:16px; margin:0 10px 0 0; top:-3px; }
#vergrande i { position:absolute; top:-8px; left:0; font-size:26px; line-height:31px;  }
.ver-grande { text-align:center; font-size:16px; display:table-cell; vertical-align:middle; float:none; }
.ver-grande a { line-height:24px; font-size:14px; color:#737373; line-height:15px; padding-left:40px; position:relative; text-align:left; display:inline-block; }
.ver-grande i { font-size:30px; position:absolute; top:0; left:0; }
.row.peca-tools { margin:15px 0 0 0; display:table; width:100%; }
.total { width:100%; position:relative; text-align:center;  }
.total i { font-size:20px;  }
.total a { color:#737373; }
.total a.back { float:left; margin-right:20px; padding:5px 20px 5px 0; border-right:1px solid #ddd; }
.total a.anterior { float:left; padding:5px 0; margin-right:15px; }
.total a.proximo { float:right; padding:5px 0; }
.total a.pickLote i { line-height:17px; font-size:14px; }
.total .faded i { color:#e4e4e4; cursor:default; }
.pickLote { display:inline-block; line-height:10px; width:200px; position:relative; font-size:20px; z-index:900; background-color:#fff; padding:5px 0 5px 10px;border-left:1px solid #fff; border-top:1px solid #fff; border-right:1px solid #fff; color:#737373; }
.pickLote:hover, .pickLote:focus { color:#737373; }
.picklote.opened { border-color:#ddd;  }
.picklote .fa-chevron-down, .picklote.opened .fa-chevron-up { display:inline-block; }
.picklote .fa-chevron-up, .picklote.opened .fa-chevron-down { display:none; }
.listaLote { position:absolute; z-index:100; top:0; left:50%; width:200px; margin-left:-100px; height:28px; overflow:hidden; }
.listaLote.opened { height:300px; padding-top:30px; z-index:100; background-color:#fff; border:1px solid #ddd; -webkit-box-shadow: 0px 1px 3px -1px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 1px 3px -1px rgba(0,0,0,0.5);
  box-shadow: 0px 1px 3px -1px rgba(0,0,0,0.5); }
.listaLote ul { overflow-y:scroll; width:100%; height:100%; }
.listaLote ul li { font-size:16px; cursor:pointer; visibility:visible; height:auto; }
.listaLote ul li a { color:#737373; padding:4px 10px; display: block;}
.listaLote ul li.hidden { visibility:hidden; height:0; padding:0; }
.listaLote ul li:hover { color:#fff; background-color:#2564c2; }
.listaLote ul li:hover a { color:#fff; }
.listaLote ul li.selected { font-weight:bold; font-style: italic; cursor:default;  }
.listaLote input { margin-bottom:0; border-color:#f1f1f1; width:100%; display:block; padding-right:40px; }
.gotoLote-filter { position:absolute; top:0; left:0; width:100%; }
.gotoLote-filter i { position:absolute; top:8px; right:10px; color:#bdbdbd; }
.listaLote-wrap { position:relative; width:100%; height:100%; padding-top:32px; }
.listaLote .loading-wrapper .loading-box { height:100%; width:100%; top:0; left:0; min-height:100%; margin-top:0; margin-left:0; box-sizing:border-box; }
.listaLote .loading-position { opacity:1; transition:visibility 0s linear 0.5s,opacity 0.5s linear; }
.listaLote.loaded .loading-position { opacity:0; visibility:hidden;  }
.total-arrows .select2 { position:absolute; top:0; left:50%; width:200px; margin-left:-100px; height:28px; }
.total-arrows .select2 .selection, .total-arrows .select2 .select2-selection, .total-arrows .select2 .select2-selection__rendered { height:19px; border:none; }
.total-arrows .selection:focus, .total-arrows .select2-selection__rendered:focus, .total-arrows .select2-selection:focus { border:none; outline:none; }
.img-modal-container { height:300px; max-height:300px; overflow:hidden; position:relative; 
  -webkit-transition: max-height 0.5s; 
  -moz-transition: max-height 0.5s; 
  -ms-transition: max-height 0.5s; 
  -o-transition: max-height 0.5s; 
  transition: max-height 0.5s;    }
.img-modal-container.loaded { height:auto; max-height:1000px; }
.loading-position { position:absolute; width:100%; height:100%; z-index:980; visibility: visible; opacity: 1; transition: visibility 0s, opacity 0.5s linear; top:0; left:0; }
.loading-wrapper { width:100%; height:100%; background:#fff; position:relative;  }
.loading-wrapper .loading-box {  position:Absolute; top:50%; margin-top:-100px; left:50%; margin-left:-100px; height:200px; width:200px; line-height:180px; text-align:center; display:block; }
.img-modal-container.loaded .loading-position { visibility:hidden; opacity: 0; transition-delay: 0.2s; }
.img-modal-container .modal-main-img { text-align:center; }
.modal-thumbs-img { width:100%; }
.modal-thumbs-img .thumb { width:16.66667%; max-width:90px; margin-right:10px; float:left; display:table; border:1px solid #ddd; opacity:0.3; transition:all 0.5s ease; }
.modal-thumbs-img .thumb.active { opacity:1; }
.modal-thumbs-img .thumb a { width:100%; height:60px; display:table-cell; vertical-align:middle; text-align:center; }
.modal-thumbs-img .thumb a img { display:inline-block; max-width:100%; max-height:100%; }
#img-modal .row { width:100%; margin-bottom:0; }
#img-modal .modal-thumbs-img { margin-bottom:10px; padding-right:110px; }
#img-modal .modal-arrow-nav { position:absolute; top:5px; right:0; width:80px; }
#img-modal .modal-arrow-nav a { height:40px; width:35px; text-align:center; display:inline-block; margin-left:10px; }
#img-modal .modal-arrow-nav a:first-child { margin-left:0; }
#img-modal .modal-arrow-nav a.disabled_arrow { cursor:not-allowed; color:#f1f1f1; }
#img-modal .modal-arrow-nav i { font-size:40px;}
.main-img-row { border:1px solid #ddd; }


.leilao-whatsapp { }
.new-social-btns .leilao-whatsapp { transition:all 0.5s ease; font-size:24px; margin-right:0; }
.new-social-btns .leilao-whatsapp:hover { color:#097700; }
.new-social-btns .leilao-whatsapp i { font-size:24px;  }
.zapmobile {  }

#content .inner-catalog .social-btns .back { border:1px solid #dedede; color:#737373; }
#content .inner-catalog .social-btns .back:hover { background: #dadada; /* Old browsers */
background: -moz-linear-gradient(top,  #dadada 0%, #ebebeb 19%, #f1f1f1 100%); /* FF3.6+ */
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#dadada), color-stop(19%,#ebebeb), color-stop(100%,#f1f1f1)); /* Chrome,Safari4+ */
background: -webkit-linear-gradient(top,  #dadada 0%,#ebebeb 19%,#f1f1f1 100%); /* Chrome10+,Safari5.1+ */
background: -o-linear-gradient(top,  #dadada 0%,#ebebeb 19%,#f1f1f1 100%); /* Opera 11.10+ */
background: -ms-linear-gradient(top,  #dadada 0%,#ebebeb 19%,#f1f1f1 100%); /* IE10+ */
background: linear-gradient(to bottom,  #dadada 0%,#ebebeb 19%,#f1f1f1 100%); /* W3C */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dadada', endColorstr='#f1f1f1',GradientType=0 ); /* IE6-9 */
  text-decoration:none; color:#737373; }
#content .inner-catalog .image-thumbs {
  padding: 0;
  margin-left:-1px;
}
#content .inner-catalog .image-thumbs .thumb-video {
  background-color: #fff;
  padding: 5px;
  height: 75px;
  position: relative;
  border: 1px solid #F1F1F1;
  margin-bottom: 10px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .inner-catalog .image-thumbs .thumb-video:hover {
  background-color: #525252;
  border-color:#525252;
}
#content .inner-catalog .image-thumbs .thumb-video h5 {
  font-size: 11px;
  font-weight: 700;
  margin-top: 0;
  font-family: 'Open Sans', Arial, sans-serif;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
  color: #737373;
}
#content .inner-catalog .image-thumbs .thumb-video p {
  text-align: center;
  font-size: 20px;
  margin-bottom: 0;
  color: #737373;
  margin-top: 0;
}
#content .inner-catalog .image-thumbs .thumb-video:hover p { color:#fff; }
#content .inner-catalog .image-thumbs .thumb-video:hover h5 { color:#fff; }
#content .inner-catalog .image-thumbs .thumb {
  height: 75px;
  position: relative;
  border: 1px solid #F1F1F1;
  margin-bottom: 5px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  opacity:0;
  padding:10px;
}
#content .inner-catalog .image-thumbs .thumb.loaded { opacity:1; } 
#content .inner-catalog .image-thumbs .thumb:hover {
}
#content .inner-catalog .image-thumbs .thumb:hover a {
  opacity: 1;
}
#content .inner-catalog .image-thumbs .thumb:last-child {
  margin: 0;
}
#content .inner-catalog .image-thumbs .thumb a {
  opacity: 0.3;
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  border:none;
  position:relative;
  background-color:#fff;
}
#content .inner-catalog .image-thumbs .thumb a img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}
#content .inner-catalog .image-thumbs .thumb.active {
  border:1px solid #f1f1f1;
  background-color:#f1f1f1;
}
#content .inner-catalog .image-thumbs .thumb.active a {
  opacity: 1;
}
#content .inner-catalog .right-side {
  padding-left: 0;
}
#content .inner-catalog .right-side .peca-info .lote-desc-type-mobile,
#content .inner-catalog .right-side .peca-info .ui-mobile {
  display: none;
}
#content .inner-catalog .right-side .peca-info .total-arrows {
  margin-bottom: 0;
  float: left;
  width: 100%;
  padding: 10px;
}
#content .inner-catalog .right-side .peca-info .lote-desc-type-mobile { padding:0; }
#content .inner-catalog .right-side .peca-info .nav-peca { position:relative; }
#content .inner-catalog .right-side .peca-info .total-arrows span {
  font-size: 13px;
  color: #737373;
  display: block;
  line-height: 135px;
}
#content .inner-catalog .right-side .peca-info .total-arrows .total {
  float: left;
}
#content .inner-catalog .right-side .peca-info .total-arrows .arrows {
  float: right;
}
#content .inner-catalog .right-side .peca-info .lote-desc-type-mobile .arrows {
  position:absolute; top:50%; right:15px; margin-top:-15px;
}
#content .inner-catalog .right-side .peca-info .total-arrows .arrows a {
  padding: 4px 9px;
  line-height: 20px;
  display: inline-block;
  background-color: #fff;
  margin-right: 10px;
  border: 1px solid #ddd;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-;
  color:#2564c2;
}
#content .inner-catalog .right-side .peca-info .total-arrows .arrows a:hover {
  background-color: #fff;
}
#content .inner-catalog .right-side .peca-info .total-arrows .arrows a:hover i {
  color: #A3A3A3;
}
#content .inner-catalog .right-side .peca-info .total-arrows .arrows a:last-child {
  margin-right: 0;
}
#content .inner-catalog .right-side .peca-info .total-arrows .arrows a i {
  color: #2564c2;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-;
}
#content .inner-catalog .right-side .peca-info .total-arrows .arrows a.faded  { border-color:#f1f1f1; }
#content .inner-catalog .right-side .peca-info .total-arrows .arrows a.faded i { color:#f1f1f1; }
#content .inner-catalog .right-side .peca-info .social-btns {
  margin-bottom: 15px;
}
#content .inner-catalog .right-side .peca-info .social-btns .facebook {
  background-color: #4b66a0;
}
#content .inner-catalog .right-side .peca-info .social-btns .facebook:hover {
  background-color: #557BCA;
}
#content .inner-catalog .right-side .peca-info .social-btns .twitter {
  background-color: #55acee;
}
#content .inner-catalog .right-side .peca-info .social-btns .twitter:hover {
  background-color: #3E7EAF;
}
#content .inner-catalog .right-side .peca-info .social-btns .googleplus {
  background-color: #d95232;
}
#content .inner-catalog .right-side .peca-info .social-btns .googleplus:hover {
  background-color: #903621;
}
#content .inner-catalog .right-side .peca-info .social-btns a {
  height: 32px;
  background-color: #525252;
  font-size: 11px;
  color: #fff;
  padding: 7px 7px;
  display: block;
  float: left;
  margin-right: 2%;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-;
}
#content .inner-catalog .right-side .peca-info .social-btns a:hover {
  background-color: #BDBDBD;
  color: #fff;
}
#content .inner-catalog .right-side .peca-info .social-btns a i {
  padding-right: 5px;
}
#content .inner-catalog .right-side .peca-info .social-btns a:last-child {
  margin-right: 0;
}
#content .inner-catalog .right-side .peca-info .lote-tipo {
  margin-bottom: 0;
  float: left;
  width: 100%;
  border: none;
  padding:0 0 0 10px;
}
.lote-desc.text-list { max-height:105px; overflow-y:auto; }
.is-breadcrumb ul { display:inline-block; }
.is-breadcrumb .is-breadlabel { font-weight:bold; color:#737373; margin-right:10px; }
.is-breadcrumb li { display:inline-block; text-transform:uppercase; font-weight:normal; font-size:11px; color:#757575; margin-right:5px;  }
.is-breadcrumb li a { margin-right:10px; color:#a9a6a6;  }
.is-breadcrumb li a:hover { color:#2564c2;  }
.is-breadcrumb li i { color:#a9a6a6;  }
.is-breadcrumb li:last-child { font-weight:bold; }

#content .inner-catalog .right-side .peca-info .lote-tipo .lote-number {
  float: left;
  width: 70%;
}
#content .inner-catalog .right-side .peca-info .lote-tipo .lote-number p {
  float: left; color:#737373;
}
#content .inner-catalog .right-side .peca-info .lote-tipo .lote-number p:first-child {
  margin-right: 15px;
}
#content .inner-catalog .right-side .peca-info .lote-tipo .lote-type {
  float: left;
  width:100%;
  margin-bottom:10px;
}
.lote-type b { color:#737373; }
#content .inner-catalog .right-side .peca-info .lote-tipo .lote-type a {
  color: #2564c2;
  font-size: 13px;
  font-weight: bold;
}
#content .inner-catalog .right-side .peca-info .lote-tipo .lote-desc {
  float: left;
  width:100%;
  padding-right:20px;
  word-break: break-word;
}
#content .inner-catalog .right-side .peca-info .user-ui {
  margin-bottom: 15px;
}
#content .inner-catalog .right-side .peca-info .user-ui a {
  width: 18%;
  display: block;
  float: left;
  margin-right: 7px;
  padding:5px 0;
}
#content .inner-catalog .right-side .peca-info .user-ui a:last-child {
  margin: 0;
}
#content .inner-catalog .right-side .peca-info .user-ui a:hover p {
  color: #000;
}
#content .inner-catalog .right-side .peca-info .user-ui p {
  margin-bottom: 0;
  text-align: center;
  line-height: 15px;
  font-size: 12px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  color:#737373;
}
#content .inner-catalog .right-side .peca-info .user-ui i {
  font-size: 15px;
  margin-bottom: 5px;
}
#content .inner-catalog .right-side .peca-info .ui-mobile i {
  font-size: 15px;
  margin:0;
}
#content .inner-catalog .right-side .peca-info .user-ui a.fav.ativo p { color:#000; }
#content .inner-catalog .right-side .peca-info .user-ui a.fav.ativo p i { color:#ECBD08; text-shadow:1px 1px 0 #FFFFFF, 2px 3px 0 rgba(0, 0, 0, 0.15); }
#content .inner-catalog .right-side .peca-info .user-ui a.watch.ativo p { color:#000; }
#content .inner-catalog .right-side .peca-info .user-ui a.watch.ativo p i { color:#2594DC; text-shadow:1px 1px 0 #FFFFFF, 2px 2px 0 rgba(0, 0, 0, 0.15); }

#content .inner-catalog .right-side .peca-info .preco-bid {
  margin-bottom: 15px;
}
#content .inner-catalog .right-side .peca-info .preco-bid form {
  padding:0 0 0 10px;
  border: none;
  float:left;
  width:100%;
}
#content .inner-catalog .right-side .peca-info .preco-bid ul {
  margin-left: 0;
  list-style: none;
  float: left;
  margin-bottom: 0;
  width: 100%;
}
#content .inner-catalog .right-side .peca-info .preco-bid ul li {
  width: 100%;
  float: left;
}
#content .inner-catalog .right-side .peca-info .preco-bid ul li ul li {
  position:relative;
}
#content .inner-catalog .right-side .peca-info .preco-bid ul li .bidnumber i, #content .inner-catalog .right-side .peca-info .preco-bid .automatic-bid i {
  color: #737373;
}
#content .inner-catalog .right-side .peca-info .preco-bid ul li label {
  float: left;
  font-size: 13px;
  color: #737373;
}
#content .inner-catalog .right-side .peca-info .preco-bid ul li p {
  float:left;
}
#content .inner-catalog .right-side .peca-info .preco-bid ul li p a {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  color: #2564c2;
}
.automatico-info { position:relative; padding:5px 50px 5px 10px; margin-bottom:0; background:#ddd; }
#content .inner-catalog .right-side .peca-info .preco-bid ul li.automatico-info label { font-size:11px !important; margin:0 0 5px 0 !important; width:100%; color:#737373; }
#content .inner-catalog .right-side .peca-info .preco-bid ul li.automatico-info p { line-height:30px; margin:0 !important; float:left !important; color:#737373; }
.automatico-info .is-rs { line-height:20px; }
.lanceuauto-tippy { color:#737373; position:absolute; top:0; right:0; width:50px; height:100%; text-align:center; display:table-cell; vertical-align:middle; }
.lanceuauto-tippy i { font-size:20px; line-height:56px; color:#737373; }
.automatico-info .is-removerauto { float:left; position:relative; }
.automatico-info .is-removerauto a:hover { background:#f7f7b1; border-color:#f7f7b1;  color:#000;  }
.automatico-info .is-removerauto a:hover i { color:#000;}
.lanceauto-wrap { float:left; }
.lanceauto-wrap .is-removerauto { height:24px; border:none; margin-left:10px; width:auto; }
.lanceauto-wrap .is-removerauto a { height:24px; border:none;  }
.lanceauto-wrap .is-removerauto a:hover { border:none;  }
.lanceauto-wrap .is-removerauto i { line-height:24px; }
.watchcs { border:1px solid #ddd; display:table; float:right; font-size:10px; text-align:center; padding:5px 0; height:32px; background:#fff; color:#737373; width:150px; transition:all 0.5s ease-in-out; }
.watchcs.ativo { border-color:#f1f1f1; background:#f1f1f1; color:#737373; }
.watchcs:hover { border-color:#f1f1f1; background:#f1f1f1; color:#737373; }
.watchcs i { font-size:20px; padding-left:5px; display:table-cell; vertical-align: middle; color:#737373; }
.watchcs.ativo i { color:#2594DC; text-shadow:1px 1px 0 #FFFFFF, 2px 3px 0 rgba(0, 0, 0, 0.15); }
.watchcs:hover i { color:#2594DC; text-shadow:1px 1px 0 #FFFFFF, 2px 3px 0 rgba(0, 0, 0, 0.15); }
.watchcs span { font-size:10px; display:table-cell; vertical-align: middle; text-align:center; margin:0 5px; color:#737373; }
.favcs { border:1px solid #ddd; display:table; float:right; font-size:10px; text-align:center; padding:5px 0; height:32px; background:#fff; color:#737373; width:150px; transition:all 0.5s ease-in-out; }
.favcs.ativo { border-color:#f1f1f1; background:#f1f1f1; color:#737373; }
.favcs:hover { border-color:#f1f1f1; background:#f1f1f1; color:#737373; }
.favcs i { font-size:20px; padding-left:5px; display:table-cell; vertical-align: middle; color:#737373; }
.favcs.ativo i { color:#ECBD08; text-shadow:1px 1px 0 #FFFFFF, 2px 3px 0 rgba(0, 0, 0, 0.15); }
.favcs:hover i { color:#ECBD08; text-shadow:1px 1px 0 #FFFFFF, 2px 3px 0 rgba(0, 0, 0, 0.15); }
.favcs span { font-size:10px; display:table-cell; vertical-align: middle; text-align:center; margin:0 5px; color:#737373; }
#content .inner-catalog .right-side .peca-info .preco-bid ul li p a:hover {
  color: #525252;
}
#content .inner-catalog .right-side .peca-info .preco-bid .status {
  margin: 0; line-height:24px; text-transform:uppercase;
}
#content .inner-catalog .right-side .peca-info .preco-bid .status .vencendo {
  color:#699E69; margin-left:20px; line-height:24px;
}
#content .inner-catalog .right-side .peca-info .preco-bid .status .vencendo i {
  margin-right:5px; font-size:16px; line-height:24px;
}

#content .inner-catalog .right-side .peca-info .preco-bid .status .coberto {
  color:#D07171;  margin-left:20px; 
}
#content .inner-catalog .right-side .peca-info .preco-bid .status label {
  margin: 0;
}
#content .inner-catalog .right-side .peca-info .preco-bid .status p {
  margin-bottom: 0;
}
#content .inner-catalog .right-side .peca-info .preco-bid .lance-btn {
  margin-bottom: 0;
}
#content .inner-catalog .right-side .peca-info .preco-bid .lance-btn button {
  width: 100%;
  border: 0;
  background-color: #525252;
  color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  padding:0;
}
#content .inner-catalog .right-side .peca-info .preco-bid .lance-btn button.is-removerauto { 
  position:relative; top:0; right:0;
}
#content .inner-catalog .right-side .peca-info .preco-bid .lance-btn button:hover {
  background-color: #BDBDBD;
  color: #fff;
}
#content .inner-catalog .right-side .peca-info .preco-bid .lance-btn span {
  width: 100%;
  background-color: #525252;
  color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  float:left;
  text-align:center;
  cursor:pointer;
  padding: 10px 20px;
  line-height:26px;
}
#content .inner-catalog .right-side .peca-info .preco-bid .lance-btn span i { margin-right:10px; font-size:20px; }
#content .inner-catalog .right-side .peca-info .preco-bid .lance-btn span:hover {
  background-color: #BDBDBD;
  color: #fff;
}
#content .inner-catalog .right-side .peca-info .preco-bid .lance-btn p {
  float: none;
  text-align: center;
}
#content .inner-catalog .right-side .peca-info .preco-bid .lance-input {
  float: left;
  width: 30%;
  margin-bottom: 0;
  border-radius: 0;
  height:38px;
  box-shadow: none;
  padding: 5px;
  border: 1px solid #ddd;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  font-size: 24px;
  color: #8E8E8E;
}
#content .inner-catalog .right-side .peca-info .preco-bid .lance-input:hover {
  border: 1px solid #A7A7A7;
  outline: 0;
}
#content .inner-catalog .right-side .peca-info .preco-bid .lance-margin {
  line-height: 16px;
  margin-right:0;
  margin-bottom: 10px;
  width:100%;
  text-align:left;
}
.lance-margin span { float:right; font-style:italic; font-size:12px; font-weight:normal; }
#lance p { float:left !important; text-align:center; line-height:25px; margin:0 !important; width:100%; }
#lance .lance-input { width:152px !important; text-align:right; margin:0px; }
#content .inner-catalog .right-side .peca-info .preco-bid .lance-margin i {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.lance-wrap { display:inline-block; float:none; width:218px; margin-left:0; }
.lance-wrap .is-rs { background-color:#ddd; color:#737373; height:38px; padding:0 7px; line-height:52px; margin:0; }
#content .inner-catalog .right-side .peca-info .preco-bid .lance-margin:hover i {
  color: #2564c2;
}
#content .inner-catalog .right-side .peca-info .preco-bid .automatic-bid {
  float: left;
  margin-bottom: 0;
  border: none;
  margin-top:1px;
  padding: 0;
}
#content .inner-catalog .right-side .peca-info .preco-bid .automatic-bid i {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease;
}
#content .inner-catalog .right-side .peca-info .preco-bid .automatic-bid a {
  font-size: 11px;
  line-height: 20px;
  color: #8E8E8E;
  margin-bottom: 0;
  float: left;
  margin-left: 5px;
  font-weight:bold;
}
#content .inner-catalog .right-side .peca-info .preco-bid .automatic-bid a:hover i {
  color: #2564c2;
}
#content .inner-catalog .right-side .peca-info .preco-bid .automatic-bid input {
  margin-top: 0;
  float: left;
  width:20px;height:20px;
}
#content .inner-catalog .right-side .peca-info .preco-bid .visitas {
  float: right;
  width: 27%;
  height: 23px;
  border-left: 1px solid #F1F1F1;
}
#content .inner-catalog .right-side .peca-info .preco-bid .small-list {
  float: left;
}
#content .inner-catalog .right-side .peca-info .preco-bid .small-list li {
  width: 100%;
  float: left;
  margin-bottom: 10px;
  position:relative;
}
#content .inner-catalog .right-side .peca-info .preco-bid .small-list label {
  margin-bottom: 0;
}
#content .inner-catalog .right-side .peca-info .preco-bid .small-list label i {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .inner-catalog .right-side .peca-info .preco-bid .small-list label:hover i {
  color: #2564c2;
}
#content .terms-info {
  margin:50px 0;
}
#content .terms-info .tabs dd.active {
  border-top: 3px solid #525252;
}
#content .terms-info .tabs dd a:focus,
#content .terms-info .tabs li a:focus {
  color: #8E8E8E;
}
#content .terms-info .terms-conditions-shipping h5 {
  color: #8E8E8E;
  font-size: 13px;
  font-weight: 600;
  margin-top: 0;
  font-family: 'Open Sans', Arial, sans-serif;
}
#content .terms-info .terms-conditions-shipping p {
  font-size: 13px;
  color: #8E8E8E;
}
#content .terms-info .terms-conditions-shipping li {
  height: 250px;
  overflow-y: scroll;
}
#dhtmltooltip {
  position: absolute;
  width: 225px!important;
  border: 1px solid #A3A3A3!important;
  padding: 10px!important;
  background-color: #fff!important;
  visibility: hidden;
  z-index: 100;
  /*Remove below line to remove shadow. Below line should always appear last within this CSS*/
  filter: progid:DXImageTransform.Microsoft.Shadow(color=gray, direction=135);
  font-size: 13px;
  color: #8E8E8E;
  top: -10px;
}
/* ==========================================================================
   PEÃ‡A QUERIES
   ========================================================================== */
@media only screen and (max-width: 955px) {
  #content .inner-catalog .right-side .peca-info .preco-bid .lance-input {
    width: 20%;
  }
}
@media only screen and (max-width: 840px) {
  #content .inner-catalog .right-side .peca-info .preco-bid .automatic-bid a {
    font-size: 9px;
  }
}
@media only screen and (max-width: 800px) {
  #content .inner-catalog .right-side .peca-info .user-ui p {
    font-size: 11px;
  }
  #content .inner-catalog .right-side .peca-info .preco-bid .lance-input {
    width: 18%;
  }
}
@media only screen and (max-width: 767px) {
  #content .terms-info-mobile {
    display: block;
  }
  .zoomContainer {
    display: none;
  }
  #content .terms-info {
    display: none;
  }
  #content .inner-peca {
    margin-bottom: 35px;
  }
  #content .inner-catalog .right-side {
    padding-left: 15px;
  }
  #content .inner-catalog .right-side .mobile-slider {
    display: block;
    margin-bottom: 15px;
    height: 395px;
  }
  #content .inner-catalog .right-side .mobile-slider .thumb-video {
    position: absolute;
    width: 20%;
    background-color: #fff;
    padding: 5px;
    height: 75px;
    border: 1px solid #F1F1F1;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    z-index: 2;
    right: 0;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
  }
  #content .inner-catalog .right-side .mobile-slider .thumb-video:hover {
    background-color: #737373;
  }
  #content .inner-catalog .right-side .mobile-slider .thumb-video h5 {
    font-size: 11px;
    font-weight: 700;
    margin-top: 0;
    font-family: 'Open Sans', Arial, sans-serif;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0;
    color: #737373;
  }
  #content .inner-catalog .right-side .mobile-slider .thumb-video p {
    text-align: center;
    font-size: 20px;
    margin-bottom: 0;
    color: #737373;
    margin-top: 6px;
  }
  #content .inner-catalog .right-side .mobile-slider .thumb-video:hover p { color:#fff; }
  #content .inner-catalog .right-side .mobile-slider .thumb-video:hover h5 { color:#fff; }
  .swp-btn { display:none; }
  #content .inner-catalog .right-side .mobile-slider .image-container-mobile {
    height: 360px;
    border: 1px solid #F1F1F1;
    position: relative;
  }
  #content .inner-catalog .right-side .mobile-slider .image-container-mobile a {
    width: 100%;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
  }
  #content .inner-catalog .right-side .mobile-slider .image-container-mobile a img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
  #content .inner-catalog .right-side .mobile-pagination {
    bottom: 5px;
  }
  #content .inner-catalog .right-side .mobile-pagination .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
  }
  #content .inner-catalog .right-side .next-mobile,
  #content .inner-catalog .right-side .prev-mobile {
    font-size: 55px;
    line-height: 33px;
    top: 44%;
  }
  #content .inner-catalog .right-side .next-mobile i,
  #content .inner-catalog .right-side .prev-mobile i {
    color: #000;
  }
  #content .inner-catalog .left-side {
    /*display: none;*/
  }
  #content .inner-catalog .left-side .image-row {
    display: none;
  }
  #content .inner-catalog .right-side .peca-info .ui-mobile {
    display: block;
  }
  #content .inner-catalog .right-side .peca-info .ui-mobile i {
    font-size: 20px;
  }
  #content .inner-catalog .right-side .peca-info .ui-mobile a {
    width: 18.8%;
    margin-right: 1.5%;
    border: 1px solid #f1f1f1;
    padding: 10px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    color:#737373;
  }
  #content .inner-catalog .right-side .peca-info .ui-mobile a:hover {
    border: 1px solid #000;
  }
  #content .inner-catalog .right-side .peca-info .lote-desc-type-mobile {
    display: block;
    position: relative;
  }
  #content .inner-catalog .right-side .peca-info .lote-desc-type-mobile .lote-number {
    float: left;
  }
  #content .inner-catalog .right-side .peca-info .lote-desc-type-mobile .lote-number p {
    float: left;
    margin:5px 15px 5px 0;
    line-height: 32px;
  }
  #content .inner-catalog .right-side .peca-info .lote-desc-type-mobile .lote-number p:last-child {
    margin-right: 0;
  }
  #content .inner-catalog .right-side .peca-info .lote-desc-type-mobile .lote-desc {
    float: left;
    margin: 10px 0;
    position:relative;
  }
  .lote-desc-type-mobile .lote-desc .userInteract { position:absolute; top:5px; right:0; }
  #content .inner-catalog .right-side .peca-info .lote-desc-type-mobile .lote-desc a {
    color: #2564c2;
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
  }
  #content .inner-catalog .right-side .peca-info .divider-peca {
    float:left;
    width:100%;
    border-top: 1px solid #f1f1f1;
  }
  #content .inner-catalog .right-side .peca-info .preco-bid ul {
    width: 100%;
  }
  #content .inner-catalog .right-side .peca-info .preco-bid .lance-input {
    width: 51%;
  }
  #content .inner-catalog .right-side .peca-info .preco-bid .automatic-bid a {
    font-size: 13px;
    margin-left: 10px;
  }
  #content .inner-catalog .right-side .peca-info .preco-bid .automatic-bid a span {
    margin-left: 5px;
  }
  #content .inner-catalog .image-thumbs {
    padding-left: 15px;
    display: none;
  }
  #content .inner-catalog .image-thumbs .thumb {
    margin-top: 15px;
    width: 15%;
    margin-right: 2%;
    float: left;
  }
  #content .inner-catalog .image-thumbs .thumb:last-child {
    margin-top: 15px;
    margin-right: 0;
  }

}
@media only screen and (max-width: 620px) {
  .default-modal .mini-description {
    width: 100%!important;
  }
  .default-modal .mini-img-outer {
    width: 100%!important;
    margin-right: 0!important;
    float: none!important;
    margin-bottom: 15px;
  }
  .default-modal .mini-img-inner {
    width: 100%!important;
  }
  .default-modal .mini-img-inner img {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
  #content .terms-info-mobile .terms-btns a {
    width: 100%;
    margin-bottom: 15px;
  }
  #content .terms-info-mobile .terms-btns a:last-child {
    margin-bottom: 0;
  }
  #content .inner-catalog .right-side .peca-info .preco-bid .lance-input {
    width: 26%;
  }
}
@media only screen and (max-width: 425px) {
  #content .inner-catalog .social-btns {
    display: none;
  }
  #content .inner-catalog .social-btns-mobile {
    display: block;
  }
  #content .inner-catalog .social-btns-mobile .facebook,
  #content .inner-catalog .social-btns-mobile .twitter,
  #content .inner-catalog .social-btns-mobile .googleplus {
    width: 22%;
  }
  #content .inner-catalog .right-side .peca-info .preco-bid .lance-margin {
    margin-right: 2%;
    width: 100%;
  }
  #content .inner-catalog .right-side .peca-info .preco-bid .lance-input {
    width: 68%;
  }
  #content .inner-catalog .right-side .peca-info .preco-bid .automatic-bid {
    width: 100%;
  }
  .is-valorinputwrap { float:right; }
}
/* ==========================================================================
   WARNING
   ========================================================================== */
.green-warning,
.red-warning {
  padding: 10px;
  margin-bottom: 5px;
}
.green-warning p,
.yellow-warning p,
.red-warning p {
  margin: 0;
  color: #fff;
}
.green-warning .warning-title i,
.yellow-warning .warning-title i,
.red-warning .warning-title i {
  margin-right: 5px;
  font-size: 20px;
}
.green-warning .warning-title,
.yellow-warning .warning-title,
.red-warning .warning-title {
  font-size: 25px;
  margin-bottom: 5px;
}
.green-warning .warning-text,
.yellow-warning .warning-text,
.red-warning .warning-text {
  font-size: 16px;
}
.green-warning {
  background-color: #699E69;
  border:1px solid #3A6B39;
}
.yellow-warning {
  background-color: #F7F7B1;
  border:1px solid #E0E095;
}
.red-warning {
  background-color: #D07171;
  border:1px solid #A55252;
}
.red-warning p {   color:#fff !important; }
.red-warning a {   color:#fff !important;  font-weight:bold; }
.green-warning p {   color:#fff !important; }
.green-warning a {   color:#fff !important; font-weight:bold;  }
.yellow-warning p {   color:#535353 !important; }
.yellow-warning a {   color:#fff !important; font-weight:normal;  }
.yellow-warning a.close-reveal-modal {   color:#535353 !important;  }
.is-solicitacao .red-warning p {   text-align:center;}
.is-solicitacao .green-warning p {   text-align:center;}
.is-solicitacao .yellow-warning p {   text-align:center;}

.table.yellow-warning {padding: 20px;}
.table.yellow-warning h4 {margin: 0; padding-bottom: 10px;}

.default-modal .warningul { margin-top:20px; }
.default-modal .warningul li { border: none !important; padding:0; width:auto; margin:0 10px 0 0; display:block; }
.botw { border: none !important; background-color: #535353; padding: 10px 20px; color:#fff; float:left; line-height:20px; cursor:pointer; }  
.botverde { background-color:#699E69; }
.botcinza { background-color:#535353; }
/* ==========================================================================
   CADASTRO
   ========================================================================== */
.has-error input,  .has-error select { border-color:#e09291 !important; } 
.has-success input,  .has-success select { border-color:#89d31e !important; } 
.has-error .error.help-block { font-style:italic; color:red; font-size:12px; }
.radio-container label { padding:0 !important; }
.label-container { height:32px; display:table; }
.label-container label { vertical-align:middle; display:table-cell; }
.email-spc-container { height:32px; display:table; }
.email-spc { vertical-align:middle; display:table-cell !important; color:#0032c2 !important; font-size:13px !important; font-weight:bold !important; }
.senha-spc { margin:10px 0 10px 0 !important; font-weight:bold !important; }
.ErrorField { border-color:#FFA1A1 !important;  }
select.ErrorField { background:none !important;  }
.ValidationErrors { line-height:15px; font-style: italic; font-size:11px; color:red !important; padding:5px 0; float:left; }
.SucessField { border-color:#89d31e !important; }
.input-box { position:relative; }
.ValidationSucess { display:none; position:absolute; top:10px; right:10px; color:#89d31e !important;  }
.ValidationSucess i {  color:#89d31e !important;  }
.deuruim { position:absolute; top:10px; right:10px; color:red !important; }
.validationIcon { position:absolute; top:10px; right:10px; color:red !important; }
.has-error .validationIcon i { color:red !important; }
.has-success .validationIcon i{ color:#89d31e !important; }
.captcha .deuruim { display:none; }
.pg-cadastro input { padding-right:30px !important; }
#div_endereco { position:relative; }
.loadingend {  position:absolute; top:0; left:0;  width:100%; height:100%; display:none;  z-index:998;}
.loadicon { position:absolute; top:50%; left:0; width:100%; text-align:center; padding-top:40px; margin-top:-26px; z-index:999; }
.dtnsc .input-container span { display:inline-block;  line-height:30px; }
.dtnsc .input-container span input#dt_dd { display:inline-block; line-height:30px; width:55px; margin-right:5px; }
.dtnsc .input-container span input#dt_mm { display:inline-block; line-height:30px; width:55px; margin:0 5px; }
.dtnsc .input-container span input#dt_yy { display:inline-block; line-height:30px; width:70px; margin-left:5px; }
.dtnsc .ValidationErrors, .dtnsc .ValidationSucess, .dtnsc .deuruim { display:none !important; }
/* ==========================================================================
   CADASTRO
   ========================================================================== */
.ad-row { padding:70px 0; }
.ad-box { float:left; border:1px solid #ccc; box-sizing: border-box; }
.ad-320 { width:302px; margin-right:2px; }
.ad-320:last-child { margin-right:0; }

/* ==========================================================================
   CONTA_SITE
   ========================================================================== */
.input-label-container .button {
  padding: 8px 15px !important;
  color: #fff !important;
  background-color: #515151 !important;
  font-size: 13px !important;
  border: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  margin-top: 15px;  }

#content .inner-content-container .seu-cadastro .input-label-container .botao.button.round, #content .inner-content-container .seu-cadastro .input-label-container .button {border:0!important;}


table th.header { cursor:pointer; }
table th.header span { padding-right:30px; float:left; width:100%; }
table th.headerSortDown, table th.headerSortUp { background-color:#d8dfd0; }
.histconsite{ float:right; border:1px solid #ddd; padding:5px 10px; font-size:15px; color:#737373; }
.histconsite:hover { color:#2564c2; }
.loteinfo { margin-top:10px; }
.conta-subnav .subnav-title { margin:0; }
.conta-subnav .subnav-title i { position:absolute; top:50%; margin-top:-12px; left:0; width:40px; height:24px; }
.conta-subnav .subnav-title h2 { float:left; width:100%; font-size:24px; color:#565656; font-weight:bold; position:relative; padding:0 0 0 40px; line-height:inherit; height:auto; }

.menu-painel { width:100% !important; margin:0 !important; float:left; padding:35px 0; }
.menu-painel ul { width:100% !important; float:left; margin:0; box-sizing:border-box;}
.menu-painel li { box-sizing:border-box; width:10% !important; float:left; padding:0 10px; }
.menu-painel li:first-child { padding-left:0; }
.menu-painel li:last-child { padding-right:0; }
.subnav-menu { margin:10px 0; }
.subnav-menu ul { list-style:none; }
.subnav-menu ul li a { text-align:center; color:#7f8777; font-weight:normal; font-size:11px; float:left; width:100%; position:relative; padding-top:55px; line-height:14px;}
.subnav-menu ul li a:hover {color:#3c3e3a;  }
.subnav-menu ul li a:hover i { background-color:#f1f1f1; color:#000;  }
.subnav-menu ul li.ativo i {background-color:#f1f1f1;  }
.subnav-menu ul li a i { width:45px; height:45px; position:Absolute; top:0; left:50%; margin-left:-22.5px; background-color:#fff; border:1px solid #F1F1F1; font-size:18px; line-height:45px; color:#8E8E8E; }
.subnav-menu ul li a:hover span {background-color:#E8E8E8;  }
.subnav-fundo img { max-width:100% !important; }
.apoioinfo i {  width:35px; height:35px; background:#fff; text-align:center; line-height:35px; position: absolute; left: 0; border:1px solid #F1F1F1; }
.small-title-icon { background-repeat:no-repeat; background-position: center -215px;}
.big-gray-title-icon { float:left; width:50px; height:50px; background-repeat:no-repeat; }
.big-green-icon { background-repeat:no-repeat; background-position: center -67px;}
a:hover .big-green-icon{ background-repeat:no-repeat; background-position: center -127px;}
.ativo .big-green-icon{ background-repeat:no-repeat; background-position: center -127px;}

.intern-title h2 { float:left; width:100%; height:78px; font-size:24px; color:#565656; font-weight:bold; line-height:70px; margin:0; position:relative; }
.intern-title h3 { float:none !important; width:auto; color:#565656; line-height: 20px !important; padding: 10px 0 10px 55px; height: auto !important; font-size:18px; position:Relative; }

.intern-title h4 { float:left; line-height:20px; height:20px; width:auto; color:#6c8449; font-size:16px; padding:0; }
.vermais-link {  }
.bloqueio-title p { color:#ff0000; font-size:12px; margin:0; }


.dados-title .big-gray-title-icon { background-position: -16px -5px; }
.resumo-title .big-gray-title-icon { background-position: -14px -5px; }
.email-title .big-gray-title-icon { background-position: -14px -5px; }
.senha-title .big-gray-title-icon { background-position: -16px -5px; }
.lances-title .big-gray-title-icon { background-position: -14px -5px; }
.pecas-title .big-gray-title-icon { background-position: -14px -5px; }
.compras-title .big-gray-title-icon { background-position: -16px -5px; }
.favoritos-title .big-gray-title-icon { background-position: -14px -5px; }
.vigias-title .big-gray-title-icon { background-position: -10px -5px; }
.mensagens-title .big-gray-title-icon { background-position: -13px -5px; }
.local-title .big-gray-title-icon { background-position: -16px -5px; }
.lbr-title .big-gray-title-icon { background-position: -16px -5px; }
.depoimento-title .big-gray-title-icon { background-position: -16px -5px; }
.bloqueio-title .big-gray-title-icon { background-position: -13px -5px; }

/*** comeÃ§a aqui alt conta_site**/
.intern-title h3.apoioinfo { padding:0 0 0 55px; }
.intern-title h3.apoioinfo.single { padding:0 0 0 55px; line-height:35px !important; }
.intern-title h3 .apoioinfotxt { font-size:11px; width:100%; background:none !important; margin:0; }
.intern-title h3 .apoioinfotxt a { color:#2564c2 ; font-weight:bold; }

.mc-menu { padding-bottom:10px; }
.subnav-fundo { border-bottom:1px solid #F1F1F1; width:100%; float:left; }

.mc-content { padding-bottom:30px; height:100%; }
.mc-content .title-box { display:table; width:100% !important; float:left; padding:0; position:relative; }
.mc-content .title-box .vermais-link { display:table-cell; vertical-align:middle; float:none !important; }
.mc-content .intern-title { padding-left:0; }

.vermais-link a { color:#2564c2 !important; font-size:11px; font-weight:bold; float:right; margin-right:15px;  padding:5px 15px 5px 0; border-right:1px solid #f1f1f1; }

.togglebox { position:absolute; right:0; top:50%; margin-top:-12px; width:25px; height:25px;  }
.togglebox i { width:25px; text-align:center; line-height:25px; color:#565656;  }

.mc-info-block { margin-bottom:10px; }
.mc-label { width:100%; font-size:11px; color:#565656; font-weight:bold; float:left;  }
.mc-label-info { float:left; width:100%; color:#7c7c7c; font-size:14px; margin:5px 0; }
.mc-label-link { float:left; color:#2564c2; font-size:11px; font-weight:bold; }

.is-sidefiltercontent-wrap { position:relative; min-height:80px;  }
.is-sidefiltercontent {  max-height:3000px; transition:all 0.5s ease-out; opacity:1; padding:10px 0; }
.is-sidefiltercontent.onload { max-height:80px; overflow:hidden; opacity:0;}
.is-sidefilter ul { list-style:none; }
.is-sidefilter ul li { }
.is-sidefilter li label { position:relative; padding-left:25px; font-size:11px; margin:0; }
.is-sidefilter .is-sidefilter-checkbox { position:absolute; left:0; top:50%; margin-top:-7px; height:14px; }
.is-sidefilter .sidefilter-input{ height:14px; width:14px; }
.is-sidefilter .sidefilter-numleilao { font-size:10px; font-weight:bold; }

.is-sidebtn { width:100%; border:none; background-color:#8E8E8E; padding:10px 0; font-size:12px; color:#fff; }

.newloading { position:absolute; top:0; left:0; width:100%; height:100%; min-height:200px; background-color:#fff; opacity:0; transition:all 0.8s ease-out; visibility:hidden; }
.newloading.onload { opacity:1; visibility:visible; }
.newloading-icon { width:100%; padding-top:40px; margin-top:20px; text-align:center; float:left; }
/*** termina aqui alt conta_site**/

.mc-toggle { padding-top:5px !important; padding-bottom:10px !important; border-bottom:1px solid #ddd; }
.mc-toggle-title { color:#565656; font-size:20px; font-weight:bold; float:left; width:100%; position:relative; padding:10px 50px 5px 0; }
.mc-toggle-link { float:left; border:1px solid #f1f1f1; height:30px; width:40px; position:absolute; top:50%; right:0; margin-top:-15px; text-align:center;  color:#8E8E8E; background-color:#fff; }
.mc-toggle-link:visited {border:1px solid #f1f1f1; color:#8E8E8E; background-color:#fff; }
.mc-toggle-link:focus { color:#8E8E8E;}
.mc-toggle-link:hover { background-color:#f1f1f1; color:#8E8E8E;}
.mc-toggle-link i { line-height:28px; }

.mc-toggle-link.ativo { background-color:#f1f1f1; color:#000; }

.mc-header { padding-top:10px !important; padding-bottom:10px !important; }
.mc-header p { margin:0; font-size:11px; }
.mc-header-links { float:right; }
.mc-header-links a { margin-left:10px; color:#2564c2 !important; }
.mc-header-links .mc-header-home  { margin-right:10px; }

.mc-ativo { overflow-y:scroll; }


.intern-title h2 { float:left; width:100%; height:78px; font-size:22px; color:#565656; font-weight:bold; line-height:70px; margin:0; position:relative; }
.intern-title h3 { float:none !important; width:auto; color:#565656; line-height: 20px !important; padding: 0 0 0 55px; height: auto !important; font-size:16px; position:Relative; }
.intern-title h4 { float:left; line-height:20px; height:20px; width:auto; color:#6c8449; font-size:16px; padding:0; }

#titlespec { display:block; width:100%; }

.conteudo-block { margin:0 0 30px 0; }
.conteudo-box { padding-top:20px; }

.center-table { text-align: center !important; }
.antiga_mensagem { background:#fff !important; }
.nova_mensagem { background:#f9f9f9; }
.nova_mensagem td { background:#fff; }
.nova_mensagem i { position:relative; }
.tabela-mensagens span { font-size: 11px; }
.tabela-mensagens { float: left; border:none; width:100%; }
.tabela-mensagens thead { background:transparent; }
.tabela-mensagens thead th { background-color:#8E8E8E; text-transform:uppercase; border:none; font-size:11px; color:#fff; }
.tabela-mensagens tbody td { border-bottom:3px solid #fff; vertical-align:middle; }
.tabela-mensagens .small-title-icon { width:35px; height:35px; display: block; margin:0 auto; position:relative; }
.tabela-mensagens .big-green-icon { width:35px; height:35px; display: block; margin:0 auto; position:relative; background-color:transparent; background-position:center -71px; }
.tabela-mensagens a:hover .big-green-icon { background-position:center -71px; }
.antiga_mensagem td { background-color:#F9F9F9; }

.msg-small { float:left; width:100%; }
.msg-small table { margin:0 0 10px 0; }
.msg-small tbody td { border:none; }
.label-small { font-weight:bold; }

.mensagem-box { font-size:13px; position:relative; }
.sepbox { border-right:1px solid #000;  }
.newmsg-alert {  cursor:default; position:absolute; top:8px; right:-8px; font-size:11px; text-align:center; width:14px !important; height:14px; background-color:red; color:#FFF; font-weight:bold; border-radius:25px; border:2px solid #fff; }
.has-alert { padding-right:25px; font-weight:bold;}
.menu-painel .newmsg-alert { font-size:15px; width:20px !important; height:20px; right:20px;  }
.chat-wrap { margin:0 auto; width:600px; position:relative; }
.chat-wrap h2 { font-family:arial; font-size:16px; margin:0; }
.chat-wrap h3 { font-family:arial; font-size:14px; margin:20px 0; }
.chat-wrap ul { margin:0; padding:0; list-style:none; }
.chat-box { position:relative; width:100%; box-sizing:border-box; padding:0 40px; float:left; }
.chat-box .usericon { position:Absolute; bottom:10px; }
.chat-box .usericon i { font-size:30px; }
.A-top .usericon { left:0; color:#737373; }
.G-top .usericon { right:0; color:#737373; }
.chat-box-wrap { position:relative; width:70%; box-sizing:border-box; border-radius:5px; padding:10px; margin-bottom:10px; border:1px solid #f1f1f1; float:left; 
  -webkit-box-shadow: 1px 1px 1px 0px rgba(0,0,0,0.2);
-moz-box-shadow: 1px 1px 1px 0px rgba(0,0,0,0.2);
box-shadow: 1px 1px 1px 0px rgba(0,0,0,0.2);
}
.chat-box .newmsg-alert { display:none; }

.A-box:after {
  right: 100%;
  bottom: 10px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(37, 100, 194, 0);
  border-right-color: #fff;
  border-width: 10px;
  margin-top: -10px;
 }
 .G-box:after {
  left: 100%;
  top: 70%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(37, 100, 194, 0);
  border-left-color: #ddd;
  border-width: 10px;
  margin-top: -10px;
 }
.chat-box.box-nova { border-color:#3f3f3f; }
.chat-box.box-nova .chatbox-header { border-color:#3f3f3f; }
.chat-box.box-nova .newmsg-alert { display:block; }
.chat-box p { margin:0; font-family:Arial; float:left; }
.chat-box .chatbox-header { line-height:16px; font-weight:normal; float:left; width:100%; font-size:11px; padding:0; }
.chat-box .chatbox-writer { font-size:14px; float:left; width:100%;}
.chat-box .chatbox-writer a { color:#2564c2; }

.chat-box .chatbox-message { float:left; width:100%; font-size:14px; padding:5px 0 0 0; box-sizing: border-box; }
.chat-box .newmsg-alert { top:10px; right:10px; width:20px; height:20px; }
.chat-box-warning .chatbox-writer { text-align:center; }
.chat-box-warning .chatbox-message { text-align:center; }
.warning-btns { float:left; width:100%; margin:20px 0;  }
.warning-center { width:300px; margin:0 auto; }
.confirm-btn { width:145px; float:left;  }
.confirm-btn a { float:left; width:100%; text-align:center; font-size:12px; padding:10px 0; background:#545454; color:#fff; border-radius:5px; }
.cancel-btn { width:145px; float:right;  }
.cancel-btn a { float:left; width:100%; text-align:center; font-size:12px; padding:10px 0; background:#545454; color:#fff; border-radius:5px; }

.A-box { background:#fff; color:#737373; }
.G-box { background:#ddd; float:right; }
.chat-list-wrap { position:relative; float:left; width:100%; border:1px solid #e6ece0; }
.chat-list { position:relative; float:left; height:350px; width:100%; list-style:none; padding:10px 15px; background-color:#f1f1f1; margin:0; overflow:hidden; }
.chat-list-load { float:left; width:100%; list-style:none; }
.chat-list-wrap.loaded .chat-list { overflow-y:scroll; overflow-x:hidden; }
.chat-list-wrap .loading-position { opacity:1; transition:visibility 0s linear 0.5s,opacity 0.5s linear; width:100%; }
.chat-list-wrap.loaded .loading-position { opacity:0; visibility:hidden; }
.chat-list-wrap:after { 
  content:'';
  height: 20px;
  left: 0;
  bottom:0;
  position: absolute;
  width: 100%;
  -webkit-box-shadow: inset 0px -9px 9px -5px rgba(0,0,0,0.2);
-moz-box-shadow: inset 0px -9px 9px -5px rgba(0,0,0,0.2);
box-shadow: inset 0px -9px 9px -5px rgba(0,0,0,0.2);
}
.nova_mensagem .bd4 { font-weight:bold; }
.nova_mensagem .bd3 { background-color:#fff; }
.histicon { position:relative; display:block; height:15px; width:15px; }
.nova_mensagem .histicon { padding-right:25px;  }
.nova_mensagem .newmsg-alert { width:14px; height:14px; top:1px; }
.antiga_mensagem i { position:relative; color:#8E8E8E; }
.mobile-msg-link i { font-size:24px; }
.antiga_mensagem .newmsg-alert { display:none;  }
.chatreply { float:left; width:100%; }
.chatreply-box { padding:10px 15px; background:#e0e0e0; float:left; width:100%; box-sizing: border-box; }
.chatreply-box form { float:left; width:100%; margin:0; }
.chatreply-header { margin:0 0 10px 0; font-family:arial; font-size:12px; font-weight:bold; float:left; width:100%; }
.chatreply textarea { width:100%; height:80px; border-color:#ddd; resize:none; padding:5px; font-size:12px; }
.chatreply-send { border:none; background:#545454; color:#fff; font-weight:bold; text-align:center; padding:10px 30px; font-size:12px; float:left !important; margin:10px 0 0 0; }
.chatreply-send.inativo { background-color:#f9f9f9; color:#e7e7e7;}
.has-anexo { width:100%; float:left; margin:5px 0; padding-top:10px; font-size:11px; line-height:30px; }
.anexobtn { padding:5px; border-radius:5px; border:1px solid #f1f1f1; color:#757575; background-color:#fff; float:left; width:96px;  }
.anexobtn:hover p { color:#2564c2; }
.anexobtn:hover i { color:#2564c2; }
.anexobtn i { color:#757575; font-size:15px; margin-right:5px;  }
.anexobtn p { float:left; width:100%; font-size:12px; margin-bottom:5px; }
.anexobtn img { max-width:100px; max-height:100px; float:left; }
.msg-hora { display:block; width:100%; color:#a7a7a7; font-size:11px; float:left; margin-bottom:2px; }
.view-visualizado {  }
.view-visualizado i { margin-right:-5px; }
.view-visualizado.svis i { color:#3aba57; }
.A-hora { text-align:left; }
.G-hora { text-align:right; }

.tabela-geral { border:none; margin-top:20px; float:left; width:100%; }
.tabela-geral th { background-color:#8E8E8E; text-transform:uppercase; border:none; font-size:11px; color:#fff; }
.tabela-geral td { font-size:12px; vertical-align:middle; background:#f9f9f9; border:1px solid #fff;  }
.tabela-geral a { color:#2564c2;  }

.tabela-lances { border:none; }
.tabela-lances thead { background:#fff; }
.tabela-lances thead th { background-color:#727272; text-transform:uppercase; border:none; font-size:11px; color:#fff; }
.tabela-lances tbody td { vertical-align: middle; font-size:11px; border:1px solid #fff; background:#f9f9f9; }
.tabela-lances tbody td span { float:left; width:100%; text-align:center; }
.tabela-lances tbody td a { color:#2564c2; }
.tabela-lances tr.pendente td { background-color:#fff3f3;  border-bottom:1px solid #fee2e2; }
.tabela-lances tr.pendente td a { color:#ff0000; }
.tabela-lances tr.ok td { background-color:#f3f7ef;  border-bottom:1px solid #d9e6cb; }
.tabela-lances .tbl-button { background:#727272; color:#fff;margin:5px 0; }

.tabela-bloqueios { border:none; }
/*.tabela-bloqueios a { color:#2564c2; font-size:18px; font-weight:bold; }
.tabela-bloqueios a:hover { color:#2564c2; text-decoration:underline; }*/
.tabela-bloqueios thead { background:#fff; }
.tabela-bloqueios thead th { background-color:#ff0000; text-transform:uppercase; border:none; font-size:11px; color:#fff; }
.tabela-bloqueios thead th:first-child {  border-top-left-radius:10px; }
.tabela-bloqueios thead th:last-child {  border-top-right-radius:10px; }
.tabela-bloqueios tbody tr { background:#fff !important; }
.tabela-bloqueios tbody td { vertical-align: middle; font-size:11px; border-bottom:1px solid #fee2e2; background:#fff; }
.wrap-bloq { padding:0 10px; background:#fff3f3; }
.wrap-bloq table { background:#fff3f3; }

.tabela-compras { border:none; }
.tabela-compras thead { background:#fff; }
.tabela-compras thead th { background-color:#8E8E8E; text-transform:uppercase; border:none; font-size:11px; color:#fff; }
.tabela-compras tbody tr { background:#fff !important; }
.tabela-compras tbody td { vertical-align: middle; font-size:11px; border-bottom:1px solid #e6ece0; }
.tabela-compras tr.pendente td { background-color:#fff3f3;  border-bottom:1px solid #fee2e2; }
.tabela-compras tr.finalizado td { background-color:#f3f7ef;  border-bottom:1px solid #d9e6cb; }
.tabela-compras a { color:#2564c2;  }
.tabela-compras a.tbl-button { color:#2564c2; float:left; width:100%; padding:5px; text-align:center; }
.tabela-compras .tabela-compras-inter td { background:#F1F1F1; }
.tabela-compras table { margin:0; border:none; float:left;}
.tabela-compras td { font-size:12px;  }
.tabela-compras .compra-label { font-weight:bold; margin:0 0 10px 0; float:left; width:100%; }
.tabela-compras .imagem-compra { height:inherit; width:100%; text-align:center; float:left; line-height:10px; }
.tabela-compras .imagem-compra img {  max-width:100%; max-height:100%; }

.tabela-favoritos { border:none; }
.tabela-favoritos thead th { background-color:#8E8E8E; text-transform:uppercase; border:none; font-size:11px; color:#fff;}
.tabela-favoritos thead th span { float:left; width:100%; }

.tabela-favoritos td { font-size:11px; vertical-align:middle; }
.tabela-favoritos td a { color:#2564c2; }
.tabela-favoritos td a.tbl-button { float:left; width:100%; text-align:center; }
.tabela-favoritos tr.encerrado td  { background-color:#fbf9de; border:1px solid #fff; }
.tabela-favoritos tr.encerrado td a.tbl-button { background-color:#c1a01e; color:#fff; }
.tabela-favoritos tr.encerrado td a.tbl-button:hover { background-color:#3c3e3a; }
.tabela-favoritos tr.encerrado td a.linkfav { color:#2564c2; }
.tabela-favoritos tr.ok td  { background-color:#f3f7ef; border:1px solid #fff; }
.tabela-favoritos tr.ok td a.tbl-button { background-color:#1d8124; color:#fff; }
.tabela-favoritos tr.ok td a.tbl-button:hover { background-color:#104813; }

.tbl-button { color:#2564c2; float:left; width:100%; padding:5px; text-align:center; }

.statustxt { text-transform:Capitalize; font-weight:bold; font-size:12px; }
.pendente .statustxt { color:#ff0000; }
.finalizado .statustxt { color:#6b8349; }
.pendente a.tbl-button{ background:#ff0000; color:#fff; }
.finalizado a.tbl-button{ background:#6b8349; color:#fff; }

.paginacao { float:left; margin:0; }
#content .inner-content-container .destaque-wrap .paginacao p, #content .destaque-wrap .paginacao p { display:inline; margin:0 15px 0 0; line-height:38px; }
.paginacao ul { float:right; list-style:none; padding:0; margin:0; ; }
.paginacao ul li { float:left; margin:0; border:1px solid #ddd;  -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out;}
.paginacao ul li.last-li { border-right:1px solid #ddd; }
.paginacao ul li span { color:#ddd; float:left;  padding:8px 0; line-height:20px ; font-size:16px; min-width:36px; text-align:center; }
.paginacao ul li span i { line-height:20px ; -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out;}
.paginacao ul li a i { line-height:20px ; -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out;}
.paginacao ul li.fixedpag { border-right:1px solid #ddd; padding:0 10px; }
.paginacao ul li.fixedpag a { padding:8px 10px; }
.paginacao ul li.fixedpag span { color:#dddee1; float:left;  padding:8px 10px; line-height:20px; }
.paginacao ul li a { color:#737373; float:left;  padding:8px 0; line-height:20px !important; font-size:16px; min-width:36px; text-align:center; }
.paginacao ul li:hover { background-color:#ddd;  }
.paginacao ul li:hover a {color:#000; background:#ddd; }
.paginacao ul li:hover span i, .paginacao ul li:hover span { color: #fff;}
.paginacao ul li.dotdot { border:none; }
.paginacao ul li.dotdot:hover {background:transparent; }
.paginacao ul li.dotdot span { line-height:20px; }
.paginacao ul li.ativo { }
.paginacao ul li.ativo span { font-weight:bold; color:#000; background:#ddd; }
.paginacao .nextarrow { margin:0; border-right:1px solid #ddd; }
.paginacao ul li:last-child { margin:0; border-right:1px solid #ddd; }
.paginacao p { float:left; line-height:38px; margin-right:10px;  }

.leilao-title-interna { padding:20px 0 0 0; float:left; width:100%; margin:0 0 20px 0; }
.lel-title  { float:left; width:100%; font-size:14px; margin:0 0 3px 0; }
.lel-title a { color:#2564c2; }
.lel-data  { float:left; width:100%; font-size:14px; }

#detalhe_leilao .vermais-link { display:table-cell; vertical-align:middle; float:none !important; padding-right:0 !important; }
#detalhe_leilao .vermais-link a { color:#2564c2 !important; font-size:11px; font-weight:bold; float:right; margin:0;  padding:5px 0; border:none; }

.compra-ul { list-style:none; }
.compra-ul table.tabela-compras { border:1px solid #e6ece0 !important;  }
.compra-ul table.tabela-compras td { border-bottom:none; }

.int-tools ul { list-style:none; float:left; margin:0; }
.int-tools ul li { list-style:none; float:left; padding:0 5px; background:#f1f1f1; transition:all 0.5s ease; margin:10px 10px 0 0; }
.int-tools ul li a { color:#565656; padding:5px 15px 5px 30px; font-size:12px; float:left; position:relative; }
.int-tools ul li i{ position:absolute; top:50%; left:5px; font-size:16px; margin-top:-9px; }

.pre-calc { list-style:none; }
.pre-calc li { padding:5px; float:left; width:100%; }
.pre-calc li.par {background:#F1F1F1;}
.x-info-label { font-size:12px; width:190px; float:left; }
.x-info-value { font-size:12px; font-weight:bold; }
.valortab { font-weight:bold; font-size:14px; }

/*.imprimir-ori { position:absolute; top:9px; left:30px; font-size:14px; color:#6c6c6c; text-decoration:none; padding:10px; line-height:14px; cursor:pointer;background:#f1f1f1; }*/
.imprimir-ori {  }
.compsend-box h4 { margin-top:0; }
.modal-pagamento { height:600px; padding-top: 50px !important; }
.modal-pagamento p { max-height:100%; overflow:scroll; padding:20px; overflow-x:hidden; border:1px solid #f1f1f1; }
#Foto { border:1px solid #fff; padding:7px; width:100%; color:#000;}
.foto-icon { float:left; padding:20px 0; font-size:18px; }
.foto-icon i { font-size:24px; margin:0 10px 0 0; }

.reveal-modal { position:fixed; max-height:1000px; z-index:999; transition: all 0.6s ease-in-out; -moz-transition: all 0.6s ease-in-out; -webkit-transition: all 0.6s ease-in-out; }
.reveal-modal-bg { z-index:998; position:fixed !important; }

#myModal1 { }
.reveal-modal .modal1-btn { display:inline-block; color:#fff; padding:10px; background:#f1f1f1; border-radius:5px; transition:all 0.5s ease;  margin-bottom:15px; }
.reveal-modal .modal1-btn i { margin-right:10px; font-size:15px; }
.reveal-modal .imprimir-ori { background-color:#2564c2; cursor:pointer; }

.descricao { margin:10px 0 0 0; float:left; }
.loading-comp { display:none; height:291px; float:left; width:100%; }
.loader {  margin:100px 0 0 0; display:block; width:100%; padding:50px 0 0 0; font-size:14px; font-weight:bold; text-align:center; }
.resultado {  margin:100px 0 0 0; display:none; float:left;  width:100% !important; padding:50px 0 0 0; font-size:14px; font-weight:bold; text-align:center; }

.obs-compmsg {  }
.obs-compmsg textarea { border-radius:0; height:100px; width:100%; resize:none; border-color:#ddd; margin:10px 0;}
.obs-compmsg textarea:focus { color:#242424;}
.obs-compmsg input.button { background:#8E8E8E; color:#fff; border:none; }

#content .inner-content-container .seu-cadastro span.meusdados { font-size:14px; line-height:32px; font-weight:bold; }
.contasite .LoteProd { width:auto !important; margin-top:10px; }
.contasite .LoteProd a { font-size:12px; line-height:14px;  }
.contasite .LoteProd a span { font-size:11px; }
.contasite .status-infodown-Vencendo { font-size:11px; font-weight:bold; line-height:28px; background-color:green; color:#fff; padding:0 5px; float:left; margin-top:10px; }
.contasite .tipo-infodown { font-size:11px; font-weight:bold; line-height:28px; background-color:blue; color:#fff; padding:0 5px; float:left; margin-top:10px; margin-left:2px; }
.contasite .status-infodown-Coberto { font-size:11px; font-weight:bold; line-height:28px; background-color:#800000 ; color:#fff; padding:0 5px; float:left; margin-top:10px; }
.contasite .prod-title h3 { font-size:12px; height:48px; }

.int-tools { padding-top:0; }
.int-tools ul #cobranca-btn { background-color:#2564c2; border-radius:5px; }
.int-tools ul #comprova-btn { background-color:#c28125; border-radius:5px; }
.int-tools ul #mensagem-btn { background-color:#4CAF50; border-radius:5px; }
.int-tools ul li:hover { background-color:#757575 !important; }
.int-tools ul li .is-newbtn { padding:10px 15px 10px 35px; font-size:13px; color:#fff; }
.int-tools ul li .is-newbtn i { font-size:18px; margin-top:-10px; color:#fff; }

#retcobranca { border:1px solid #ccc; padding:10px; overflow-y:scroll; height:500px; }
/* ==========================================================================
   DESTAQUES
   ========================================================================== */

.is-destaques .inner-content-title { position:relative; }
.is-destaques h2 { display:inline; }
.is-pesquisa h2 { }
.is-pesquisa .img-box-wrap {margin-top: 0!important;}
.is-pesquisa .prod-title h3 a { }
.is-pesquisa .LoteProd a {}
.is-pesquisa .prod-title h3 { } 
.cat-filtro { float:right;  }
.cat-filtro form { margin:0; float:left; }
.cat-filtro label { display:inline; text-align:right; line-height:33px; font-size:12px; margin:0 10px 0 0; }
.cat-filtro select { float:right; border:1px solid #ddd; padding:5px; color:#8e8e8e; width:200px; }

.mc-menu .cat-filtro { float:left;  }
.mc-menu .cat-filtro label { float:left; width:100%; text-align:left; }
.mc-menu .cat-filtro select { float:left; width:100%; }

.is-Grid { float:left; width:100%; list-style:none; }
.is-Grid li { margin-bottom:30px; }
.is-Grid li .wrap-gal{ height:152px; width:100%; padding:0 !important; border:1px solid #e9e9e9;  background-color:#FFF; box-shadow:2px 2px 1px -2px #d8d8d8; display:table; }
.is-Grid li a { height:150px; width:100%; vertical-align:middle; display:table-cell; text-align:center; line-height:148px; }
.is-Grid li a img { max-width:100%; max-height:100%; text-align:center; vertical-align:middle;  }

.is-convites .is-Grid li .wrap-gal { height:252px; }
.is-convites .is-Grid li .wrap-gal a { height:250px; cursor:pointer; }

#encontrados { float:none; display:inline; }
#encontrados b { font-size:18px; }
.find-numb { font-size:24px; font-weight:bold; float:left; margin-right:10px; line-height:36px; color:#737373; }
.is-destaques .paginacao { float:right; margin:0;  }
.is-pesquisa .paginacao { position:absolute; top:50%; right:15px; margin-top:-19px;  }
.is-catalogo .paginacao { position:absolute; top:50%; right:15px; margin-top:-19px;  }
.is-catalogo  .last-destaque-row .paginacao { right:0px; }
.destaque-row { margin-bottom:25px !important; margin-top:0 !important; position:relative; }
.last-destaque-row { margin-bottom:1px !important; margin-top:20px !important; }
.destaque-wrap { padding:10px 15px; background-color:#f1f1f1; position:relative; float:left; width:100%; }
.last-destaque-row .destaque-wrap { padding:20px 0; }
#content .inner-content-container .destaque-wrap p, #content .destaque-wrap p { margin:0; display:block; line-height:38px; }
.featherlight .featherlight-content { overflow:initial !important; }
.featherlight .featherlight-content .caption { margin-top:20px; max-width:600px; line-height:22px;}

/* ==========================================================================
   PESQUISA
   ========================================================================== */
.banner-orama-pesq {padding-top: 20px!important; padding-bottom: 20px!important;}
.resultado-search ul { padding:0; margin:0; list-style:none; }
.prod-box { padding:0; border:none;  }
.prod-box:hover { border-color:#E2E1E1; }
.prod-box.hover { border-color:#E2E1E1; }
.prod-box .img-box-inner { border:none; width:150px; height:150px; }
.is-pesquisa .prod-box { margin-bottom:20px; }
.img-box-wrap { width:100%; float:left; height:200px; border:1px solid #f1f1f1; position:relative; margin-top:10px; }
.prod-title { float:left; width:100%;}
.prod-title h3 { font-size:14px !important;  font-family:arial,verdana,helvetica,sans-serif; font-weight:normal; line-height:16px; padding:0 !important; height:65px; overflow:hidden;}
.prod-title h3 a { color:#2564c2; }
.img-box-inner a { width:150px; height:150px; float:left;}
.img-box-inner img {  max-width:100%; max-height:100%; vertical-align: middle;  }
.img-box-inner-search {  width:100%; height:100%; background:#FFF }
.img-box-inner-desktop {  }
.img-box-inner-search a { width:100%; display:block; height:100%; display:flex; justify-content: center; }
.img-box-inner-search img {  max-width:100%; max-height:100%; align-self: center; }
.LoteProd { float:left; width:100%; }
.extra-info-lance { float:right; font-size:11px; line-height:24px; color: #8E8E8E}
.LoteProd a { line-height:24px; float:left; color:#2564c2; font-weight:bold; font-size:11px; }
.extra-info-lance span { font-weight:bold; }
.status-prod  { margin-bottom:10px;}
.status-prod .aovivo-status { 
  font-size:13px; width:auto; color:red; line-height:27px; position:relative; padding:0 5px; border:1px solid red; text-transform:uppercase;  font-weight:bold; display:inline-block; margin:0;
}
.is-peca .status-prod .aovivo-status { width:100%; text-align:center; margin:10px 0 0 0; background-color:#ff0000; color:#fff; border-color:#ff0000; }
.status-prod .aovivo-status i { font-size:22px; margin-left:5px; margin-top:2px; float:right; }

.loteestatus a { float:left; }
.loteestatus .status-prod { float:left; margin-left:10px; }
.is-catalogo-lado .status-prod .aovivo-status {  }
.is-catalogo-lado .prod-box:hover .status-prod .aovivo-status { background-color:red; border-color:red; color:#fff;  }
.pesquisa-finalizados .search-login {color:#807E7E; }
.search-login span { font-weight:normal; color:#666; font-size:11px; font-family:arial, â€‹verdana, â€‹helvetica, â€‹sans-serif;}
.finalizado-box .search-login { line-height:30px; font-size:12px; font-weight:bold; color:#a9a9a9; }
.pc-vendida .search-login {  color:#7A0303; }
.pc-vendida .dia-prod b { color:#7A0303; }
.vendido-box .search-login { line-height:30px; font-size:12px; font-weight:bold; color:#7A0303; }
.search-login:hover { color:#3c3e3a; }
.search-lance-btn { width:100% !important; float:left !important; border-radius:5px; -moz-border-radius:5px; -webkit-border-radius:5px; font-size:11px; color:#FFF; background:#758b83; line-height:30px; text-align:center; }
.search-lance-btn:hover { background:#3c3e3a; color:#FFF; }
.maisdetalhes-btn { width:100% !important; float:left !important; border-radius:5px; -moz-border-radius:5px; -webkit-border-radius:5px; font-size:11px; color:#FFF; background:#a9a9a9; line-height:30px; text-align:center; }
.maisdetalhes-btn:hover { background:#3c3e3a; color:#FFF; }
.vendido-btn { width:100% !important; float:left !important; border-radius:5px; -moz-border-radius:5px; -webkit-border-radius:5px; font-size:11px; color:#FFF; background:#7A0303; line-height:30px; text-align:center; }
.vendido-btn:hover { background:#3c3e3a; color:#FFF; }

.mk-login { line-height:14px !important; }
.mk-login a { font-size:14px !important;  line-height:11px !important;  }
.prod-box:hover .vermais-btn a { opacity:1; }
.prod-box:hover .vermais-btn a { opacity:1; }
.pesquisa-ativos .vermais-btn a { background-color:#758b83; }
.pesquisa-ativos .vermais-btn a { background-color:#758b83; }
.pesquisa-finalizados .vermais-btn a { background-color:#807E7E; }
.pc-vendida .vermais-btn a { background-color:#7A0303; }
.vermais-btn a:hover { background-color:#3c3e3a; color:#fff; }

.bannerwrap { overflow:hidden; }

.quervender-sub { padding-bottom:50px; padding-top:20px;}
.quervender-sub h2 { color:#6c8449; font-size:18px; }
.quervender-sub h3 { color:#000; font-size:18px; line-height:25px; padding:0; text-align:left; margin:0 0 20px 0; }
.quervender-sub p {  font-size:14px; text-align:left; margin:0 0 15px 0 !important; }
.quervender-sub b { color:#6c8449;  }
.step-box { padding-left:72px !important; position:relative; margin:30px 0; }
.step-box textarea { border-radius:5px !important; height:171px; resize:none; }
.step-circle { position:absolute; top:0; left:6px; width:48px; height:48px; background:#6c8449; border-radius:25px; color:#efefef; font-size:30px; line-height:48px; text-align:center; }
.upload-box { padding:10px; background:#fafafa; border-radius:5px; border:1px solid #c3cbba;  box-sizing:border-box; }
.upload-box h3 {  }

.search-login { line-height:22px; height:50px; overflow:hidden; font-weight:bold; color: #8E8E8E!important; font-size:18px !important; font-family:arial, â€‹verdana, â€‹helvetica, â€‹sans-serif;}
.search-login a { color:#2564c2; }
.lancepeca-infofinal p { font-size:14px;line-height:15px; height:35px; font-weight:bold; overflow:hidden; color:#000 !important; font-family:arial, â€‹verdana, â€‹helvetica, â€‹sans-serif; margin-bottom:5px;}
.lancepeca-infofinal .infofinal-meulance { font-size:10px; font-weight:normal; }
.lancepeca-infofinal .infofinal-ultlance { font-size:10px; font-weight:normal; }
span.price-bid { font-size:20px; color:#2564c2; }
.vermais-btn { height:32px; margin:10px 0 0 0; }
.vermais-btn a{  text-align:center; color:#fff; float:left; width:100%; padding:10px 0; font-size:11px;  opacity:0; background:#000;
		-webkit-transition: all 0.5s ease;
	  -moz-transition: all 0.5s ease;
	  -ms-transition: all 0.5s ease;
	  -o-transition: all 0.5s ease;
	  transition: all 0.5s ease;
 }
.ToolBox { float:left; width:100%; }
.ToolBox ul { list-style:none; margin:0; }
.is-Submit a { color: #17a2b8;; }
.TitleToolBox { padding:10px; border-bottom:1px solid #f1f1f1; float:left; }
.TitleTool { font-weight:bold; text-transform:uppercase; font-size:12px; float:left; padding-left:22px; position:relative;}
.TitleTool i { position:absolute; top:50%; margin-top:-6px; left:0; }
.ContentToolBox { float:left; width:100%; }
.UserTools li { position:relative; padding:5px 0; float:left; width:100%; }
.UserTools li.selected { background:#737373;  }
.UserTools li input { height:17px; width:17px; float:left; margin-right:10px; }
.UserTools li label { padding:0; text-align:left; font-size:12px;line-height:15px; }
.UserTools li.selected label {color:#fff; }
.UserTools li.selected {background: #201806;}
.UserTools li.selected label, .is-ferramenta li a:hover label, .is-ferramenta li a.ativo label, .is-ferramenta li a.ativo i, .is-ferramenta li a:hover i,
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .lote-status a, .vermais-btn a, #content .inner-catalog .leilao-catalogo-top .catalog-box .facebook {color: #ffffcd}

.ShowBox { margin:0 0 20px 0; float:left; }
.ShowBox label { width:100%; float:left; }
.is-Select { border:1px solid #ddd; padding:5px; height:32px; }
.is-label { font-size:11px; }
.is-filtro li { margin:0 0 10px 0; padding:0; float:left; width:100%; }
.is-filtro li:last-child { margin:0; }
.is-ferramenta li { margin:0 !important; }
.is-ferramenta li a.ativo { background:#737373 ; border:1px solid #737373 ; }
.is-ferramenta li a.ativo label { color:#fff; }
.is-btn { width:100%; background:#737373; text-align:center; color:#fff; font-size:12px; padding:10px 0; float:left;  }
.is-btn:hover { width:100%; background:#000; text-align:center; color:#fff; font-size:12px; padding:10px 0; float:left;  }
.dia-prod { font-size:11px; height:20px; float:left; width:100%; color: #8E8E8E}
.is-irpara {  }
.is-ferramenta { float:left; width:100%; padding:0; }
.is-ferramenta li a { float:left; width:100%; border-top:1px solid #ddd; border-left:1px solid #ddd; border-right:1px solid #ddd; padding:5px 0; -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; color: #17a2b8;}
.is-ferramenta li:last-child a { border-bottom:1px solid #ddd; }
.is-ferramenta li a i { float:left;  text-align:center; margin:0 10px; font-size:18px; color:#737373; }
.is-ferramenta li a.ativo i { color:#fff; }
.is-ferramenta li a label { text-align:left; font-size:12px; }
.is-ferramenta li a:hover { background-color:#737373; color:#fff; border-color:#737373; }
.is-ferramenta li a:hover i { color:#fff;  }
.is-ferramenta li a:hover label { color:#fff;  }

.search-container { margin:0; }
.search-container input { margin:0; width:200px; padding-right:30px;  -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out;}
.input-spacement { position:relative; }
.search-btn { position:absolute; top:0; right:6px; width:40px; height:32px; -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out;}
.search-btn i {-webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; }
.pesq-label { display:table; width:100%; float:left; }
.pesq-label label { display:table-cell; width:100%; height:32px; vertical-align:middle; text-align:right; }
.padding-box { padding:0 10px; }

.sucess-box { text-align: center; background: #e6ece0; border: none; border-radius: 5px; padding: 10px; margin: 0 0 30px 0;  }
.sucess-box p { color:#6b8349 !important; }
.emailcad { font-size:25px; color:#2564c2; }

/* ==========================================================================
   FOOTER
   ========================================================================== */
#footer-wrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
}
#footer {
  background: #8E8E8E;
  width: 100%;
  padding: 40px 0;
  border-top: 1px solid #373737;
}
#footer .footer-logo {
  margin-bottom: 15px;
}
#footer .footer-logo h1 {
  margin: 0;
}
#footer .footer-logo h1 img {
  width: inherit;
}
#footer .footer-menu a {
  text-decoration: none;
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
#footer .footer-menu a:hover {
  color: #BDBDBD;
}
#footer .footer-menu ul {
  list-style: none;
}
#footer .footer-menu h5 {
  margin: 0;
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  padding-bottom: 10px;
  text-transform: uppercase;
}
#footer .footer-menu li {
  padding: 0 0 11px 0;
}
#footer .footer-menu li:last-child {
  padding: 0;
}
#footer .footer-caption h4 {
  margin: 0;
  font-size: 12px;
  font-family: 'Open Sans', Arial, sans-serif;
  font-weight: 600;
  padding-bottom: 10px;
  text-transform: uppercase;
}
#footer .footer-caption p {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  color: #D4D4D4;
  padding-bottom: 25px;
}
#footer .footer-caption p:last-child {
  padding: 0;
}
#footer .footer-address {
  margin-bottom: 15px;
}
#footer .footer-address h5 {
  margin: 0;
  font-size: 12px;
  font-family: 'Open Sans', Arial, sans-serif;
  font-weight: 600;
  padding-bottom: 5px;
}
#footer .footer-address p {
  margin: 0;
  font-size: 13px;
  font-weight: 400;
  color: #D4D4D4;
}
#footer .footer-copywrite p {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
}
#footer .footer-copywrite a {
  font-size: 12px;
  font-weight: 400;
  color: #D4D4D4;
}
#footer .footer-copywrite a:hover {
  color: #696969;
}
#footer .footer-copywrite span {
  padding: 0 2px;
}
#footer .footer-invitation h5 {
  margin: 0;
  font-size: 12px;
  padding-bottom: 5px;
  font-weight: 600;
  font-family: 'Open Sans', Arial, sans-serif;
  text-transform: uppercase;
}
#footer .footer-invitation p {
  font-size: 12px;
  font-weight: 400;
  color: #D4D4D4;
  margin-bottom: 10px;
}
#footer .footer-invitation input {
  height: 30px;
  padding: 5px 10px;
  border-radius: 0;
  color: #000;
  border: 1px solid #000;
  font-size: 13px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  font-weight: normal!important;
}
#footer .footer-invitation input:hover {
  border: 1px solid #696969;
}
#footer .footer-invitation button {
  width: 100%;
  height: 31px;
  border: none;
  background-color: #525252;
  color: #8E8E8E;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  font-weight: normal!important;
}
#footer .footer-invitation button:hover {
  background-color: #696969;
}
#footer .footer-invitation form {
  margin-bottom: 15px;
}
#footer .footer-socialmedia i {
  font-size: 25px;
}
#footer .footer-socialmedia h5 {
  margin: 0;
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  padding-bottom: 10px;
  text-transform: uppercase;
}
#footer .footer-socialmedia a {
  color: #D4D4D4;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  padding-right: 2px;
}
#footer .footer-socialmedia a:hover {
  color: #696969;
}
#footer .footer-socialmedia a:first-child {
  padding-right: 5px;
}
#footer .footer-socialmedia {
  margin-bottom: 10px;
  text-align: center;
}
.opiniaoImg {
  position: relative;
  height: 140px;
  display: block;
}
.opiniaoImg img {
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -o-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  width: 40%;
}
#sub-footer {
  background-color: #F1F0F0;
  width: 100%;
}
#sub-footer h6 {
  margin: 0;
  font-size: 12px;
  padding: 11px 0;
  font-family: 'Open Sans', Arial, sans-serif;
  font-weight: 300;
}
#sub-footer ul {
  float: right;
  margin: 0;
}
#sub-footer ul li {
  float: left;
  list-style-type: none;
}
#sub-footer ul li:first-child a {
  padding-left: 0;
}
#sub-footer ul li:last-child a {
  padding-right: 0;
}
#sub-footer ul li:last-child a:after {
  display: none;
}
#sub-footer ul li a {
  color: #8E8E8E;
  font-size: 12px;
  padding: 9px 10px;
  display: block;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  font-weight: normal!important;
}
#sub-footer ul li a:after {
  content: '';
  position: absolute;
  height: 12px;
  width: 1px;
  background-color: #8E8E8E;
  top: 11px;
  margin-left: 10px;
}
#sub-footer ul li a:hover {
  color: #000;
}
.mobile-show .opiniaoImg {display: none;}
/* ==========================================================================
   CATALOGO
   ========================================================================== */
.adsWrap { float:left; margin-bottom:20px; }
.cat-title { position:relative; }
.cat-title h2 { padding-right:300px; }
.is-catalogo .cat-title h2 { padding-bottom:17px; border-bottom:1px solid #f1f1f1; }
.is-meucatalogo #content .inner-content-title .cat-title h2 { padding-bottom:15px; }
.cat-title-btns { position:absolute; top:0; right:0; }
.cat-title-btns a { float:right; padding:0 10px; margin:0 10px 0 0; height:32px; line-height:32px; font-size:12px; -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out;
-ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; }
.cat-title-btns a:focus { color:#fff; }
.cat-title-btns a:hover { background:#000; color:#fff;  }
.cat-title-btns a i { margin:0;  }
.cat-title .cat-title-btns a.zapmobile { background-color:#25d366 !important; color:#fff !important; font-size:22px; }
.fb-share { font-size:12px; }
.more-info-btn { background-color:#737373; color:#fff; }
.more-info-btn .more-info-span { display:none; }
.more-info-btn .less-info-span { display:block; }
.more-info-btn.active .more-info-span { display:block; }
.more-info-btn.active .less-info-span { display:none; }

#formlances { display:none; }

.is-filtro .grid-options { padding:5px 15px; border:1px solid #ddd; float:left; color:#737373; width:auto;  -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out;}
.is-filtro .grid-options.active { color:#fff; background-color:#737373; border-color:#737373; }
.is-filtro select { border-radius:0; border:1px solid #ddd; margin:0; height:32px; -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out;}

.catalogo-fav-watch { position:absolute; top:0; right:15px; margin-top:0;  }
.catalogo-fav-watch a { font-size:18px; color:#737373; float:left; width:initial !important; font-weight:normal !important; cursor:pointer !important;}
.catalogo-fav-watch a i { line-height:32px; padding:0; }
.catalogo-fav-watch a.ativado { color:#000; }
.catalogo-fav-watch a:hover { color:#000; }
.is-catalogo-lado .status-prod { margin:0; }
.is-catalogo-lado #content .inner-content-container .status-prod p { float:left; margin-bottom:0;  }
.is-catalogo-lado .prod-box:hover  .status-prod { margin:0; }
.is-catalogo-lado .catalogo-fav-watch { position:relative; right:0; }
.is-catalogo-lado .catalogo-fav-watch a { border:none; font-size:22px; }
.is-catalogo-lado .catalogo-fav-watch a i { padding:0; }
.is-catalogo-lado .catalogo-fav-watch a span { display:none; }
.is-catalogo-lado .catalogo-fav-watch a.ativo  { background-color:#737373; border-color:#737373; color:#fff; }

.status-prod { position:relative; }

.tool-top { margin:0 0 15px 0; }
.is-irpara button { width:100%; height:32px; border:1px solid #ddd; background-color:#fff; -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;}
.is-irpara button:hover { background-color:#525252; color:#fff; }
.is-irpara input { border-radius:0; border:1px solid #ddd; margin:0; -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;}

.Botao { width:100%; float:left; background-color:#737373 ; color:#fff; text-align:center; line-height:32px; font-size:12px; border:1px solid #737373 ;}
.Botao.nenhum-filtro { opacity:0.2 !important; }



#content .inner-content-title .more-info a {
  float: right;
  background-color: #525252;
  padding: 5px 10px;
  text-align: center;
  color: white;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .inner-content-title .cat-title .cat-title-btns .fb-share { background-color:#4b66a0!important; color:#fff; font-size:16px; }
#content .inner-content-title .cat-title .cat-title-btns .fb-share:hover { background-color: #557BCA!important;}
#content .inner-content-title .more-info a:hover {
  background-color: #BDBDBD;
}
#content .inner-content-title .more-info .more-info-btn.active .more-info-span {
  display: block;
}
#content .inner-content-title .more-info .more-info-btn.active .less-info-span {
  display: none;
}
#content .inner-content-title .more-info .more-info-btn .more-info-span {
  display: none;
}
#content .inner-content-title .more-info .more-info-btn .less-info-span {
  display: block;
}
#content .inner-content-title .hidden-info {
  overflow: hidden;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .inner-content-title .hidden-info h4 {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  font-family: 'Open Sans', Arial, sans-serif;
  padding-bottom: 5px;
  color: #000;
}
#content .inner-content-title .hidden-info p {
  margin-bottom: 10px;
  font-size: 13px;
  color: #8E8E8E;
}
#content .inner-content-title .hidden-info .warning {
  color: red!important;
}
#content .inner-content-title .hidden-info .important-info {
  position: relative;
  margin-top: 10px;
}
#content .inner-content-title .hidden-info .important-info h5 {
  margin: 0;
  font-weight: 600;
  font-size: 15px;
  font-family: 'Open Sans', Arial, sans-serif;
  padding-bottom: 10px;
}
#content .inner-content-title .hidden-info {
  max-height: 0;
  min-height:0;
}
#content .inner-content-title .hidden-info.open {
  margin-top: 20px;
  max-height: 300px;
}
#content .inner-content-container .catalog {

}
#content .inner-content-container .catalog .top-level-ui {
  float: left;
  width: 100%;
  margin-bottom: 15px;
}
#content .inner-content-container .catalog .top-level-ui .view-options a {
  height: 32px;
  background-color: #525252;
  padding: 5px 15px;
  margin-right: 3%;
  text-align: center;
  color: white;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  float: left;
  font-size: 13px;
}
#content .inner-content-container .catalog .top-level-ui .view-options a:hover {
  background-color: #BDBDBD;
}
#content .inner-content-container .catalog .top-level-ui .view-options i {
  padding-right: 5px;
}
#content .inner-content-container .catalog .top-level-ui .view-options .fb-share {
  background-color: #4b66a0;
  margin-right: 0;
}
#content .inner-content-container .catalog .top-level-ui .view-options .filter-options,
#content .inner-content-container .catalog .top-level-ui .view-options .fb-share {
  width: 30%;
}
#content .inner-content-container .catalog .top-level-ui .view-options .grid-options,
#content .inner-content-container .catalog .top-level-ui .view-options .lote-options {
  width: 32%;
}
#content .inner-content-container .catalog .top-level-ui .view-options .filter-options.active .fa-plus {
  display: none;
}
#content .inner-content-container .catalog .top-level-ui .view-options .filter-options.active .fa-minus {
  display: inline-block;
}
#content .inner-content-container .catalog .top-level-ui .view-options .filter-options .fa-plus {
  display: inline-block;
}
#content .inner-content-container .catalog .top-level-ui .view-options .filter-options .fa-minus {
  display: none;
}
#content .inner-content-container .catalog .top-level-ui .search-container {
  position: relative;
}
#content .inner-content-container .catalog .top-level-ui .search-container input {
  margin-bottom: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 5px;
  border: 1px solid #ddd;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  font-size: 13px;
  color: #8E8E8E;
}
#content .inner-content-container .catalog .top-level-ui .search-container input:hover,
#content .inner-content-container .catalog .top-level-ui .search-container input:focus {
  border: 1px solid #A7A7A7;
  outline: 0;
}
#content .inner-content-container .catalog .top-level-ui .search-container label {
  padding-top: 8px;
  margin-bottom: 0;
  text-align: right;
}
#content .inner-content-container .catalog .top-level-ui .search-container .input-spacement {
  padding-right: 45px;
}
#content .inner-content-container .catalog .top-level-ui .search-container .search-btn {
  position: absolute;
  right: 0;
  height: 32px;
  background-color: #BDBDBD;
  border: 1px solid #000;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  padding:0 10px;
}

#content .inner-content-container .catalog .top-level-ui .search-container .search-btn:hover {
  border: 1px solid #B3B3B3;
  background-color: #fff;
}
#content .inner-content-container .catalog .top-level-ui .search-container .search-btn:hover i {
  color: #BDBDBD;
}
#content .inner-content-container .catalog .top-level-ui .search-container .search-btn i {
  color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .inner-content-container .catalog .results-pagination {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}
#content .inner-content-container .catalog .results-pagination .found {
  float: left;
  line-height: 32px;
}
#content .inner-content-container .catalog .results-pagination .results p {
  line-height: 32px;
}
#content .inner-content-container .catalog .results-pagination .pagination {
  float: right;
}
#content .inner-content-container .catalog .results-pagination .pagination .nextarrow {
  margin: 0;
}
#content .inner-content-container .catalog .results-pagination .pagination p {
  display: inline;
  margin: 0 15px 0 0;
  line-height: 32px;
}
#content .inner-content-container .catalog .results-pagination .pagination ul {
  float: right;
  list-style: none;
  padding: 0;
  margin: 0;
}
#content .inner-content-container .catalog .results-pagination .pagination ul li {
  float: left;
  margin: 0 10px 0 0;
  border: 1px solid #F1F1F1;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .inner-content-container .catalog .results-pagination .pagination ul li span {
  color: #f1f1f1;
  float: left;
  padding: 5px 10px;
  line-height: 20px ;
}
#content .inner-content-container .catalog .results-pagination .pagination ul li span i {
  line-height: 20px;
}
#content .inner-content-container .catalog .results-pagination .pagination ul li a {
  color: #2564c2;
  float: left;
  padding: 5px 10px;
  line-height: 20px !important;
}
#content .inner-content-container .catalog .results-pagination .pagination ul li a i {
  line-height: 20px;
}
#content .inner-content-container .catalog .results-pagination .pagination ul li:hover {
  background-color: #f1f1f1;
}
#content .inner-content-container .catalog .results-pagination .pagination ul li:hover a {
  color: #000;
}
#content .inner-content-container .catalog .results-pagination .pagination ul li:hover i {
  color: #fff;
}
#content .inner-content-container .catalog .results-pagination .pagination ul li:last-child {
  margin-right: 0;
}
#content .inner-content-container .catalog .results-pagination .pagination ul li:last-child:hover i {
  color: #000;
}
#content .inner-content-container .catalog .results-pagination .pagination ul li.fixedpag span {
  color: #f1f1f1;
  float: left;
  padding: 5px 10px;
  line-height: 20px;
}
#content .inner-content-container .catalog .results-pagination .pagination ul li.fixedpag:hover span {
  color: #fff;
}
#content .inner-content-container .catalog .results-pagination .pagination ul li.dotdot {
  border: none;
}
#content .inner-content-container .catalog .results-pagination .pagination ul li.dotdot:hover {
  background: transparent;
}
#content .inner-content-container .catalog .results-pagination .pagination ul li.dotdot span {
  line-height: 20px;
}
#content .inner-content-container .catalog .results-pagination .pagination ul li.ativo span {
  font-weight: bold;
  background: #f1f1f1;
  color: #000;
}
#content .inner-content-container .catalog .new-tools {
  margin-bottom: 15px;
}
#content .inner-content-container .catalog .new-tools:last-child {
  margin-top: 20px;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui {
  float: left;
  border: 1px solid #DDDDDD;
  padding: 10px;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui .filter {
  padding-left: 0;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui .filter a {
  width: 100%;
  height: 32px;
  background-color: #525252;
  padding: 5px 15px;
  text-align: center;
  color: white;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  float: left;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui .filter a:hover {
  background-color: #BDBDBD;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui .go-to,
#content .inner-content-container .catalog .new-tools .mid-level-ui .filterby,
#content .inner-content-container .catalog .new-tools .mid-level-ui .numberfound {
  padding-left: 0;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui .go-to .go-to-label-container,
#content .inner-content-container .catalog .new-tools .mid-level-ui .filterby .go-to-label-container,
#content .inner-content-container .catalog .new-tools .mid-level-ui .numberfound .go-to-label-container {
  display: table;
  float: left;
  height: 32px;
  margin-right: 10px;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui .go-to input,
#content .inner-content-container .catalog .new-tools .mid-level-ui .filterby input,
#content .inner-content-container .catalog .new-tools .mid-level-ui .numberfound input,
#content .inner-content-container .catalog .new-tools .mid-level-ui .go-to button,
#content .inner-content-container .catalog .new-tools .mid-level-ui .filterby button,
#content .inner-content-container .catalog .new-tools .mid-level-ui .numberfound button,
#content .inner-content-container .catalog .new-tools .mid-level-ui .go-to select,
#content .inner-content-container .catalog .new-tools .mid-level-ui .filterby select,
#content .inner-content-container .catalog .new-tools .mid-level-ui .numberfound select {
  float: left;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui .go-to label,
#content .inner-content-container .catalog .new-tools .mid-level-ui .filterby label,
#content .inner-content-container .catalog .new-tools .mid-level-ui .numberfound label {
  margin-right: 10px;
  font-size: 12px;
  display: table-cell;
  vertical-align: middle;
  margin-bottom: 0;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui .go-to input,
#content .inner-content-container .catalog .new-tools .mid-level-ui .filterby input,
#content .inner-content-container .catalog .new-tools .mid-level-ui .numberfound input {
  width: 44%;
  margin-right: 10px;
  margin-bottom: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 5px;
  border: 1px solid #ddd;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  font-size: 13px;
  color: #8E8E8E;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui .go-to input:hover,
#content .inner-content-container .catalog .new-tools .mid-level-ui .filterby input:hover,
#content .inner-content-container .catalog .new-tools .mid-level-ui .numberfound input:hover,
#content .inner-content-container .catalog .new-tools .mid-level-ui .go-to input:focus,
#content .inner-content-container .catalog .new-tools .mid-level-ui .filterby input:focus,
#content .inner-content-container .catalog .new-tools .mid-level-ui .numberfound input:focus {
  border: 1px solid #A7A7A7;
  outline: 0;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui .go-to select,
#content .inner-content-container .catalog .new-tools .mid-level-ui .filterby select,
#content .inner-content-container .catalog .new-tools .mid-level-ui .numberfound select {
  border-radius: 0;
  box-shadow: none;
  padding: 5px;
  border: 1px solid #ddd;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  font-size: 13px;
  color: #8E8E8E;
  width: 40%;
  margin-right: 10px;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui .go-to select:hover,
#content .inner-content-container .catalog .new-tools .mid-level-ui .filterby select:hover,
#content .inner-content-container .catalog .new-tools .mid-level-ui .numberfound select:hover,
#content .inner-content-container .catalog .new-tools .mid-level-ui .go-to select:focus,
#content .inner-content-container .catalog .new-tools .mid-level-ui .filterby select:focus,
#content .inner-content-container .catalog .new-tools .mid-level-ui .numberfound select:focus {
  border: 1px solid #A7A7A7;
  outline: 0;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui .go-to button,
#content .inner-content-container .catalog .new-tools .mid-level-ui .filterby button,
#content .inner-content-container .catalog .new-tools .mid-level-ui .numberfound button {
  height: 32px;
  background-color: #BDBDBD;
  border: 1px solid #000;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui .go-to button i,
#content .inner-content-container .catalog .new-tools .mid-level-ui .filterby button i,
#content .inner-content-container .catalog .new-tools .mid-level-ui .numberfound button i {
  color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui .go-to button:hover,
#content .inner-content-container .catalog .new-tools .mid-level-ui .filterby button:hover,
#content .inner-content-container .catalog .new-tools .mid-level-ui .numberfound button:hover {
  border: 1px solid #B3B3B3;
  background-color: #fff;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui .go-to button:hover i,
#content .inner-content-container .catalog .new-tools .mid-level-ui .filterby button:hover i,
#content .inner-content-container .catalog .new-tools .mid-level-ui .numberfound button:hover i {
  color: #BDBDBD;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui .numberfound {
  width: 20.66667%;
  padding-right: 0;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui .numberfound .go-to-label-container {
  margin-right: 0;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui .pagination {
  width: 12.66667%;
  padding-right: 0;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui .pagination ul {
  margin: 0;
  list-style: none;
  float: right;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui .pagination li {
  display: table;
  float: left;
  height: 32px;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui .pagination li a {
  display: table-cell;
  vertical-align: middle;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui .pagination li a i {
  font-size: 30px;
  color: #BDBDBD;
}
#content .inner-content-container .catalog .new-tools .mid-level-ui .pagination li:first-child {
  margin-right: 10px;
}
#content .inner-content-container .catalog .sidebar-tools.open {
  display: block;
}
#content .inner-content-container .catalog .sidebar-tools {
  display: none;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container {
  float: left;
  margin-bottom: 20px;
  width:100%;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-top a {
  background-color: #737373 ;
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  line-height: 42px;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-top a:hover {
  background-color: #BDBDBD;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-top a i {
  font-size: 18px;
  padding-right: 15px;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box {
  float: left;
  width: 100%;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-title {
  background-color: #BDBDBD;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-title h5,
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-title a {
  margin: 0;
  font-size: 13px;
  font-family: 'Open Sans', Arial, sans-serif;
  font-weight: 600;
  padding: 0 0 0 15px;
  line-height: 32px;
  color: #fff;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-title h5 i,
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-title a i {
  padding-right: 16px;
  font-size: 15px;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-title .custom-padding {
  padding-right: 20px;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content.lots li {
  padding-right: 15px;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content.lots label {
  line-height: 32px;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content.lots input {
  width: 50%;
  margin-right: 11px;
  margin-left: 10px;
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: none;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content.lots button {
  height: 32px;
  background-color: #BDBDBD;
  border: 1px solid #000;
  width: 25px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content.lots button:hover {
  background-color: #fff;
  border: 1px solid #BDBDBD;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content.lots button:hover i {
  color: #BDBDBD;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content.lots button i {
  padding-right: 0;
  color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content.lots input,
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content.lots select {
  color: #8E8E8E;
  border: 1px solid #ddd;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content.lots input:focus,
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content.lots select:focus {
  border: 1px solid #A7A7A7;
  outline: 0;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content.lots input:hover,
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content.lots select:hover {
  border: 1px solid #A7A7A7;
  outline: 0;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content.lots select {
  height: 32px;
  width: 51%;
  margin-left: 12px;
  margin-top: 0;
  margin-bottom: 0;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content ul {
  margin: 0;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content input {
  float: left;
  margin-top: 5px;
  margin-right: 23px;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content label {
  margin: 0;
  display: inline-block;
  font-size: 13px;
  float: left;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content li {
  list-style-type: none;
  float: left;
  width: 100%;
  padding: 0 0 5px 15px;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content li:first-child {
  padding-top: 8px;
  padding-bottom: 8px;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content li:last-child {
  padding-bottom: 8px;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content i {
  padding-right: 21px;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content a {
  color: #8E8E8E;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content p {
  margin-bottom: 10px;
  padding: 0 15px;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content p:first-child {
  margin-top: 8px;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content .newsletter input {
  margin: 0;
  border-radius: 0;
  border: 1px solid #ddd;
  box-shadow: none;
  padding: 5px;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content .newsletter input:hover {
  border: 1px solid #A7A7A7;
  outline: 0;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content .newsletter input:focus {
  border: 1px solid #A7A7A7;
  outline: 0;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content .newsletter li {
  padding: 0 15px 8px 15px;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content .newsletter li:first-child {
  margin-top: 8px;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content .newsletter label {
  color: #8E8E8E;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content .newsletter button {
  width: 100%;
  border: 0;
  height: 32px;
  background-color: #525252;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  color: #fff;
}
#content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content .newsletter button:hover {
  background-color: #BDBDBD;
}
#content .inner-content-container .catalog .sidebar-tools .media-container {
  float: left;
  width: 100%;
  border: 1px solid #f1f1f1;
  padding:0 10px 10px 10px;
}
#content .inner-content-container .catalog .sidebar-tools .media-container .powered-by a {
  display: block;
}

#content .inner-content-container .catalog .sidebar-tools .media-container .social-media a {
  background-color: #17a2b8;
  height: 32px;
  width:100%;
  max-width:40px;
  margin:0 auto;
  display: table;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  text-align:center;
}
#content .inner-content-container .catalog .sidebar-tools .media-container .social-media .i {
  font-size: 20px;
  display: table-cell;
  vertical-align: middle;
  color: #fff;
  margin-top: 5px;
  margin-left: 10px;
}
#content .inner-content-container .catalog .sidebar-tools .media-container .social-media ul {
  margin-bottom: 0;
}
#content .inner-content-container .catalog .sidebar-tools .media-container .social-media ul li {
  width: 20%;
  float: left;
  padding-right: 2%;
}
#content .inner-content-container .catalog .sidebar-tools .media-container .social-media ul li:first-child a:hover {
  background-color: #4b66a0;
}
#content .inner-content-container .catalog .sidebar-tools .media-container .social-media ul li:nth-child(2) a:hover {
  background-color: #2caae1;
}
#content .inner-content-container .catalog .sidebar-tools .media-container .social-media ul li:nth-child(3) a:hover {
  background-color: #d73d32;
}
#content .inner-content-container .catalog .sidebar-tools .media-container .social-media ul li:nth-child(4) a:hover {
  background-color: #8953c5;
}
#content .inner-content-container .catalog .sidebar-tools .media-container .social-media ul li:last-child a:hover {
  background-color: #757575;
}
#content .inner-content-container .catalog .sidebar-tools .media-container .social-media ul li:last-child {
  padding-right:0;
}

#content .inner-content-container .catalog .catalog-content.full-width {
  width: 100%;
}
#content .inner-content-container .catalog .catalog-content {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  float: right;
}
#content .inner-content-container .catalog .catalog-content .results .list li {
  list-style: none;
  padding: 20px 0;
}
#content .inner-content-container .catalog .catalog-content .results .list li:first-child {
  padding-top: 0;
}
#content .inner-content-container .catalog .catalog-content .results .list li:nth-child(even) {
  border-top: 1px solid #F1F1F1;
  border-bottom: 1px solid #F1F1F1;
}
#content .inner-content-container .catalog .catalog-content .results .list li:last-child {
  border-bottom:none;
}
.is-catalogo-lado #content .inner-content-container .catalog .catalog-content .results .list li {
  padding:0 15px; border:none; min-height: 440px; margin-bottom:15px;
}

#content .inner-content-container .catalog .catalog-content .results .list .product-content-container {
  padding-left: 0;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-image {
  height: 230px;
  position: relative;
  border: 1px solid #DDDDDD;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-image:hover {
  border: 1px solid #525252;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-image a {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-image a img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .top-line {
  margin-bottom: 10px;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .top-line a:hover span {
  background-color: #fff;
  border: 1px solid #B3B3B3;
  color: #000;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .top-line span {
  font-size: 12px;
  font-weight: bold;
  background-color: #737373;
  border:1px solid #737373;
  padding: 5px 10px;
  color: #fff;
  display: inline-block;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .top-line .temvideo { border:none; padding:0 10px; background-color:transparent; }
#content .inner-content-container .catalog .catalog-content .results .list .product-content .top-line .temvideo i, .status-prod .temvideo i { font-size:27px; color:red; }

#content .inner-content-container .product-price-bid {
  float: left;
}
#content .inner-content-container .product-price-bid-new { margin-top:5px;  }
.is-meucatalogo #content .inner-content-container .product-price-bid-new { margin-top:0px; }
#content .inner-content-container .product-price-bid-new p { font-size:11px; }
#content .inner-content-container .product-price-bid p {
  text-align: left;
  line-height: 10px;
  margin:0;
  font-weight:bold;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .top-line .product-price-bid p:first-child {
  margin-bottom: 0px;
}
#content .inner-content-container .product-price-bid .price-bid {
  font-size: 24px;
  color:#2564c2;
  font-weight:bold; margin-top:5px; line-height:22px;
}
#content .inner-content-container .product-price-bid.price-cotacao .price-bid {
  font-size: 12px;
  color:#9c9c9c;
  font-weight:bold; margin-top:0; line-height:20px;
}
.price-cotacao-toolbox { display:table; height:50px; }
.product-price-bid.price-cotacao { border:1px solid #eaeaea; margin-bottom:5px; } 
.product-price-bid.price-cotacao .is-rs { background-color:#eaeaea; padding:1px 5px; color:#737373; font-weight:bold; } 
.product-price-bid.price-cotacao .is-cotacao-valor { padding:1px 5px; float:left; } 

.product-price-bid.price-cotacao-dollar { margin-right:15px; } 

.price-cotacao-wrap { margin-top:20px; display:table-cell; vertical-align:bottom;  } 
.is-peca .price-cotacao { }
.is-peca .product-price-bid.price-cotacao-dollar { }
.is-peca #content .inner-content-container .product-price-bid.price-cotacao .price-bid { font-size:14px; }
.is-peca #content .inner-catalog .right-side .peca-info .preco-bid ul li p { float:none; }
.pcw-wrap { }
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-description h3 {
  margin: 0;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-description h3 a {
  color: #737373;
  display: block;
  max-height: 147px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  line-height:23px;
  font-size: 18px;
  line-height:25px;
  font-family:"Open Sans", arial, verdana;
  font-weight:normal;
  margin-bottom:5px;
  overflow:hidden;
}
.is-meucatalogo #content .inner-content-container .catalog .catalog-content .results .list .product-content .product-description h3 a{
min-height:48px; max-height:48px; margin-bottom:10px;
}
.is-removerauto { position:absolute; top:5px; right:10px; font-size:26px; }
.is-removerauto a { color:#737373; }
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-description h3 a:hover {
  text-decoration:underline;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-description a span {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-description a span:hover {
  border: 1px solid #B3B3B3;
  background-color: #fff;
  color: #B3B3B3;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .price-bid-custom-padding .product-price-bid {
  float: left;
  width: auto;
  padding-bottom: 0;
  font-style:italic;
}
.is-meucatalogo .price-bid-custom-padding .product-price-bid { margin-bottom:10px; padding:0 !important; }
#content .inner-content-container .catalog .catalog-content .results .list .product-content .price-bid-custom-padding .product-price-bid p {
  text-align: right;
  line-height: normal;
  color:#a0a0a0;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .price-bid-custom-padding .product-price-bid p:first-child {
  margin-bottom: 3px;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .price-bid-custom-padding .product-price-bid .price-bid {
  font-size: 18px;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .price-bid-custom-padding .visits {
  width: auto;
  float: left;
  margin-right:10px; padding-right:10px; border-right:1px solid #ddd; font-style:italic;
}
.is-meucatalogo .PrecoProd { float:left; margin-right:10px;  }
.is-meucatalogo .PrecoProd.lastprod{ padding-left:10px; border-left:1px solid #f1f1f1;  }
.is-meucatalogo .PrecoProd span { width:100%; float:right; text-align:left; margin-top:2px; font-size:16px; font-weight:normal; color:#8e8e8e; }
.is-meucatalogo .PrecoProd .is-Bolder {    text-align: left;
  line-height: 10px;
  margin: 0;
  font-weight: bold;
  font-size:11px;
}
.vencendo-prod p { color:green !important; }
.vencendo-prod p span { color:green !important; }
.perdendo-prod p { color:#800000 !important;  }
.perdendo-prod p span { color:#800000 !important; }
.is-lances { margin-bottom:10px; }
.is-lances .visits { float:right; position:relative; padding:2px 10px; background:#f1f1f1; }
.is-lances .visits p { text-align:right; padding-right:30px; margin:0 !important; }
.is-lances .visits p span.is-Bolder { font-size:10px; font-weight:bold;}

#content .inner-content-container .catalog .catalog-content .results .list .product-content .price-bid-custom-padding .visits p {
  line-height: normal;
  margin: 0;
  text-align: right;
  color:#a0a0a0;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .tools .fav-watch-btns .fav {
  width: auto;
  margin-right: 0;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .tools .fav-watch-btns .fav:hover, .fav-watch-btns .fav:hover { border-color: #ECBD08; background-color: #fff; color: #000;}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .tools .fav-watch-btns .fav:hover i, .fav-watch-btns .fav:hover i { color: #989898; text-shadow: 1px 1px 0 #FFFFFF, 2px 3px 0 rgba(0, 0, 0, 0.15);}

#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .tools .fav-watch-btns .watch:hover, .catalogo-fav-watch .watch:hover  { border-color: #2594DC; background-color: #fff; color: #000;}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .tools .fav-watch-btns .watch:hover i, .catalogo-fav-watch .watch:hover i { color: #989898; text-shadow: 1px 1px 0 #FFFFFF, 2px 3px 0 rgba(0, 0, 0, 0.15); }

#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .tools .fav-watch-btns .fav.ativo, .fav-watch-btns .fav.ativo {
  border-color: #f1f1f1; background-color: transparent; color: #000;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .tools .fav-watch-btns .fav.ativo i, .fav-watch-btns .fav.ativo i {
  color: #ECBD08; text-shadow: 1px 1px 0 #FFFFFF, 2px 3px 0 rgba(0, 0, 0, 0.15);
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .tools .fav-watch-btns .watch {
  width: 40%;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .tools .fav-watch-btns .watch.ativo, .fav-watch-btns .watch.ativo {
  border-color: #f1f1f1; background-color: transparent; color: #000;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .tools .fav-watch-btns .watch.ativo i, .fav-watch-btns .watch.ativo i {
  color: #2594DC; text-shadow: 1px 1px 0 #FFFFFF, 2px 3px 0 rgba(0, 0, 0, 0.15);
}
.is-catalogo-lado .catalogo-fav-watch .fav.ativo { border-color: #ECBD08; background-color: #fff; color: #000;}
.is-catalogo-lado .catalogo-fav-watch .fav.ativo i { color: #ECBD08; text-shadow: 1px 1px 0 #FFFFFF, 2px 3px 0 rgba(0, 0, 0, 0.15);}

.is-catalogo-lado .catalogo-fav-watch .watch.ativo { border-color: #2594DC; background-color: #fff; color: #000;}
.is-catalogo-lado .catalogo-fav-watch .watch.ativo i { color: #2594DC; text-shadow: 1px 1px 0 #FFFFFF, 2px 3px 0 rgba(0, 0, 0, 0.15); }

#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .tools .fav-watch-btns a {
  height: 36px;
  background-color: #fff;
  padding: 5px 0;
  margin-right: 10px;
  text-align: center;
  color: #737373;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  float: left;
  font-size: 10px;
  display: table;
  border:none;
  font-weight:normal;
  cursor:pointer;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .tools .fav-watch-btns a:hover {
  background-color: #737373;
  border-color:#737373;
  color:#fff;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .tools .fav-watch-btns a.ativo {
  background-color: #737373 ;
  border-color:#737373 ;
  color:#fff;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .tools .fav-watch-btns a:last-child {
  margin-right: 0;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .tools .fav-watch-btns .icon {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  padding-left: 5px;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .tools .fav-watch-btns i {
  font-size: 20px;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .tools .fav-watch-btns span,
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .tools .fav-watch-btns .icon {
  display: table-cell;
  vertical-align: middle;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .tools .visits {
  float: left;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .tools .visits p {
  line-height: normal;
  margin: 0;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .lote-status {
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .lote-status p {
  font-size: 15px;
  text-align: right;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .lote-status a {
  height: 36px;
  background-color: #737373;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  color: white;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  font-size: 12px;
}
.vermais-btn.is-vencedor a { background-color:green !important; }
.vermais-btn.is-coberto a { background-color:#800000 !important; opacity:1 !important; }
.is-lowerbtns .is-vencedor a { background-color:green !important; }
.is-lowerbtns .is-coberto a { background-color:#800000 !important; }
.is-lowerbtns .watchcs { padding:7px 0; }
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .lote-status a:hover {
  background-color: #BDBDBD;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .lote-control {
  width: auto;
  display: table;
}
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .is-CoolBtn {
}
.fav-watch-btns a { float:right; font-size:26px; line-height:25px; }
.fav-watch-btns a i { color: #ddd; text-shadow: 1px 1px 0 #FFFFFF, 2px 3px 0 rgba(0, 0, 0, 0.15); transition: all 0.5s ease; }
.fav-watch-btns .fav { margin-right:15px; }
.lote-status { float:right; }
.is-meucatalogo .lote-status { float:left; margin-top:20px; }
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .is-CoolBtn a {
  padding:0 25px; font-size:13px; font-weight:bold;
}
.lotevendido { font-size:22px; text-align:left; color:#737373; font-weight:bold; font-style:italic; }
.is-catalogo .lotevendido { text-align:right; }
.is-catalogo-lado .lotevendido { font-size:20px; line-height:45px; }
.is-pesquisa .lotevendido { font-size:20px; line-height:45px; }

#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .lote-status .is-coberto a { background-color:#800000 !important; }
#content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .lote-status .is-vencedor a { background-color:green !important; }

/*** gar ******/
#content .inner-catalog .right-side .peca-info .total-arrows {  border-top:none; border-left:none; border-right:none; padding:0 0 10px 0; }
#content .inner-catalog .right-side .peca-info .total-arrows .total { width:auto; float:right; }
#content .inner-catalog .right-side .peca-info .total-arrows .gotoLote { width:auto; float:left; position:relative; }
.userInteract a {  width:30px; height:30px; display:block; float:right; margin-left:15px; }
.userInteract a i { font-size:26px;}
.userInteract a.fav i, .userInteract a.watch i { color: #ddd; text-shadow: 1px 1px 0 #FFFFFF, 2px 3px 0 rgba(0, 0, 0, 0.15); transition:all 0.5s ease;  } 
.userInteract a.fav.ativo i { color: #ECBD08; text-shadow: 1px 1px 0 #FFFFFF, 2px 3px 0 rgba(0, 0, 0, 0.15);  } 
.userInteract a.watch.ativo i {color: #2594DC;text-shadow: 1px 1px 0 #FFFFFF, 2px 2px 0 rgba(0, 0, 0, 0.15); } 
#content .inner-content-container .lote-desc p { font-size:18px; line-height:25px; }
#content .inner-content-container .lote-desc a.aovivo { font-size:13px; width:auto; color:red; line-height:28px; position:relative; padding:0 25px 0 5px; border:1px solid red; text-transform:uppercase; display:inline-block; margin:0; } 
.lote-desc a.aovivo i { font-size:20px; position:Absolute; top:4px; right:5px; } 
.lote-desc ul { list-style:none; margin:0; }
.lote-desc ul li { display:block; width:100%; margin-bottom:2px; }
#content .inner-content-container .lote-desc ul li p { font-size:13px; font-weight:bold;  }
#content .inner-content-container .lote-desc ul li p a { color:#2564c2; margin-bottom:0;  }
#content .inner-content-container .lote-desc ul label { display:inline-block; font-weight:bold; font-size:11px; margin-right:5px; color:#737373; text-transform:uppercase; line-height:22px; margin-bottom:0; }
#content .inner-content-container .lote-desc ul label i { font-size:16px; line-height:20px; float:left; margin-right:5px; color:#b5b3b3; }
#content .inner-content-container .preco-bid ul li p { font-size:12px; font-weight:bold; margin:0;  }
#content .inner-content-container .preco-bid ul li p a { color:#2564c2; }
#content .inner-content-container .preco-bid ul label { display:inline-block; font-weight:bold; font-size:14px; margin-right:5px; color:#737373;  }
#content .inner-content-container .preco-bid ul label i { font-size:14px;  }
.section-title { padding-left:10px; position:relative; margin-top:10px; }
.section-title h2 { font-weight:normal; color:#737373; font-size:12px;  font-family:"Open Sans"; text-transform:uppercase; letter-spacing:2px; margin:10px 0; }
.section-title h2 span { padding-right:10px; }
.section-title h2 span i { color:#bdbbbb; }
.section-title:after  {     content: " ";
    display: block;
    position: relative;
    top: -17px;
    width: 100%;
    border-top: 1px solid #ddd;
    z-index: -1; }
.is-rs { font-size:12px; display:block; float:left; font-weight:normal; margin-right:2px; }    
.is-valor { font-size:24px; display:block; float:left; line-height:24px; }
.row.spaced-box { margin-bottom:15px; }    
.ToolBox .section-title { padding-left:0; }

.video-wrap { padding-left:10px; }

.greenStatus .is-rs, .greenStatus .is-valor { color:#699E69;  }
.bidnumber .is-rs { line-height:40px; font-weight:bold; font-size:16px; }
.bidnumber .is-decimals { line-height:52px; color:#737373; background-color:#ddd; height:38px; display:block; padding:0 7px; width:auto; float:left; }
#lance { margin-top:2px; padding:10px 10px 20px 10px; background-color:#f1f1f1; width:100% !important; min-width:265px; }
.valor-atual label { line-height:16px; }
#lance p.is-observacao { font-style:italic; font-weight:normal; width:100%; margin-left:0px !important; text-align:center;  }
#content .inner-catalog .right-side .peca-info .preco-bid .automatic-bid { position:Relative; padding:5px 35px 5px 10px; background:#ddd; margin-left:0px; width:100%; }
#content .inner-catalog .right-side .peca-info .preco-bid .automatic-bid label { font-size:12px; margin:0; }
#content .inner-catalog .right-side .peca-info .preco-bid .automatic-bid label input { margin:2px 10px 0 0; }
#content .inner-catalog .right-side .peca-info .preco-bid .automatic-bid a { position:absolute; top:9px; right:10px; font-size:14px; }
#content .inner-catalog .right-side .peca-info .preco-bid .automatic-bid a i { font-size:18px; }
.is-valorinputwrap { float:left; width:100%; }
.bidnumber { width:100%; }
.bidnumber .lance-wrap { margin-left:0; }
.lance-btn { float:left; width:100%; padding:0; }
.lance-btn span { border-left:none; border-right:1px solid hsla(0, 0%, 100%, 0.21); }
#content .inner-catalog .right-side .peca-info .preco-bid .lance-btn.remove-auto-btn span { border-left:1px solid rgba(0, 0, 0, 0.16);  border-right:none; font-size:12px; padding:10px 0; }


.lance-btn span span { padding:0 !important; margin:0; }
.moreinfo { position:absolute; top:0; right:0; font-size:11px; color:#737373; }
.main-image { padding-top:15px; padding-bottom:15px; background-color:#f1f1f1; }
.thumb-wrap { display:table; float:left; width:100%; height:100%;  border:1px solid #fff; }
.active .thumb-wrap { border:1px solid #ddd; }
.new-social-btns { margin-bottom:10px; padding:0; }
.new-social-btns .section-title span { background-color:#f1f1f1; z-index:1; position:relative; }
.new-social-btns .section-title h2 { margin-top:0; text-align:center; }
.new-social-btns .section-title:after { content: " ";
    display: block;
    position: relative;
    top: -17px;
    width: 100%;
    border-top: 1px solid #ddd;
    z-index: 0; }
.new-social-btns .compartilhe-links { text-align:center; }
.hasTP { }
[data-info]:hover:before, [data-info]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}
[data-info]:before {
    content: attr(data-info);
    background-color: #757575;
    color: #fff;
    font-size: 13px;
    position: absolute;
    padding: 5px 10px;
    bottom: 110%;
    left: 0; 
    margin-left:-20px;
    white-space: pre;
    box-shadow: 1px 1px 1px #22222294;
    opacity: 0;
    border: 1px solid #8c8b8b;
    z-index: 99998;
    visibility: hidden;
}
[data-info]:after {
    top: 0;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
	border-top-color: #757575;
    border-width: 8px;
    margin-top:-5px;
    margin-left: -8px;
    visibility: hidden;
    z-index: 99999;
    opacity: 0;
}
[data-info] {
    position: relative;
}

.tippy-tooltip.facebook-theme{
    background-color: #4267b2;
    color: #FFFFFF;
}
.tippy-tooltip.facebook-theme .tippy-roundarrow {
    fill: #4267b2;
}
.tippy-tooltip.twitter-theme{
    background-color: #1da1f2;
    color: #FFFFFF;
}
.tippy-tooltip.twitter-theme .tippy-roundarrow {
    fill: #1da1f2;
}
.tippy-tooltip.zap-theme{
    background-color: #097700;
    color: #FFFFFF;
}
.tippy-tooltip.zap-theme .tippy-roundarrow {
    fill: #097700;
}
.tippy-tooltip.lanceauto-theme{
    background-color: #fff;
    border:1px solid #ddd;
    color: #757575;
    box-shadow:1px 1px 1px 0px rgba(0, 0, 0, 0.2);
}
.tippy-tooltip.lanceauto-theme p {
    font-size:12px;
    text-align:left;
    padding:0 10px;
}

.tippy-tooltip.lanceauto-theme h2 {
    font-size:16px;
    text-align:left;
    padding:0 10px;
    color:#737373;
}

.tippy-tooltip.lanceauto-theme .tippy-roundarrow {
    fill: #fff;
}



/* ==========================================================================
   contasite_meuslances
   ========================================================================== */
#detalheleilao .list .product-content .product-toolbox .lote-control {
  width:100%;
  display:table;
}
#detalheleilao .list .product-content .product-toolbox .lote-control a {
     height: 36px;
     background-color: #737373;
     display: table-cell;
     vertical-align: middle;
     text-align: center;
     color: white;
     transition: all 0.5s ease-in-out;
     -webkit-transition: all 0.5s ease-in-out;
     -moz-transition: all 0.5s ease-in-out;
     -ms-transition: all 0.5s ease-in-out;
     -o-transition: all 0.5s ease-in-out;
     font-size: 12px;
}
#detalheleilao .is-favoritolist .product-content .lote-control { width:100%; display:table; }
#detalheleilao .is-favoritolist .product-content .lote-control a {
     height: 36px;
     background-color: #737373;
     display: table-cell;
     vertical-align: middle;
     text-align: center;
     color: white;
     -webkit-transition: all 0.5s ease-in-out;
     -moz-transition: all 0.5s ease-in-out;
     -ms-transition: all 0.5s ease-in-out;
     -o-transition: all 0.5s ease-in-out;
     font-size: 12px;
     width:100%;
     transition-delay: 2s;
}

#detalheleilao .list .PrecoProd { float:left; margin-right:10px;  }
#detalheleilao .list .PrecoProd.lastprod{ padding-left:10px; border-left:1px solid #f1f1f1; transition: all 0.5s ease-in-out; transition-delay: 2s; }
#detalheleilao .list .PrecoProd span { width:100%; float:right; text-align:left; font-size:14px; font-weight:normal; color:#8e8e8e; }
#detalheleilao .list .PrecoProd .is-Bolder { text-align:left; font-size:10px; font-weight:bold; color:#8e8e8e; }
#content .inner-content-container .catalog .catalog-content {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  float: right;
}
#detalheleilao .list li {
  list-style: none;
  padding: 20px 0;
}
#detalheleilao .list li:first-child {
  padding-top: 0;
}
#detalheleilao .list li:nth-child(even) {
  border-top: 1px solid #F1F1F1;
  border-bottom: 1px solid #F1F1F1;
}

#detalheleilao .list .product-content-container {
  padding-left: 0;
}
#detalheleilao .list .product-image {
  height: 187px;
  position: relative;
  border: 1px solid #DDDDDD;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#detalheleilao .is-favoritolist .product-image { height: 135px; }
#detalheleilao .is-favoritolist .is-lances { padding-bottom:0; }
#detalheleilao .list .product-image:hover {
  border: 1px solid #525252;
}
#detalheleilao .list .product-image a {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
#detalheleilao .list .product-image a img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}
#detalheleilao .list .product-content .top-line {
  margin-bottom: 5px;
}
#detalheleilao .list .product-content .top-line a:hover span {
  background-color: #fff;
  border: 1px solid #B3B3B3;
  color: #000;
}
#detalheleilao .list  .product-content .top-line span {
  font-size: 15px;
  font-weight: 600;
  background-color: #fff;
  padding: 5px 10px;
  color: #737373;
  display: inline-block;
  border: 1px solid #ddd;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#detalheleilao .list  .product-content .top-line .product-price-bid {
  float: right;
}
#detalheleilao .list  .product-content .top-line .product-price-bid p {
  text-align: right;
  line-height: normal;
}
#detalheleilao .list  .product-content .top-line .product-price-bid p:first-child {
  margin-bottom: 0px;
}
#detalheleilao .list  .product-content .top-line .product-price-bid .price-bid {
  font-size: 18px;
  color:#2564c2;
}
#detalheleilao .list  .product-content .product-description h3 {
  margin: 0;
  font-size: 14px;
  font-family:arial, verdana;
}
#detalheleilao .list  .product-content .product-description h3 a {
  color: #2564c2;
  display: block;
  min-height: 127px;
  max-height: 127px;
  font-weight: 300;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#detalheleilao .list  .product-content .product-description h3 a{
min-height:48px; max-height:48px; margin-bottom:10px;
}
#detalheleilao .list .product-description {   min-height: 48px;  max-height: 48px; margin-top:7px; }
#detalheleilao .list .product-content .product-description h3 a:hover {
  text-decoration:underline;
}
#detalheleilao .list .product-content .product-description a span {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
#detalheleilao .list .list .product-content .product-description a span:hover {
  border: 1px solid #B3B3B3;
  background-color: #fff;
  color: #B3B3B3;
}
#detalheleilao .list .product-content .price-bid-custom-padding {
  padding-left: 0;
}
#detalheleilao .list .product-content .price-bid-custom-padding .product-price-bid {
  float: right;
  width: 100%;
  padding-bottom: 30px;
}
#detalheleilao .list .price-bid-custom-padding .product-price-bid { margin-bottom:10px; padding:0 !important; }
#detalheleilao .list .product-content .price-bid-custom-padding .product-price-bid p {
  text-align: right;
  line-height: normal;
}
#detalheleilao .list .product-content .price-bid-custom-padding .product-price-bid p:first-child {
  margin-bottom: 3px;
}
#detalheleilao .list .product-content .price-bid-custom-padding .product-price-bid .price-bid {
  font-size: 18px;
}
#detalheleilao .list .product-content .price-bid-custom-padding .visits {
  width: 100%;
  float: right;
}

#content .inner-catalog .image-thumbs .thumb-video {background-color: #e62117; height: 64px;}
#content .inner-catalog .image-thumbs .thumb-video p, #content .inner-catalog .image-thumbs .thumb-video h5 {color: #fff;}
#content .inner-catalog .image-thumbs .thumb {height: 90px;}

/* banner adwords css start*/

.banner-adwords {padding-bottom: 30px; padding-top: 30px;}
.bannerwrap {border: 1px solid #e6e4e4;height: 252px;width: 100%;overflow: hidden;text-align: center;position: relative;}
.bannerwrap-inner {position: relative;z-index: 2;}
.bannerwrap-pub {position: absolute; z-index: 1;top: 50%;left: 0;text-align: center;margin-top: -8px;width: 100%;text-transform: uppercase;font-size: 11px;color: #c3c0c0;}
.gray-background {background-color: #E8E8E8;}
.lightgray-background {background-color: #f1f1f1;}
.hidden-info2 { margin-bottom:50px; float:left; padding:15px 0; }
.hidden-info .lightgray-background { float:left; width:100%; padding:15px; }
.hidden-info .section-title { padding-left:0; margin-top:0; margin-bottom:10px;  }
.hidden-info .section-title h2 span { background:#f1f1f1; position:relative; z-index:1; font-size:14px; }
.hidden-info .section-title:after { border-color:#d8d8d8; z-index:0; }
.is-termos .is-termoswrap { height:280px; overflow-y:scroll; border-bottom:1px solid #d8d8d8;padding-bottom:20px; -webkit-box-shadow: inset -9px -5px 21px -10px rgba(0,0,0,0.75);
  -moz-box-shadow: inset -9px -5px 21px -10px rgba(0,0,0,0.75);
  box-shadow: inset -9px -5px 21px -10px rgba(0,0,0,0.75); margin-bottom:20px; }
.main-title h2 { font-size:18px; color:#737373;  }
.hidden-info h3 { font-size:18px; color:#737373; }
.hidden-info p { color:#737373; font-size:14px;}
.hidden-info p b { opacity:0.7; }
.hidden-info .vermais-btn { width:auto; float:left; margin:0 10px 0 0; height:auto; }
.hidden-info .vermais-btn a { width:auto; opacity:1; padding:10px 20px; font-size:14px; }
.hidden-info .vermais-btn a i { margin-right:10px; }
.hidden-info .info-gerais-row { margin-bottom:30px; }

@media only screen and (max-width: 767px) {
  .is-anuncio.hidden-sm {
    display:none;
  }
}

@media only screen and (max-width: 576px) {
  .is-anuncio.hidden-xs {
    display:none;
  }
  .is-anuncio.is-banner-g {
    width: 100%!important;
  }
}

/* banner adwords css end */

/* 404 */

.error-page h1 {font-weight: bold; text-align: center; font-size: 100px; margin-top: 40px; margin-bottom: 0; color: #000;}
.error-page h2 {color: #818181; margin: 0 0 15px 0;}
.error-page h2, .error-page p {text-align: center;}
.error-page p {font-size: 16px!important;}

/* ==========================================================================
   CATALOGO MEDIA QUERIES
   ========================================================================== */
@media only screen and (max-width: 955px) {
  #content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content.lots input {
    width: 47%;
  }
  #content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content.lots select {
    width: 48%;
  }
}
@media only screen and (max-width: 920px) {
  #content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content.lots input {
    width: 44%;
  }
  #content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content.lots select {
    width: 45%;
  }
  #content .inner-content-container .catalog .sidebar-tools .media-container .powered-by img {
    margin-left: 4%;
  }
  #content .inner-content-container .catalog .sidebar-tools .media-container .social-media ul li {
    width: 24%;
  }
  #content .inner-catalog .leilao-catalogo-top .catalog-box .peca-button {
    padding: 7px 4px;
    font-size: 10px;
  }
  #content .inner-catalog .leilao-catalogo-top .catalog-box .facebook {
    font-size: 10px;
    padding: 7px 7px 7px 30px;
  }
}
@media only screen and (max-width: 880px) {
  #content .inner-content-container .catalog .catalog-content .results .list .product-content .product-description h3 a { 
    font-size:16px;
    line-height:22px;
  }
  #content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content.lots input {
    width: 33%;
  }
  #content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content.lots select {
    width: 34%;
  }
  #content .inner-content-container .catalog .sidebar-tools .media-container .powered-by img {
    margin-left: 0;
  }
  /* #content .inner-content-container .catalog .sidebar-tools .media-container .social-media ul li {
    width: 23%;
  }
  #content .inner-content-container .catalog .sidebar-tools .media-container .social-media a {
    width: 74%;
  } */
}
@media only screen and (max-width: 850px) {
  #content .inner-content-container .catalog .results-pagination .page {
    float: left;
  }
  #content .inner-content-container .catalog .results-pagination .pagination {
    width: 100%;
  }
  #content .inner-content-container .catalog .results-pagination .pagination ul {
    float: left;
  }
}
@media only screen and (max-width: 767px) {
  .is-meucatalogo #content .inner-content-title .cat-title h2 { padding-bottom:0; }
  .catalog-content .is-catalog-content-tools { margin-bottom:0; }
  .destaque-wrap { margin-top:20px; }
  .video-como-comprar {padding-top: 20px; padding-bottom: 20px;}
  #content .inner-catalog .right-side .peca-info .total-arrows { border-bottom:1px solid #f1f1f1; }
  #content .inner-catalog .right-side .peca-info .preco-bid { border-bottom:1px solid #ddd; padding-bottom:20px; margin-bottom:20px; }
  #content .inner-catalog .right-side .peca-info .preco-bid .lance-btn { float:none; margin-top:10px; }
  /*#content .inner-catalog .right-side .peca-info .preco-bid .status { display:none; }*/
  .status-prod .temvideo, .status-prod .aovivo-status { float:left; margin-right:10px; line-height:28px; }
  .video-wrap { padding:0; }
  /*.is-valorinputwrap { float:right; }*/
  .section-title { padding-left:0; }
  #content .inner-catalog .right-side .peca-info .preco-bid form { padding-left:0; }
  #lance .is-decimals { margin:0 !important; }
  #detalheleilao .is-favoritolist .is-lances { margin:10px 0 0 0; padding-bottom:15px; }
  .histconsite { font-size:12px; padding:6px 10px 7px 10px; }
  #detalheleilao { float:left; width:100%; }
  #detalheleilao .product-content { margin-top:10px; }
  #detalheleilao .is-lances .visits { float:right; margin:0;}
  #detalheleilao .is-lances .visits p { text-align:left; }
  .adsWrap { display:none !important; }
  #content .inner-catalog .left-side { height:auto; }
  .mc-toggle-link:hover { background-color:#fff; color:#8E8E8E;}
  .mc-toggle-link.ativo { background-color:#f1f1f1; color:#8E8E8E;}
  .is-meucatalogo .PrecoProd { margin:0 0 10px 0; }
  .is-meucatalogo #content .inner-content-container .PrecoProd p { margin-bottom:0; }
  #content .inner-content-container .lanceauto-mobile p { width:auto; margin:0; }
  .lanceauto-mobile .is-removerauto { position:absolute; top:50%; right:15px; margin-top:-13px;  }
  .lanceauto-mobile .lanceauto-mobile-wrap { padding:10px; position:relative; background:#f1f1f1;  }
  .is-filtro .tool-top a { width:auto !important; float:left; padding-right:20px !important; }
  .is-filtro li { padding:0; }
  .ToolBox-wrap { border-bottom:1px solid #f1f1f1; }
  .ToolBox-wrap .TitleToolBox { margin:0 -15px; }
  .is-catalogo .ContentToolBox { padding:10px 15px !important; }
  .is-catalogo .ShowBox { margin:0 !important; }
  .is-ferramenta li { margin:0; padding:0 !important; }
  .tools-container { display:none; padding:0 15px 30px 15px; overflow-y:scroll; }
  #content .inner-content-title .more-info {
    margin-top: 15px;
  }
  #content .inner-content-title .hidden-info.open {
    max-height: 1000px;
  }
  #content .inner-content-container .catalog .catalog-content {
    width: 100%!important;
  }
  #content .inner-content-container .catalog .catalog-content .results .list .product-content-container {
    padding: 0 15px;
  }
  #content .inner-content-container .catalog .catalog-content .results .list .product-content .product-toolbox .lote-status { padding-left:15px; }
  .fav-watch-btns a { width:50%; }
  .fav-watch-btns a span { display:none;  }
  .fav-watch-btns a.ativo { background-color:#fff; }
  .fav-watch-btns a.ativo { background-color:#525252; }
  .fav-watch-btns a.ativo i { color:#fff; }

  #content .inner-content-container .catalog .catalog-content .results .list .product-content .product-description h3 a {
    min-height: 55px;
    max-height: 55px;
  }
  #content .inner-content-container .catalog .catalog-content .results .list .product-content .top-line span {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  #content .inner-content-container .catalog .catalog-content .results .list .product-content .top-line .product-price-bid {
    float: left;
    margin-bottom: 15px;
  }
  #content .inner-content-container .catalog .catalog-content .results .list .product-content .top-line .product-price-bid p {
    text-align: left;
  }
  #content .inner-content-container .catalog .catalog-content .results .list .product-content .price-bid-custom-padding {
    padding-left: 15px;
  }
  #content .inner-content-container .catalog .catalog-content .results .list .product-content .price-bid-custom-padding .visits {
    margin-bottom: 15px;
    width:auto;
    float: left;
  }
  #content .inner-content-container .catalog .catalog-content .results .list .product-content .price-bid-custom-padding .visits p {
    text-align: left;
  }
  #content .inner-content-container .catalog .catalog-content .results .list .product-content .price-bid-custom-padding .product-price-bid {
    padding-bottom: 15px;
    width:auto;
    float:left;
  }
  #content .inner-content-container .catalog .catalog-content .results .list .product-content .price-bid-custom-padding .product-price-bid p {
    text-align: left;
  }
  #content .inner-content-container .catalog .sidebar-tools {
    float: left;
    position: absolute;
    top: 0;
    left: 0;
    background-color:#fff;
    padding-top:20px;
    padding-bottom:20px;
  }
  #content .inner-content-container .catalog .sidebar-tools .media-container {
    display: none;
  }
  #content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content.lots input {
    width: 80%;
  }
  #content .inner-content-container .catalog .sidebar-tools .tools-container .tool-box .tool-box-content.lots select {
    width: 80%;
    margin-left: 13px;
  }
  #content .inner-content-container .catalog .input-spacement {
    width: 88%!important;
    float: left;
  }
  #content .inner-content-container .catalog .top-level-ui .search-container label {
    text-align: left;
  }
  #content .inner-content-container .catalog .top-level-ui .search-container .four {
    width: 12%!important;
    float: left;
  }
  #content .inner-content-container .catalog .top-level-ui .view-options {
    float: left;
    width: 100%!important;
    margin-bottom: 15px;
  }
  #content .inner-content-container .catalog .top-level-ui .view-options-search {
    float: left;
    width: 100%!important;
  }
}

/* ==========================================================================
   MEDIA QUERIES
   ========================================================================== */
@media only screen and (max-width: 950px) {
  #content .inner-content-container .catalog .sidebar-tools .media-container .social-media ul li {
    width: 20%;
  }
  
}
@media only screen and (max-width: 920px) {
  #slider {
    display: none;
  }
    #slider.slider-capt { display:block !important; }
  #content .inner-catalog .leilao-catalogo-top .catalog-box .peca-button {
    padding: 7px 4px;
    font-size: 10px;
  }
  #content .inner-catalog .leilao-catalogo-top .catalog-box .facebook {
    font-size: 10px;
    padding: 7px;
  }
}
@media only screen and (max-width: 900px) {
.is-catalogo #encontrados { width: 50%; float: none;}
}
@media only screen and (max-width: 880px) {
  #header .barra-topo:after { top:45px;}
  #content .inner-content-container .catalog .sidebar-tools .tools-container .tool-top a { font-size:12px; }
  .is-filtro .grid-options { font-size:11px; }
  #header .barra-topo .mobile-hide {
    display: none;
  }
  #header .barra-topo p {

  }
  #header .barra-topo .login-topo {
    width: 100%;
    padding-left: 0;
  }
  #header .barra-topo .login-topo .telefone-topo {
    float: left;
  }
  #header .barra-topo .login-topo .login {

  }
  #header .barra-topo .login-topo .desktop-hide {
    display: block;
  }
  #header .header-content .header-banner .search_select {
    padding: 5px 5px;
    font-size: 12px;
  }
  #header .header-content .header-banner .search_bar {
    font-size: 12px;
  }
  #header .menu .menu-nav ul > li a {
    font-size: 11px;
  }
}
@media only screen and (max-width: 767px) {
  .int-tools ul li { width:100%; }
  .hidden-info .vermais-btn a { margin-bottom:10px; }
  .hidden-info .lightgray-background { padding:15px 0; }
  .status-prod { height:34px; }
  .is-catalogo #encontrados { width:100%;  }
  #content .inner-content-container .destaque-wrap p { line-height:20px; }
  #content .last-destaque-row .destaque-wrap p { line-height:20px; }
  #content .inner-content-container .destaque-wrap .paginacao p { line-height:38px; float:left; }
  #content .last-destaque-row .destaque-wrap .paginacao p { line-height:38px; float:left; }
  .is-catalogo .cat-title h2 { border:none; }
  .find-numb { line-height:20px; font-size:20px; }
  .cntblck { padding-left:0; }
  .is-catalogo-lado #content .inner-content-container .catalog .catalog-content .results .list li {min-height: inherit;}
  .eight.columns.mobile-terms {width: 65%!important; float: left;}
  .opiniaoImg {width: 35%!important; float: left; padding: 10px 0px 5px 0; height: 100px;}
  .opiniaoImg img {max-width: 100%; max-height: 100%; position: inherit;overflow: hidden;top: 0;left: 0; -webkit-transform: none; -moz-transform: none;-ms-transform: none;-o-transform: none; width: auto;}
  .mobile-hide .opiniaoImg {display: none;}
  #sub-footer ul li a:after {display: none;}
  #sub-footer ul li {float: none;}
  #sub-footer ul li a {padding: 9px 0;}
  .mobile-show .opiniaoImg { display: block;}
  #footer .footer-caption p:last-child {margin-bottom: 0;}
  #footer .footer-socialmedia {margin-top: 10px; text-align: left;}
  .grid-box { border-bottom:1px solid #f1f1f1 !important; padding-bottom:15px !important; margin-bottom:15px !important;}
  .grid-box:first-child { padding-top:0 !important;}
  .img-box-wrap { margin:0 !important; }
  .is-catalogo-lado .status-prod { opacity:1; }
  .watchcs { width:100%; height:36px; display:table; vertical-align:middle; text-align:center; }
  .watchcs i { padding-right:10px; }
  .is-lances { margin-bottom:10px; }
  .product-toolbox .vermais-btn { margin:0 !important; }
  .menu-painel .newmsg-alert { font-size:15px; width:20px !important; height:20px; right:50%; margin-right:-22px;  }
  #header .barra-topo:after { top:48px!important;}
  #header .barra-topo .login-topo .login a { font-size:12px; font-weight:bold; margin:0 5px;}
  #header .barra-topo { height:48px;}
  .mobile-search-col { padding-right: 0!important;}
  #header .header-content .logo-mobile { display: table;}
  #header .header-content .logo-desktop { display: none;}
  .header-banner .mobile-two { width: 60% !important;}

  .reveal-modal, .reveal-modal.small, .reveal-modal.medium, .reveal-modal.large, .reveal-modal.xlarge {
    left:1%; margin-left:0; width:98%;
  }

  .is-filtro .grid-options { font-size:14px;  }
  #header .menu .menu-nav ul > li a { line-height:initial; }
  .adsWrap { display:none; }
  .is-catalogo .inner-content-title { margin-top:61px !important; }
  .cat-title h2 { padding:0;  }
  #content .inner-content-title .cat-title h2 { font-size:16px; line-height:24px; padding:0; }

  .is-breadcrumb { display:none; }
  .cat-title .cat-title-btns {position:relative; float:left; margin-top:10px; }
  .cat-title-btns a { float:left; font-size:10px; }
  .cat-title-btns a:last-child { margin:0; }
  .pesq-label label { text-align:left; height:20px; }

  #footer-wrapper { position:relative;}

  .resultado-search li { margin-bottom:20px; }
  .prod-box:hover { border-color:#fff; }

  .ToolBox { width:100%; border:none; }
  .TitleToolBox { float:none; padding:10px 15px; }
  .ContentToolBox { float:none; padding:15px;}

  .UserTools { height:300px; overflow-y:scroll; border:1px solid #f1f1f1; }
  .UserTools li { width:100% !important; float:left; padding:5px 10px !important; }

  .is-catalogo .is-Submit { height:initial !important; max-height:300px; }

  .prod-box { padding:0; }
  .vermais-btn a { opacity:1; }
  .prod-title h3 { height:auto !important; max-height:65px; }

  #content .inner-catalog .image-container { margin-bottom:20px; height:auto;  }
  .content-info { margin:0 !important; max-height:0; }

  .content-title-mobile { display:block; margin:0 0 10px 0; padding-left: 15px;}
  .content-title-mobile h2 {
  font-size: 16px;
  font-family: 'Open Sans', Arial, sans-serif;
  color: #8E8E8E;
  font-weight: 300;
  margin: 0; }

  .content-title h2 { display:none; }

  .paginacao { float:left; margin:0 0 20px 0; }
  .paginacao p { display:block; margin:0 0 10px 0; float:none; line-height:16px; }
  .paginacao ul { float:left; list-style:none; padding:0; margin:0; }
  .paginacao ul li { margin:0; }
  .paginacao ul li:last-child { margin:0; border-right:1px solid #ddd; }
  .paginacao ul li.dotdot { display:none; }
  .paginacao ul li.fixedpag { display:none; }

  .is-pesquisa .paginacao { float:left; margin:0; position:initial; }
  .is-catalogo .paginacao { float:left; margin:0; position:initial; margin-top:10px; }

  .is-destaques .paginacao { float:left; }

  /*.ad-box { display:none; }*/
  /*.ad-1 { display:block; }*/
  .ad-4 { width:100%; text-align:center; }
  .ad-4 .bannerwrap { display:inline; }

  .mc-content .title-box { display:block;  }

  .intern-title h2 { font-size:18px !important; }
  .intern-title h3 { position:relative; line-height:20px !important;  height:auto !important; margin:10px 0 0 0; }
  .intern-title h3 span.medium-title-icon { position:absolute; left:0; top:50%; margin-top:-17px; }
  .intern-title h3.apoioinfo.single { line-height:22px !important;  }

  .mc-content .subnav-fundo { margin-top:10px;  }
  .vermais-link a { float:left; padding: 0 0 0 40px; border:none; }
  #detalhe_leilao .vermais-link a { float:left; padding: 0 0 0 40px; border:none; }

  .mc-menu { padding:0; position:absolute !important; top:0; left:0; width:100% !important; z-index:998; background:#fff; }
  .mc-menu.sticky { position:fixed !important; }
  .mc-conteudo { padding-top:51px; position:relative; }
  .is-catalogo .mc-conteudo { padding-top:0; }
  .menu-painel { display:none; padding:10px 0; margin:0 !important; overflow-y:scroll;  }
  .is-pesquisa .menu-painel { overflow-y:scroll; }
  .subnav-menu li { width:33% !important; float:left !important; padding-top:10px !important; padding-bottom:10px !important;  }

  #header .header-content h1 { height:80px; display:table; padding:0; }
  #header .header-content h1 a { height:60px; display:table-cell; vertical-align:middle; float:none !important; }
  #header .header-content a { height:60px;  }
  .mobile-search input,
  .mobile-search select {
    font-size: 10px;
  }
  .mobile-search.open {
    max-height: 500px;
  }
  #header .header-content .header-banner .desktop-search {
    display: none;
  }
  #header .header-content .collapse-menu {
    display: block;
  }

  #header .menu {
    display: none;
  }
  #header .mobile-menu.open {
    display: block;
  }
  .mobile-vers .catalog-title { margin:0 !important; float:left; padding:0 !important; }
  .mobile-vers .catalog-title h2 { float:left; margin:0 0 10px 0 !important; }
  #content .ongoing-auctions .ongoing-auctions-container .content-title {
    margin-top: 15px;
  }
  #content .inner-content-container .seu-cadastro .input-label-container .label-container,
  #content .inner-content-container .seu-endereco .input-label-container .label-container {
    padding-bottom: 5px;
  }
  #content .inner-content-container .seu-cadastro .input-label-container .label-container label,
  #content .inner-content-container .seu-endereco .input-label-container .label-container label {
    padding-top: 10px!important;
  }
  #content .inner-content-container .seu-cadastro .input-label-container .input-container,
  #content .inner-content-container .seu-endereco .input-label-container .input-container {
    padding-left: 0;
  }
  #content .inner-content-container .seu-cadastro li:nth-child(8) {
    height: inherit;
  }
  #content .inner-content-container .seu-cadastro .pessoa-tipo {
    margin-bottom: 5px;
  }
  #content .inner-content-container .seu-cadastro .pessoa-tipo input {
    height: 20px;
    width: 20px;
  }
  #content .inner-content-container .seu-cadastro .pessoa-tipo label {
    padding-top: 4px;
    padding-left: 10px;
  }
  #content .inner-content-container .conditions {
    margin-top: 20px;
  }
  #content .inner-content-container .conditions .pessoa-tipo input {
    height: 20px;
    width: 20px;
  }
  #content .inner-content-container .conditions .pessoa-tipo label {
    padding-top: 4px;
    padding-left: 10px;
  }
  #content .inner-content-container .seu-endereco li:first-child h4 {
    margin-bottom: 15px;
  }
  #content .inner-content-container .seu-endereco li:nth-child(12) {
    margin-top: 25px;
  }
  #content .inner-content-container .seu-endereco li:nth-child(13) {
    margin-bottom: 20px;
  }
  #content .inner-content-container .seu-endereco .input-label-container .input-container .ddd {
    margin-right: 3%;
  }
  #content .inner-content-container .seu-endereco .input-label-container .input-container .fone {
    width: 72%;
  }
  #content .inner-content-container .seu-endereco .pessoa-tipo input {
    height: 20px;
    width: 20px;
  }
  #content .inner-content-container .seu-endereco .pessoa-tipo label {
    padding-top: 4px;
    padding-left: 7px;
  }
  #content .inner-content-container .catalog-box .catalog-auction {
    margin-bottom: 25px;
  }
  #content .inner-content-container .inner-padded-column {
    margin-top: 20px;
  }
  #content .inner-content-container .cadastro-type-1 {
    margin-bottom: 25px;
  }
  #content .inner-content-container .contact-mail h4 {
    margin: 0 0 10px 0;
  }
  #content .inner-content-container .contact-mail .label-text {
    padding-bottom: 5px;
  }
  #content .inner-content-container .contact-mail .input-size {
    padding-left: 0;
  }
  #content .inner-content-container .contact-mail .input-size textarea {
    width: 100%;
    max-width: 767px;
  }
  #content .inner-content-container .contact-mail:last-child {
    margin-bottom: 10px;
  }
  #content .inner-content-container .contact-phone {
    margin-top: 30px;
  }
  #content .inner-catalog .info-box {
    margin-top: 0;
  }
  #content .inner-catalog .leilao-catalogo-top .catalog-title {
    width: 100%;
    padding-bottom: 15px;
    margin-bottom: 35px;
  }
  #content .pecas-highlights {
    display: none;
  }
  #content .inner-catalog .mobile-show {
    display: block;
  }
  #content .inner-catalog .mobile-hide {
    display: none;
  }
  #footer {
    height: inherit;
    padding: 25px 0;
    bottom: 35px;
  }
  #footer .footer-logo {
    display: none;
  }
  #footer .footer-menu {
    display: none;
  }
  #footer .footer-caption {
    margin-top: 15px;
  }
  #footer .footer-caption p {
    padding-bottom: 10px;
  }
  #footer .footer-invitation {
    margin-top: 15px;
  }
  #footer .footer-invitation button {
    width: 100%;
  }
  #footer .footer-invitation form {
    margin-bottom: 0;
  }
  #sub-footer .mobile-hide {
    display: none;
  }
  #sub-footer ul {
    float: left;
  }
  #sub-footer ul li a {
    font-size: 11px;
  }
}
@media only screen and (max-width: 620px) {
  .mobile-search {
    padding-right: 0;
  }
  .mobile-search .mobile-search-col {
    width: 100%!important;
    padding-right: 15px!important;
  }
  .mobile-search .mobile-search-col input {
    margin: 10px 0 0 0;
  }
  .mobile-search .mobile-select-col {
    width: 100%!important;
    padding-right: 65px;
  }
  .mobile-search .search_button {
    top: 40px;
    right: 15px;
  }
}
/*@media only screen and (max-width: 490px) {
  #sub-footer ul li a {
    padding: 9px 7px;
  }
  #sub-footer ul li a:after {
    margin-left: 7px;
  }
}*/
@media only screen and (max-width: 480px) {
  .pickLote { font-size:14px; width:150px; height:28px; padding-left:0; }
  .listaLote { width:150px; left:0; margin-left:0; }
  #content .inner-content-container .lote-desc p { font-size:16px; line-height:22px; }
  #detalheleilao .is-lances .visits { float:left; margin-top:10px; width:100%;  padding: 5px 10px; border: 1px solid #eee; }
  #detalheleilao .is-removerauto { position:absolute; top:7px; right:10px; height:40px; width:50px; padding-left:10px; border-left:1px solid #f1f1f1;  }
  #toTop span { display:none; }
  #toTop i { margin:0; }


  #slider .in-captation .info-text {
    margin-top: 55px;
  }
  #slider .in-captation .info-text h3 {
    font-size: 30px;
  }
  #slider .in-captation .info-text p {
    font-size: 20px;
    word-wrap:break-word;
  }
  #header .barra-topo .desktop-hide {
    display: none!important;
  }
  #header .barra-topo .login-topo {
    padding-left: 15px;
  }
  #header .barra-topo .login-topo .login {
    float: left;
  }
  #content .inner-content-container .contact-phone table tbody td {
    font-size: 12px;
  }
  #content .inner-content-container .captcha input {
    width: 100%;
  }
  #content .inner-content-container .captcha .realperson-challenge {
    width: 100%;
    margin-bottom: 15px;
  }
  #content .inner-content-container .captcha .button {
    float: left;
  }
  #content .inner-catalog .image-container {
    height: auto;
  }
  #content .inner-catalog .leilao-catalogo-top .catalog-box {
    width: 100%;
    margin-right: 0;
    float: none;
  }
  #content .inner-catalog .leilao-catalogo-top .catalog-box .facebook {
    padding: 7px;
    text-align: center;
  }
  #content .inner-catalog .leilao-catalogo-top .catalog-box .facebook span {
    left: 35%;
  }
  #footer {
    bottom: 105px;
  }
  #confirma-termos-modal .aceitetermos #idaceito {width: 10%;}
  #confirma-termos-modal .aceitetermos li label {height: inherit; width: 85%;}
}
@media only screen and (max-width: 360px) {
  .is-banner-g { padding:0 !important;  }
  #slider .in-captation .info-text {
    margin-top: 40px;
  }
  #slider .in-captation .info-text h3 {
    font-size: 26px;
    padding-bottom: 20px;
  }
  #slider .in-captation .info-text p {
    font-size: 16px;
  }
  .ad-row .columns { padding:0 !important; }
}



.column, .columns { float: left; min-height: 1px; padding: 0 15px; position: relative; }
.column.centered, .columns.centered { float: none; margin: 0 auto; }

[class*="column"] + [class*="column"]:last-child { float: left; }

[class*="column"] + [class*="column"].end { float: left; }

.one, .row .one { width: 8.33333%; }

.two, .row .two { width: 16.66667%; }

.three, .row .three { width: 25%; }

.four, .row .four { width: 33.33333%; }

.five, .row .five { width: 41.66667%; }

.six, .row .six { width: 50%; }

.seven, .row .seven { width: 58.33333%; }

.eight, .row .eight { width: 66.66667%; }

.nine, .row .nine { width: 75%; }

.ten, .row .ten { width: 83.33333%; }

.eleven, .row .eleven { width: 91.66667%; }

.twelve, .row .twelve { width: 100%; }

.push-two { left: 16.66667%; }

.pull-two { right: 16.66667%; }

.push-three { left: 25%; }

.pull-three { right: 25%; }

.push-four { left: 33.33333%; }

.pull-four { right: 33.33333%; }

.push-five { left: 41.66667%; }

.pull-five { right: 41.66667%; }

.push-six { left: 50%; }

.pull-six { right: 50%; }

.push-seven { left: 58.33333%; }

.pull-seven { right: 58.33333%; }

.push-eight { left: 66.66667%; }

.pull-eight { right: 66.66667%; }

.push-nine { left: 75%; }

.pull-nine { right: 75%; }

.push-ten { left: 83.33333%; }

.pull-ten { right: 83.33333%; }

