.heightMax {
    min-height: 100px;
    max-height: 150px;
}

.title-product {
    color: rgb(29, 77, 116);
    font-weight: bold;
    font-size: 23px;
}

.description-product {
    color: #888;
    font-size: 18px;
}

.types-products {
    padding: 10px 10px !important;
}

.p-15 {
    padding-left: 15px;
}