.banner-resp {
    max-height: 330px;
    overflow: hidden;
    position: relative;
}

.banner-resp img {
    width: 100%;
    min-height: 105px;
}

.about-title {
    background-color: rgba(57, 57, 57, 0.5);
    padding: 20px;
    bottom: 15%;
    position: absolute;
    margin-left: 15px;
}

.about-title h2 {
    font-family: 'Noto Sans';
    font-size: 25px;
    color: #fff;
    margin: 0;
    text-transform: uppercase;
    font-weight: bold;
}

.vantagens-container {
    padding-top: 50px;
    padding-bottom: 80px;
}

.vantagens-container h2, .vantagens-form h2 {
    margin: 0 0 40px 0;
}

.vantagens-topic {
    margin-bottom: 15px;
}

.vantagens-topic h3 {
    color: rgb(29, 77, 116);
    font-size: 16px;
    font-weight: bold;
}

.vantagens-topic p:last-child {
    margin-bottom: 0;
}

.fonts {
    font-size: 18px;
}

.mid-section {
    background-color: #f3f3f3;
    padding: 60px 0;
    margin: 0;
}

.sobre-box {
    padding: 50px 0 80px 0;
}

.depoimentos h2 {
    margin: 0 0 50px 0;
}

.img-controller {
    position: relative;
    height: 125px;
}

.img-controller img {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.autor-text {
    padding-right: 25px;
}

.depoimentos .autor-text p {
    font-family: 'Open Sans';
    font-size: 16px;
    color: #3d3d3d;
    line-height: 1.2;
    min-height: 60px;
    margin-bottom: 15px;
    font-style: italic;
}

.autor-text h5 {
    font-family: 'Open Sans';
    font-weight: 300;
    font-size: 16px;
    margin: 0;
    font-style: italic;
}