html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  max-height: 100vh;
  max-width: 100vw;
 
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  font-family: Gotham !important;
  font-size: 14px;
}

.classeErro {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
  padding: 10px;
  text-transform: uppercase;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Arial;
  width: 300px;
  box-sizing: border-box;
  margin: 30px;
  pointer-events: all;
}

.classeErro .btn {
  color: #842029;
}

.classeSucesso {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
  padding: 10px;
  text-transform: uppercase;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Arial;
  width: 300px;
  box-sizing: border-box;
  margin: 30px;
  pointer-events: all;
}

.classeSucesso .btn {
  color: #0f5132;
}

#__react-alert__ div div div {
  width: auto !important;
}