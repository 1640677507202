
.gray-background {
    background-color: #E8E8E8;
    padding: 0px 30px;
}

.banner-adwords {
    padding-bottom: 30px;
    padding-top: 30px;
}

.bannerwrap {
    border: 1px solid #e6e4e4;
    height: 252px;
    width: 100%;
    overflow: hidden;
    text-align: center;
    position: relative;
}

.bannerwrap-inner {
    position: relative;
    z-index: 2;
}

.bannerwrap-pub {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    text-align: center;
    margin-top: -8px;
    width: 100%;
    text-transform: uppercase;
    font-size: 11px;
    color: #c3c0c0;
}